//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableDebitMandates from '../../../../components/table/TableDebitMandates';
import Pagination from '../../../../components/Pagination';
import Loading from '../../../../components/Loading';
//action
import {
	setDebitMandates,
	setNbPagesDebitMandates,
} from '../../../../action_creators/accountActionCreators';
import { setLog } from '../../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../../functions/ajax';
import { getActivityParameters } from '../../../../functions/coownerships';

class CardDebitMandates extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			current_page: 1,
			is_loading: false,
		};

		this.redirectToPage = this.redirectToPage.bind(this);
		this.responseManagment = responseManagment.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.checkPageParam();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		// Update coowners when page change
		if (prevProps.match.params.page !== this.props.match.params.page) {
			// Prevent infinite loading from page redirection during or after ajax request
			if (this.state.is_loading === true) {
				this.setState({ is_loading: false });
			}
			this.checkPageParam();
		}
	}

	checkPageParam() {
		let nb_current_page = this.props.match.params.page;

		if (nb_current_page === undefined) {
			// No page mentionned = page 1
			this.getDebitMandates();
		} else if (isNaN(nb_current_page) || nb_current_page < 1) {
			// Page param is invalid
			this.redirectToPage();
		} else {
			nb_current_page = parseInt(nb_current_page);
			if (nb_current_page !== this.state.current_page) {
				// Set new page in state
				this.setState({ current_page: nb_current_page });
			}
			// Check if there are no coowners on current page
			if (!this.props.debit_mandates[nb_current_page]) {
				this.getDebitMandates(nb_current_page);
			}
		}
	}

	async getDebitMandates(asked_page = 1) {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/mandats-prelevements?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&page=${asked_page}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			// Check if asked page match with API pages
			if (response.current_page > asked_page) {
				return this.redirectToPage();
			} else if (response.current_page > 0 && response.current_page < asked_page) {
				return this.redirectToPage(response.current_page);
			}
			if (!this.props.debit_mandates.nb_pages) {
				this.props.setNbPagesDebitMandates(response.nb_pages);
			}
			this.props.setDebitMandates(
				response.mandats_prelevements,
				response.current_page
			);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	/**
	 * Redirect the incident page of the given number
	 * @param {number|string} page_number
	 */
	redirectToPage(page_number = 1) {
		this.props.history.push(
			`/coownership/${this.props.match.params.id}/account/debit_mandates/${page_number}`
		);
	}

	goBack(evt) {
		this.props.history.push(`/coownership/${this.props.match.params.id}/account/`);
	}

	render() {
		const { debit_mandates } = this.props;
		const { current_page, is_loading } = this.state;
		return (
			<div className="card">
				<div className="card-header card-header-with-back">
					<button className="back-btn" onClick={this.goBack}>
						Revenir en arrière
					</button>
					<h2>Mandats de Prélèvements</h2>
				</div>
				<div className="card-body table-wrapper">
					{is_loading === false ? (
						debit_mandates.pages[current_page]
						&& debit_mandates.pages[current_page].length ? (
							<TableDebitMandates
								cols={[
									'RÉFÉRENCE',
									'DÉBITEUR',
									'BIC',
									'IBAN',
									'CRÉANCIER',
									'BANQUE',
									'OPTIONS',
								]}
								table={debit_mandates.pages[current_page]}
								className={'table-debit-mandate'}
								picto_color={this.props.coownerships.current.syndic}
								activity={this.props.coownerships.current}
							/>
						) : (
							<p>Vous n'avez aucun mandat de prélèvements.</p>
						)
					) : (
						<Loading />
					)}
				</div>
				{debit_mandates > 1 && (
					<Pagination
						page_max={debit_mandates.nb_pages}
						callback={this.redirectToPage}
						current_page={current_page}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		debit_mandates: state.debit_mandates,
	};
};
const mapDispatchToProps = {
	setDebitMandates,
	setNbPagesDebitMandates,
	setLog,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CardDebitMandates)
);
