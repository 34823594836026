//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableBalance from '../../../components/table/TableBalance';
import PrintButton from '../../../components/button/PrintButton';
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import Loading from '../../../components/Loading';
// action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setBalanceTransactions,
	setBalanceExercices,
	setBalanceCurrentExercice,
} from '../../../action_creators/councilActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';
import { getActivityParameters } from '../../../functions/coownerships';

class Balance extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.balance.transactions.length) {
			this.getBalance();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.balance.current_exercice !== this.props.balance.current_exercice) {
			this.updateBalance();
		}
	}

	async getBalance() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/balance?` + getActivityParameters(this.props.coownerships.current),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setBalanceExercices(response.exercices);
			this.props.setBalanceTransactions(response.comptes);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateBalance() {
		this.setState({ is_loading: true });
		const { balance, coownerships, user } = this.props;
		const response = await getRequest(
			`/balance?`
				+ getActivityParameters(coownerships.current)
				+ `&start=${balance.exercices[balance.current_exercice].start}`
				+ `&end=${balance.exercices[balance.current_exercice].end}`,
			user.token
		);
		if (this.responseManagment(response)) {
			this.props.setBalanceTransactions(response.comptes);
		} else {
			this.props.setBalanceTransactions({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleExercice(evt) {
		this.props.setBalanceCurrentExercice(evt.target.value);
	}
	handlePrint(evt) {
		printTable(
			'table-balance',
			'balance-corpropriete.pdf',
			'Balance',
			this.props.coownerships.current
		);
	}

	goBack(evt) {
		this.props.history.push(
			`/coownership/${this.props.match.params.id}/coownership_council/`
		);
	}

	render() {
		return (
			<div className="grid grid-1">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Balance</h2>
						<PrintButton
							picto_color={this.props.coownerships.current.syndic}
							print={this.handlePrint}
						/>
					</div>
					<div className="card-body table-wrapper">
						<div className="standfirst">
							{this.props.balance.exercices.length ? (
								<SelectorItemObject
									id="select-balance-exerice"
									label="Période"
									current={this.props.balance.current_exercice}
									options={this.props.balance.exercices}
									callback={this.handleExercice}
								/>
							) : null}
						</div>
						{this.state.is_loading === false ? (
							this.props.balance.transactions.length ? (
								<TableBalance
									cols={[
										'NUMÉRO',
										'LIBELLÉ',
										'DÉBIT',
										'CRÉDIT',
										'SOLDE',
									]}
									table={this.props.balance.transactions}
								/>
							) : (
								<p>Il n'y a aucune solde à cette période.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		balance: state.balance,
	};
};
const mapDispatchToProps = {
	setLog,
	setBalanceTransactions,
	setBalanceExercices,
	setBalanceCurrentExercice,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Balance));
