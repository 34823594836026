// module
import React from 'react';
import PropTypes from 'prop-types';

function RadioItem(props) {
	const { input, formValue, callback } = props;
	const { name, required, label, value } = input;
	const id = `${name}-${value}`;
	const checked = formValue ? formValue === value : formValue;

	return (
		<div className="form-item form-group form-group-check-box form-item-radio">
			<input
				className="form-item-check-box"
				type="radio"
				id={id}
				name={name}
				value={value}
				checked={checked}
				required={required}
				onChange={evt => callback(evt)}
			/>
			<label
				htmlFor={id}
				className={'form-item-check-box-label' + (checked ? ' checked' : '')}
				dangerouslySetInnerHTML={{ __html: label }}
			/>
		</div>
	);
}

RadioItem.propTypes = {
	input: PropTypes.object.isRequired,
	formValue: PropTypes.any,
	callback: PropTypes.func.isRequired,
};

export default RadioItem;
