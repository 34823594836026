// module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// component
import Form from '../../components/form/Form';
import Loading from '../../components/Loading';
// actions
import { setLog } from '../../action_creators/logActionCreators';
import { postRequest, responseManagment } from '../../functions/ajax';

class ForgottenPassword extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
	}

	UNSAFE_componentWillMount() {
		// If user is already connected redirect him to racine
		if (Object.keys(this.props.user).length) {
			this.props.history.push('/');
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async resetPassword(value) {
		this.setState({ is_loading: true });
		const response = await postRequest(`/forgotten-password`, value);
		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message: response.message,
			});
		}

		if (this._isMounted) {
			this.setState({
				is_loading: false,
				display_password: true,
			});
		}
	}

	goBack(evt) {
		this.props.history.push('/login');
	}

	render() {
		return (
			<div className="page">
				<div className="grid grid-1 login">
					<div className="card">
						<div className="card-header card-header-with-back">
							<button
								className="back-btn"
								onClick={(evt) => this.goBack(evt)}
							>
								Revenir en arrière
							</button>
							<h1>Mot de passe oublié</h1>
						</div>
						<div className="card-body">
							{this.state.is_loading === false ? (
								<Fragment>
									<p>
										Veuillez remplir votre identifiant, pour que votre
										nouveau mot de passe vous soit communiqué.
									</p>
									<Form
										form_items={[
											{
												type: 'text',
												name: 'username',
												label: `Identifiant`,
												required: true,
											},
										]}
										callback={this.resetPassword}
									/>
								</Fragment>
							) : (
								<Loading />
							)}
						</div>
						<div className="card-footer">
							<Link to="/legal-notice">Mentions Légales</Link>
							<a
								href="/pdf/CGU-MonEspaceCoproprietaire.pdf"
								// eslint-disable-next-line react/jsx-no-target-blank
								target="_blank"
							>
								Conditions Générales d'Utilisation
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};

const mapDispatchToProps = {
	setLog,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword)
);
