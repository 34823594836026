//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableJudicialDocs from '../../../components/table/TableJudicialDocs';
import Loading from '../../../components/Loading';
import { SelectorItem } from '../../../components/form/item/SelectorItem';
// action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setJudicialsDocs,
	setJudicialsDates,
	setJudicialsCurrentDate,
} from '../../../action_creators/docsActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { getActivityParameters } from '../../../functions/coownerships';
// import CardButton from '../../../components/button/CardButton';
import CardLandDeclarations from './cards/CardLandDeclarations';
import CardDebitMandates from './cards/CardDebitMandates';

class MyJudicials extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleDate = this.handleDate.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (
			!this.props.judicials.docs.length
			|| this.props.judicials.docs[0].preparation
		) {
			this.getJudicialList();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.judicials.current_date !== this.props.judicials.current_date) {
			this.getJudicialList();
		}
	}

	async getJudicialList() {
		const judicials = this.props.judicials;
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/juridique-list?`
				+ getActivityParameters(this.props.coownerships.current)
				+ (judicials.dates.length
					? `&periode=${judicials.dates[judicials.current_date]}`
					: ''),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			if (!judicials.dates.length) {
				this.props.setJudicialsDates(response.dates);
			}
			this.props.setJudicialsDocs(Object.values(response.documents));
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleDate(evt) {
		this.props.setJudicialsCurrentDate(evt.target.value);
	}

	render() {
		return (
			<div className="grid grid-3 my-judicials">
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>Documents juridiques</h2>
						{this.props.judicials.dates.length ? (
							<SelectorItem
								id="select-judicial-date"
								label="Période"
								current={this.props.judicials.current_date}
								options={this.props.judicials.dates}
								callback={this.handleDate}
							/>
						) : null}
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading === false ? (
							this.props.judicials.docs.length ? (
								<TableJudicialDocs
									cols={['date', 'documents', 'actions']}
									table={this.props.judicials.docs}
									activity={this.props.coownerships.current}
									coownership_id={this.props.match.params.id}
								/>
							) : (
								<p>Aucun document juridique n'est disponible.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				{/* <CardButton
					title="Déclarations de Revenus Fonciers"
					btn_label="Accéder aux Déclarations de Revenus Fonciers"
					url={`/coownership/${this.props.match.params.id}/judicials/land_declarations`}
				/> */}
				<CardLandDeclarations />
				{/* <CardButton
					title="Mandats de Prélèvements"
					btn_label="Accéder aux Mandats de Prélèvements"
					url={`/coownership/${this.props.match.params.id}/judicials/debit_mandate`}
				/> */}
				<CardDebitMandates />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		judicials: state.docs.judicials,
	};
};
const mapDispatchToProps = {
	setLog,
	setJudicialsDocs,
	setJudicialsDates,
	setJudicialsCurrentDate,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyJudicials));
