// module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// component
import Notification from '../../components/Notification';
import TableCoowner from '../../components/table/TableCoowner';
import GraphLines from '../../components/graph/GraphLines';
import Loading from '../../components/Loading';
//actions
import { addNotifications } from '../../action_creators/notificationsActionCreators';
import {
	setBankGraph,
	setBanks,
	setCurrentBank,
} from '../../action_creators/banksActionCreators';
import { setLog } from '../../action_creators/logActionCreators';
// functions
import { createBankGraph } from '../../functions/bank';
import { getRequest, responseManagment } from '../../functions/ajax';
import { formatNumbers } from '../../functions';
import { getActivityParameters } from '../../functions/coownerships';

class DashBoard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading_bank_graph: false,
			are_loading_notifications: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.notifications.length) {
			this.getNotifications();
		}
		if (this.props.banks.current_bank !== -1) {
			this.props.setCurrentBank(-1);
		}
		if (!Object.keys(this.props.banks.graph).length) {
			this.getBankGraph();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.banks.current_bank !== this.props.banks.current_bank) {
			this.getBankGraph();
		}
	}

	async getBankGraph() {
		this.setState({ is_loading_bank_graph: true });
		const response = await getRequest(
			`/banque-graph?` + getActivityParameters(this.props.coownerships.current),
			this.props.user.token
		);
		if (this.responseManagment(response) && response.months && response.years) {
			let graph = createBankGraph(response);
			if (this.props.banks.list.length) {
				this.props.setBankGraph(graph);
			} else {
				this.props.setBanks(response.banks, -1, graph);
			}
		}

		if (this._isMounted) {
			this.setState({ is_loading_bank_graph: false });
		}
	}

	async getNotifications() {
		this.setState({ are_loading_notifications: true });
		const response = await getRequest(
			`/notification?` + getActivityParameters(this.props.coownerships.current),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.addNotifications(response.notifications);
		}

		if (this._isMounted) {
			this.setState({ are_loading_notifications: false });
		}
	}

	render() {
		return (
			<div
				className={
					// Base on grid 2 (max-height) even if has 3 cards
					'grid grid-2 dashboard'
					+ (Object.keys(this.props.banks.graph).length
					&& this.props.banks.graph.series.length
						? ' parent-graph'
						: '')
				}
			>
				<div className="card">
					<div className="card-header">
						<h2>Copropriétaire</h2>
					</div>
					<div className="card-body">
						<TableCoowner
							className="horizontal"
							table={this.props.coownerships.current.coowner}
						/>
					</div>
				</div>
				<div className="card">
					<div className="card-header">
						<h2>Notifications</h2>
					</div>
					<div className="card-body pb-2">
						{/* Starting by adding own App notifications */}
						<Notification>
							Consultation obligatoire du Conseil Syndical à partir de{' '}
							{formatNumbers(
								this.props.coownerships.current
									.montantConsultationConseilSyndical
							)}
							&nbsp;€
						</Notification>
						<Notification>
							Mise en concurrence obligatoire des marchés et contrats à
							partir de{' '}
							{formatNumbers(
								this.props.coownerships.current.montantMiseEnConcurrence
							)}
							&nbsp;€
						</Notification>
						<Notification title="Pas à pas">
							Découvrez votre accès personnalisé à votre copropriété&nbsp;!
							Il vous suffit de suivre le{' '}
							{/* eslint-disable-next-line react/jsx-no-target-blank */}
							<a href={`/pdf/MY-Guide-Coproprietaire.pdf`} target="_blank">
								guide
							</a>
						</Notification>
						<Notification title="Note de votre gestionnaire">
							Bienvenue sur votre espace Copropriétaire&nbsp;!
						</Notification>
						{/* Add API Notifications */}
						{this.state.are_loading_notifications === false ? (
							this.props.notifications.length ? (
								this.props.notifications.map((notification, i) => (
									<Notification title={notification.title} key={i}>
										{notification.description}
									</Notification>
								))
							) : null
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card no-min">
					<div className="card-header">
						<h2>Nouveau</h2>
					</div>
					<div className="card-body pb-3">
						Si votre gestionnaire l'a activé, vous pourrez désormais remplir
						puis signer électroniquement les formulaire de vote par
						correspondance depuis cette espace. <br />
						Rendez-vous dans{' '}
						<Link to={`/coownership/${this.props.match.params.id}/judicials`}>
							Mon juridique.
						</Link>{' '}
						(Pour les assemblées générales prévues mais n'ayant pas encore
						eues lieu).
					</div>
				</div>
				<div className="card unscrollable">
					<div className="card-header">
						<h2>Trésorerie globale</h2>
					</div>
					<div className="card-body">
						{this.state.is_loading_bank_graph === false ? (
							Object.keys(this.props.banks.graph).length
							&& this.props.banks.graph.series.length ? (
								<Fragment>
									<GraphLines
										id="bank-graph"
										title="Évolution de la trésorerie saisie dans votre comptabilité sur les 12 derniers mois"
										x={this.props.banks.graph.x}
										series={this.props.banks.graph.series}
									/>
									<div className="btn-wrapper">
										<Link
											className="btn"
											to={`/coownership/${this.props.match.params.id}/bank`}
										>
											Accéder au détail du compte courant
										</Link>
									</div>
								</Fragment>
							) : (
								<p>
									Aucune donnée n'est disponible sur les 36 derniers
									mois.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		banks: state.banks,
		notifications: state.notifications,
	};
};

const mapDispatchToProps = {
	addNotifications,
	setBankGraph,
	setBanks,
	setCurrentBank,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashBoard));
