//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableBank from '../../components/table/TableBank';
import { SelectorItemObject } from '../../components/form/item/SelectorItem';
import PrintButton from '../../components/button/PrintButton';
import Loading from '../../components/Loading';
//actions
import {
	setBankTransactions,
	setBanks,
	setCurrentBank,
	setBankExercices,
	setBankCurrentExercice,
} from '../../action_creators/banksActionCreators';
import { setLog } from '../../action_creators/logActionCreators';
// function
import { getRequest, responseManagment } from '../../functions/ajax';
import { printTable } from '../../functions/print';
import { getActivityParameters } from '../../functions/coownerships';

class MyBank extends Component {
	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.handleExercice = this.handleExercice.bind(this);
		this.handleAccount = this.handleAccount.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.goBack = this.goBack.bind(this);
		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.banks.exercices.length) {
			this.getBank();
		} else if (this.props.banks.current_bank < 0) {
			this.props.setCurrentBank(
				this.props.banks.list.findIndex(bank => bank.default)
			);
		} else if (!Object.keys(this.props.banks.transactions).length) {
			this.updateBank();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.banks.current_bank !== this.props.banks.current_bank
			|| prevProps.banks.current_exercice !== this.props.banks.current_exercice
		) {
			this.updateBank();
		}
	}

	async getBank() {
		const banks = this.props.banks;
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/banque-data?`
				+ getActivityParameters(this.props.coownerships.current)
				+ (this.props.banks.current_bank >= 0 && banks.list.length
					? `&compte_id=${banks.list[banks.current_bank].id}`
					: ''),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			// If solde of banks is undefined we should reload banks
			if (!banks.list.length || banks.list[0].solde === undefined) {
				this.props.setBanks(response.comptesBanque);
			}
			this.props.setBankExercices(response.exercices);
			this.props.setBankTransactions(response.compteLignes);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateBank() {
		const banks = this.props.banks;
		if (banks.exercices.length && banks.list.length) {
			this.setState({ is_loading: true });
			const response = await getRequest(
				`/banque-data?`
					+ getActivityParameters(this.props.coownerships.current)
					+ `&start=${banks.exercices[banks.current_exercice].start}`
					+ `&end=${banks.exercices[banks.current_exercice].end}`
					+ `&compte_id=${banks.list[banks.current_bank].id}`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setBankTransactions(response.compteLignes);
			} else {
				this.props.setBankTransactions([]);
			}

			if (this._isMounted) {
				this.setState({ is_loading: false });
			}
		}
	}

	goBack(evt) {
		if (this.props.history.length < 3) {
			this.props.history.push(
				`/coownership/${this.props.match.params.id}/dashboard`
			);
		} else {
			this.props.history.goBack();
		}
	}

	handleExercice(evt) {
		this.props.setBankCurrentExercice(evt.target.value);
	}
	handleAccount(evt) {
		this.props.setCurrentBank(evt.target.value);
	}
	handlePrint(evt) {
		printTable(
			'table-bank',
			'banque-copropriete.pdf',
			'Banque',
			this.props.coownerships.current
		);
	}

	render() {
		const banks = this.props.banks;
		const current_coownership = this.props.coownerships.current;
		return (
			<div className="grid grid-1">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Banque</h2>
						<PrintButton
							picto_color={current_coownership.syndic}
							print={this.handlePrint}
						/>
					</div>
					<div className="card-body table-wrapper" id="toPrint">
						<div className="standfirst">
							{current_coownership.coowner.cs && banks.list.length ? (
								<SelectorItemObject
									id="select-bank-account"
									label="Compte"
									current={banks.current_bank}
									options={banks.list}
									callback={this.handleAccount}
								/>
							) : null}
							{banks.exercices.length ? (
								<SelectorItemObject
									id="select-bank-exerice"
									label="Période"
									current={banks.current_exercice}
									options={banks.exercices}
									callback={this.handleExercice}
								/>
							) : null}
						</div>
						{this.state.is_loading === false ? (
							banks.transactions.length ? (
								<TableBank
									cols={[
										'DATE',
										'LIBELLÉ',
										'ENCAISSEMENT',
										'DÉCAISSEMENT',
										'SOLDE',
									]}
									table={banks.transactions}
									solde={
										banks.list[banks.current_bank]
											? banks.list[banks.current_bank].solde
											: 0
									}
								/>
							) : (
								<p>
									Il n'y a pas d'opération sur ce compte à cette
									période.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		banks: state.banks,
	};
};
const mapDispatchToProps = {
	setBankTransactions,
	setBanks,
	setCurrentBank,
	setBankExercices,
	setBankCurrentExercice,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyBank));
