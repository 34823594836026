import {
	SET_GLOBAL_ACCOUNT_AMOUNTS,
	SET_ACCOUNT_NUMBER,
	SET_ACCOUNT_EXERCICES,
	SET_ACCOUNT_CURRENT_EXERCICE,
    SET_ACCOUNT_TRANSACTIONS,
    SET_ADVANCES,
    REMOVE_ACCOUNT
} from "../action_types/accountActionTypes";

const initialState = {
	global_amounts: {},
	transactions: [],
	exercices: [],
	current_exercice: 0,
	history: {
		global: { exercice: 0 },
		transactions: { exercice: 0 }
	},
	advances: []
};

function accountReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case SET_GLOBAL_ACCOUNT_AMOUNTS:
			nextState = {
				...state,
				global_amounts: action.amounts,
				history: {
					...state.history,
					global: { exercice: state.current_exercice }
				}
			};
			return nextState || state;

		case SET_ACCOUNT_NUMBER:
			nextState = {
				...state,
				number: action.number
			};
			return nextState || state;

		case SET_ACCOUNT_EXERCICES:
			nextState = {
				...state,
				exercices: action.exercices
			};
			return nextState || state;

		case SET_ACCOUNT_CURRENT_EXERCICE:
			nextState = {
				...state,
				current_exercice: action.exercice
			};
			return nextState || state;

		case SET_ACCOUNT_TRANSACTIONS:
			nextState = {
				...state,
				transactions: action.transactions,
				history: {
					...state.history,
					transactions: { exercice: state.current_exercice }
				}
			};
			return nextState || state;

		case SET_ADVANCES:
			nextState = {
				...state,
				advances: action.advances
			};
			return nextState || state;

		case REMOVE_ACCOUNT:
			return initialState;

		default:
			return state;
	}
}

export default accountReducer;
