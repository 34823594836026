// module
import React, { Component } from 'react';
// components
import { TableBase, colorEvenRows } from './Table';
import TdAmount from './TdAmount';

class TableGreatBook extends Component {
	render() {
		return (
			<TableBase className="table-great-book" cols={this.props.cols}>
				{this.props.table.map((account, i) => (
					<tbody key={i}>
						<tr className="subtitle">
							<td colSpan={this.props.cols.length}>
								{account.account_number} - {account.account_label}
							</td>
						</tr>
						{account.detail.map((value, j) => (
							<tr key={j} className={colorEvenRows(j)}>
								<td className="td-label">{value.label}</td>
								<td className="date">{value.date}</td>
								<TdAmount value={value.debit} negative={true} />
								<TdAmount value={value.credit} />
								<td>{value.journal}</td>
								{value.association ? (
									<td
										style={{
											backgroundColor: `#${value.association.color}`,
										}}
									>
										<div className="association-ref">
											{value.association.code}{' '}
										</div>
										<span>
											{value.association.full
												? 'Complet'
												: 'Incomplet'}
										</span>
									</td>
								) : (
									<td></td>
								)}
							</tr>
						))}
						<tr className="subtotal">
							<td className="td-align-right" colSpan={2}>
								Total compte {account.account_label}
							</td>
							<TdAmount value={account.cumul_debit} negative={true} />
							<TdAmount value={account.cumul_credit} />
							<TdAmount value={account.solde} />
							<td></td>
						</tr>
					</tbody>
				))}
			</TableBase>
		);
	}
}

export default TableGreatBook;
