// module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import Form from '../../../components/form/Form';
import Loading from '../../../components/Loading';
// actions
import { setLog } from '../../../action_creators/logActionCreators';
import { setSyndic } from '../../../action_creators/syndicActionCreators';
// functions
import { getRequest, postRequest, responseManagment } from '../../../functions/ajax';
import { getActivityParameters } from '../../../functions/coownerships';

class Contact extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading_syndic: false,
			is_sending: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.postMessage = this.postMessage.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getSyndic();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getSyndic() {
		if (!this.props.syndic.email) {
			this.setState({ is_loading_syndic: true });
			const response = await getRequest(
				`/syndic?` + getActivityParameters(this.props.coownerships.current),
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setSyndic(response.syndic);
			}

			if (this._isMounted) {
				this.setState({ is_loading_syndic: false });
			}
		}
	}

	async postMessage(form_value) {
		this.setState({ is_sending: true });
		const response = await postRequest(
			`/message-gestionnaire?`
				+ getActivityParameters(this.props.coownerships.current),
			form_value,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message: 'Message envoyé au gestionnaire avec succès.',
			});
		}

		if (this._isMounted) {
			this.setState({ is_sending: false });
		}
	}

	render() {
		return (
			<div className="grid grid-1 contact">
				<div className="card">
					<div className="card-header">
						<h2>Contactez votre gestionnaire</h2>
					</div>
					<div className="card-body">
						{this.state.is_loading_syndic === false ? (
							Object.keys(this.props.syndic).length ? (
								<Fragment>
									<blockquote>
										Votre message va être envoyé à&nbsp;:{' '}
										{this.props.syndic.email}
									</blockquote>
									{this.state.is_sending === false ? (
										<Form
											form_items={[
												{
													type: 'text',
													name: 'object',
													label: `Objet`,
													required: true,
												},
												{
													type: 'textarea',
													name: 'message',
													label: `Message`,
													required: true,
												},
											]}
											callback={this.postMessage}
										/>
									) : (
										<Loading />
									)}
								</Fragment>
							) : (
								<p>
									Vous n'avez plus de gestionnaire, vous ne pouvez donc
									pas le contacter.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		coownerships: state.coownerships,
		syndic: state.syndic,
	};
};
const mapDispatchToProps = {
	setLog,
	setSyndic,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact));
