// modules
import { combineReducers } from 'redux';
// reducers
import user from './userReducer';
import coownerships from './coownershipsReducer';
import account from './accountReducer';
import reminders from './remindersReducer';
import capital_calls from './capitalCallsReducer';
import land_declarations from './landDeclarationsReducer';
import debit_mandates from './debitMandatesReducer';
import counters from './countersReducer';
import budget from './budgetReducer';
import exercices_state from './exercicesStateReducer';
// import products from './productsReducer';
import charges from './chargesReducer';
import tenant_charges from './tenantChargesReducer';
import works from './worksReducer';
import visits from './visitsReducer';
import council from './councilReducer';
import balance from './balanceReducer';
import great_book from './greatBookReducer';
import notifications from './notificationsReducer';
import banks from './banksReducer';
import lots from './lotsReducer';
import syndic from './syndicReducer';
import keys from './keysReducer';
import incidents from './incidentsReducer';
import docs from './docsReducer';
import log from './logReducer';

// TODO don't forget to add a remove function in src\class\constants\clearCoownershipActionList.js
// to clear storage from your entity's data when change coownership
const reducerCombiner = combineReducers({
	coownerships,
	user,
	/* amounts */
	exercices_state,
	// products,
	charges,
	tenant_charges,
	/* account */
	account,
	reminders,
	capital_calls,
	land_declarations,
	debit_mandates,
	counters,
	/* council */
	council,
	balance,
	great_book,
	syndic,
	/* leftovers */
	notifications,
	budget,
	incidents,
	works,
	visits,
	banks,
	keys,
	lots,
	docs,
	log,
});

export default reducerCombiner;
