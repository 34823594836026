// module
import React, { Component } from 'react';
// components
import PropTypes from 'prop-types';

class TableCoowner extends Component {
	render() {
		return (
			<table className={`table table-syndic ${this.props.className}`}>
				<tbody>
					<tr>
						<th>nom</th>
						<td>{this.props.table.name}</td>
					</tr>
					<tr>
						<th>adresse</th>
						<td>
							{this.props.table.address
								? this.props.table.address.full
								: null}
						</td>
					</tr>
					<tr>
						<th>téléphone</th>
						<td>{this.props.table.telephone}</td>
					</tr>
					<tr>
						<th>Membre&nbsp;du conseil&nbsp;syndical</th>
						<td>{this.props.table.cs ? 'Oui' : 'Non'}</td>
					</tr>
				</tbody>
			</table>
		);
	}
}

TableCoowner.propTypes = {
	table: PropTypes.object.isRequired,
	className: PropTypes.string,
};

export default TableCoowner;
