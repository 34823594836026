//module
import React from 'react';

const Notification = props => (
	<div className="notification">
		{!!props.title && <h2>{props.title}</h2>}
		{!!props.children && <p>{props.children}</p>}
	</div>
);

export default Notification;
