//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import FormSimulation from '../../../components/form/FormSimulation';
import Loading from '../../../components/Loading';
//action
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { formatNumbers } from '../../../functions';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { getActivityParameters } from '../../../functions/coownerships';

class Simulation extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			keys: [],
			simulation: {},
			is_loading_date: false,
			is_loading_simulate: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.goBack = this.goBack.bind(this);
		this.handleSimulate = this.handleSimulate.bind(this);
		this.handleDate = this.handleDate.bind(this);
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	goBack(evt) {
		this.props.history.push(`/coownership/${this.props.match.params.id}/account/`);
	}

	async handleSimulate(values) {
		this.setState({ is_loading_simulate: true });
		// Get key object from key id
		const key = this.state.keys.filter(key => key.id === parseInt(values.key))[0];
		const response = await getRequest(
			`/simulation-cle?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&date_etat=${values.date}`
				+ `&key=${values.key}`
				+ `&amount=${values.amount}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			if (this._isMounted) {
				this.setState({
					simulation: {
						key: key,
						amount: values.amount,
						coowner_amount: response.amountCopro,
						tantieme: response.tantiemesCle,
						coowner_tantieme: response.tantiemesPersonne,
					},
					is_loading_simulate: false,
				});
			}
		} else if (this._isMounted) {
			this.setState({ is_loading_simulate: false });
		}
	}

	async handleDate(date) {
		this.setState({ is_loading_date: true });
		const response = await getRequest(
			`/simulation-cle?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&date_etat=${date}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			if (!response.keys.length) {
				this.props.setLog({
					type: 'success',
					message:
						"Il n'y a pas de clé de répartition à cette date sur cette copropriété.",
				});
			}
			// set state full or void key array
			if (this._isMounted) {
				this.setState({
					keys: response.keys,
					// reset simulation
					simulation: {},
					is_loading_date: false,
				});
			}
		} else if (this._isMounted) {
			this.setState({ is_loading_date: false });
		}
	}

	render() {
		return (
			<div className="grid grid-1 simulation">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Simulation par clé de répartition</h2>
					</div>
					<div className="card-body">
						{this.state.is_loading_date === true && <Loading />}
						<FormSimulation
							keys={this.state.keys}
							callback={this.handleSimulate}
							handleDate={this.handleDate}
							setLog={log => this.props.setLog(log)}
						/>
						{this.state.is_loading_simulate === false ? (
							Object.keys(this.state.simulation).length ? (
								<p>
									Vous représentez{' '}
									<b>{this.state.simulation.coowner_tantieme}</b>{' '}
									tantièmes de la clé de répartition{' '}
									<b>{this.state.simulation.key.name}</b> sur{' '}
									<b>{this.state.simulation.tantieme}</b>
									.<br />
									Le montant total de la charge pour la copropriété est
									de{' '}
									<b>
										{formatNumbers(this.state.simulation.amount)}
										&nbsp;€
									</b>
									.<br />
									Le montant vous incombant sera donc de{' '}
									<b>
										{formatNumbers(
											this.state.simulation.coowner_amount
										)}
										&nbsp;€
									</b>
									.
								</p>
							) : null
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
	};
};
const mapDispatchToProps = {
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Simulation));
