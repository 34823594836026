import * as types from '../action_types/syndicActionTypes';

function syndicReducer(state = {}, action) {
    let nextState;

    switch (action.type) {

        case types.SET_SYNDIC:
            nextState = action.syndic;
            return nextState || state;

        case types.REMOVE_SYNDIC:
            return {};

        default:
            return state;
    }
}

export default syndicReducer;