export const REMOVE_COUNCIL = 'REMOVE_COUNCIL';
// Council
export const SET_COUNCIL_MEMBERS = 'SET_COUNCIL_MEMBERS';
// Balance
export const SET_BALANCE_TRANSACTIONS = 'SET_BALANCE_TRANSACTIONS';
export const SET_BALANCE_EXERCICES = 'SET_BALANCE_EXERCICES';
export const SET_BALANCE_CURRENT_EXERCICE = 'SET_BALANCE_CURRENT_EXERCICE';
export const REMOVE_BALANCE = 'REMOVE_BALANCE';
// Great Book
export const SET_GREAT_BOOK_TRANSACTIONS = 'SET_GREAT_BOOK_TRANSACTIONS';
export const SET_GREAT_BOOK_EXERCICES = 'SET_GREAT_BOOK_EXERCICES';
export const SET_GREAT_BOOK_CURRENT_EXERCICE = 'SET_GREAT_BOOK_CURRENT_EXERCICE';
export const REMOVE_GREAT_BOOK = 'REMOVE_GREAT_BOOK';
// Judicial Procedure
export const SET_JUDICIAL_PROCEDURES = 'SET_JUDICIAL_PROCEDURES';
// Coowner List
export const SET_COOWNERS = 'SET_COOWNERS';
export const REMOVE_COOWNERS = 'REMOVE_COOWNERS';
export const SET_NB_PAGES_COOWNERS = 'SET_NB_PAGES_COOWNERS';