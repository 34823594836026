import * as types from '../action_types/coownershipAmountsActionTypes';

export const setBudget = budget => ({
	type: types.SET_BUDGET,
	budget,
});
export const removeBudget = () => ({
	type: types.REMOVE_BUDGET,
});
// EXERCICES
export const setGlobalExercicesStateAmounts = amounts => ({
	type: types.SET_GLOBAL_EXERCICES_STATE_AMOUNTS,
	amounts,
});
export const setExercicesStateTransactions = transactions => ({
	type: types.SET_EXERCICES_STATE_TRANSACTIONS,
	transactions,
});
export const setExercicesStateExercices = exercices => ({
	type: types.SET_EXERCICES_STATE_EXERCICES,
	exercices,
});
export const setExercicesStateCurrentExercice = exercice => ({
	type: types.SET_EXERCICES_STATE_CURRENT_EXERCICE,
	exercice,
});
export const setExercicesStateCommonOperation = common_operation => ({
	type: types.SET_EXERCICES_STATE_COMMON_OPERATION,
	common_operation,
});
export const removeExercicesState = () => ({
	type: types.REMOVE_EXERCICES_STATE,
});
// PRODUCTS
export const setGlobalProductsAmounts = amounts => ({
	type: types.SET_GLOBAL_PRODUCTS_AMOUNTS,
	amounts,
});
export const setProductsTransactions = transactions => ({
	type: types.SET_PRODUCTS_TRANSACTIONS,
	transactions,
});
export const setProductsExercices = exercices => ({
	type: types.SET_PRODUCTS_EXERCICES,
	exercices,
});
export const setProductsCurrentExercice = exercice => ({
	type: types.SET_PRODUCTS_CURRENT_EXERCICE,
	exercice,
});
export const validProductsCache = is_valid => ({
	type: types.VALID_PRODUCTS_CACHE,
	is_valid,
});
export const removeProducts = () => ({
	type: types.REMOVE_PRODUCTS,
});
// CHARGES
export const setGlobalChargesAmounts = amounts => ({
	type: types.SET_GLOBAL_CHARGES_AMOUNTS,
	amounts,
});
export const setChargesTransactions = transactions => ({
	type: types.SET_CHARGES_TRANSACTIONS,
	transactions,
});
export const setChargesExercices = exercices => ({
	type: types.SET_CHARGES_EXERCICES,
	exercices,
});
export const setChargesCurrentExercice = exercice => ({
	type: types.SET_CHARGES_CURRENT_EXERCICE,
	exercice,
});
export const validChargesCache = is_valid => ({
	type: types.VALID_CHARGES_CACHE,
	is_valid,
});
export const removeCharges = () => ({
	type: types.REMOVE_CHARGES,
});
//TENANT CHARGES
export const setGlobalTenantChargesAmounts = amounts => ({
	type: types.SET_GLOBAL_TENANT_CHARGES_AMOUNTS,
	amounts,
});
export const setTenantChargesTransactions = transactions => ({
	type: types.SET_TENANT_CHARGES_TRANSACTIONS,
	transactions,
});
export const setTenantChargesExercices = exercices => ({
	type: types.SET_TENANT_CHARGES_EXERCICES,
	exercices,
});
export const setTenantChargesCurrentExercice = exercice => ({
	type: types.SET_TENANT_CHARGES_CURRENT_EXERCICE,
	exercice,
});
export const setTenantChargesLots = lots => ({
	type: types.SET_TENANT_CHARGES_LOTS,
	lots,
});
export const validTenantChargesCache = is_valid => ({
	type: types.VALID_TENANT_CHARGES_CACHE,
	is_valid,
});
export const removeTenantCharges = () => ({
	type: types.REMOVE_TENANT_CHARGES,
});
// WORKS
export const setGlobalWorks = works => ({
	type: types.SET_GLOBAL_WORKS,
	works,
});
export const setWorks = (works, in_progress) => ({
	type: types.SET_WORKS,
	works,
	in_progress,
});
export const setWorkSteps = (steps, id, in_progress) => ({
	type: types.SET_WORK_STEPS,
	steps,
	id,
	in_progress,
});
export const setWorksExercices = exercices => ({
	type: types.SET_WORKS_EXERCICES,
	exercices,
});
export const setWorksCurrentExercice = exercice => ({
	type: types.SET_WORKS_CURRENT_EXERCICE,
	exercice,
});
export const removeWorks = () => ({
	type: types.REMOVE_WORKS,
});
// VISITS
export const setGlobalVisits = visits => ({
	type: types.SET_GLOBAL_VISITS,
	visits,
});
export const setVisits = visits => ({
	type: types.SET_VISITS,
	visits,
});
export const setVisitsExercices = exercices => ({
	type: types.SET_VISITS_EXERCICES,
	exercices,
});
export const setVisitsCurrentExercice = exercice => ({
	type: types.SET_VISITS_CURRENT_EXERCICE,
	exercice,
});
export const removeVisits = () => ({
	type: types.REMOVE_VISITS,
});
