import * as types from '../action_types/banksActionTypes';

export const setBanks = (banks, current_bank, graph) => ({
	type: types.SET_BANKS,
	banks,
	current_bank,
	graph,
});
export const setCurrentBank = index => ({
	type: types.SET_CURRENT_BANK,
	index,
});

export const setBankGraph = graph => ({
	type: types.SET_BANK_GRAPH,
	graph,
});

export const setBankTransactions = transactions => ({
	type: types.SET_BANK_TRANSACTIONS,
	transactions,
});
export const setBankExercices = exercices => ({
	type: types.SET_BANKS_EXERCICES,
	exercices,
});
export const setBankCurrentExercice = exercice => ({
	type: types.SET_BANKS_CURRENT_EXERCICE,
	exercice,
});

export const removeBank = () => ({
	type: types.REMOVE_BANKS,
});
