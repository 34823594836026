//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

class AsideNav extends Component {
	render() {
		if (Object.keys(this.props.coownerships.current).length) {
			return (
				<nav>
					<ul>
						<li>
							<NavLink
								to={`/coownership/${this.props.coownerships.current.coowner.id}/dashboard`}
							>
								Tableau de bord
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/coownership/${this.props.coownerships.current.coowner.id}/data`}
							>
								Mes données
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/coownership/${this.props.coownerships.current.coowner.id}/incidents`}
							>
								Incidents
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/coownership/${this.props.coownerships.current.coowner.id}/account`}
							>
								Mon compte
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/coownership/${this.props.coownerships.current.coowner.id}/coownership`}
							>
								Ma copropriété
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/coownership/${this.props.coownerships.current.coowner.id}/judicials`}
							>
								Mon juridique
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`/coownership/${this.props.coownerships.current.coowner.id}/files`}
							>
								Mes fichiers
							</NavLink>
						</li>
						{this.props.coownerships.current.coowner.cs ? (
							<li>
								<NavLink
									to={`/coownership/${this.props.coownerships.current.coowner.id}/coownership_council`}
								>
									Conseil syndical
								</NavLink>
							</li>
						) : null}
						{this.props.coownerships.current.syndic ? (
							<li className="contact-link">
								<NavLink
									to={`/coownership/${this.props.coownerships.current.coowner.id}/contact`}
								>
									Contact
								</NavLink>
							</li>
						) : null}
						{/* <li className="my-coownership-link">
                            <NavLink exact to="/">Mes copropriétés</NavLink>
                        </li> */}
					</ul>
				</nav>
			);
		} else {
			return (
				<nav>
					<ul>
						<li>
							<NavLink exact to="/">
								Mes copropriétés
							</NavLink>
						</li>
						<li>
							<NavLink to="/info">Mes informations</NavLink>
						</li>
					</ul>
				</nav>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
	};
};

export default withRouter(connect(mapStateToProps)(AsideNav));
