// Budget
export const SET_BUDGET = 'SET_BUDGET';
export const REMOVE_BUDGET = 'REMOVE_BUDGET';
// Exercice
export const SET_GLOBAL_EXERCICES_STATE_AMOUNTS = 'SET_GLOBAL_EXERCICES_STATE_AMOUNTS';
export const SET_EXERCICES_STATE_TRANSACTIONS = 'SET_EXERCICES_STATE_TRANSACTIONS';
export const SET_EXERCICES_STATE_EXERCICES = 'SET_EXERCICES_STATE_EXERCICES';
export const SET_EXERCICES_STATE_CURRENT_EXERCICE =
	'SET_EXERCICES_STATE_CURRENT_EXERCICE';
export const SET_EXERCICES_STATE_COMMON_OPERATION =
	'SET_EXERCICES_STATE_COMMON_OPERATION';
export const REMOVE_EXERCICES_STATE = 'REMOVE_EXERCICES_STATE';
// Produit
export const SET_GLOBAL_PRODUCTS_AMOUNTS = 'SET_GLOBAL_PRODUCTS_AMOUNTS';
export const SET_PRODUCTS_TRANSACTIONS = 'SET_PRODUCTS_TRANSACTIONS';
export const SET_PRODUCTS_EXERCICES = 'SET_PRODUCTS_EXERCICES';
export const SET_PRODUCTS_CURRENT_EXERCICE = 'SET_PRODUCTS_CURRENT_EXERCICE';
export const REMOVE_PRODUCTS = 'REMOVE_PRODUCTS';
export const VALID_PRODUCTS_CACHE = 'VALID_PRODUCTS_CACHE';
// Charges
export const SET_GLOBAL_CHARGES_AMOUNTS = 'SET_GLOBAL_CHARGES_AMOUNTS';
export const SET_CHARGES_TRANSACTIONS = 'SET_CHARGES_TRANSACTIONS';
export const SET_CHARGES_EXERCICES = 'SET_CHARGES_EXERCICES';
export const SET_CHARGES_CURRENT_EXERCICE = 'SET_CHARGES_CURRENT_EXERCICE';
export const REMOVE_CHARGES = 'REMOVE_CHARGES';
export const VALID_CHARGES_CACHE = 'VALID_CHARGES_CACHE';
// Tenant Charges
export const SET_GLOBAL_TENANT_CHARGES_AMOUNTS = 'SET_GLOBAL_TENANT_CHARGES_AMOUNTS';
export const SET_TENANT_CHARGES_TRANSACTIONS = 'SET_TENANT_CHARGES_TRANSACTIONS';
export const SET_TENANT_CHARGES_EXERCICES = 'SET_TENANT_CHARGES_EXERCICES';
export const SET_TENANT_CHARGES_CURRENT_EXERCICE = 'SET_TENANT_CHARGES_CURRENT_EXERCICE';
export const SET_TENANT_CHARGES_LOTS = 'SET_TENANT_CHARGES_LOTS';
export const REMOVE_TENANT_CHARGES = 'REMOVE_TENANT_CHARGES';
export const VALID_TENANT_CHARGES_CACHE = 'VALID_TENANT_CHARGES_CACHE';
// Works
export const SET_GLOBAL_WORKS = 'SET_GLOBAL_WORKS';
export const SET_WORKS = 'SET_WORKS';
export const SET_WORK_STEPS = 'SET_WORK_STEPS';
export const SET_WORKS_EXERCICES = 'SET_WORKS_EXERCICES';
export const SET_WORKS_CURRENT_EXERCICE = 'SET_WORKS_CURRENT_EXERCICE';
export const REMOVE_WORKS = 'REMOVE_WORKS';
// Visits
export const SET_GLOBAL_VISITS = 'SET_GLOBAL_VISITS';
export const SET_VISITS = 'SET_VISITS';
export const SET_VISITS_EXERCICES = 'SET_VISITS_EXERCICES';
export const SET_VISITS_CURRENT_EXERCICE = 'SET_VISITS_CURRENT_EXERCICE';
export const REMOVE_VISITS = 'REMOVE_VISITS';
