// module
import React, { Component, Fragment } from 'react';
// components
import { TableBase, colorEvenRows } from './Table';
import TdAmount from './TdAmount';
// functions
import { accumulate } from '../../functions';

class SubTableBalance extends Component {
	render() {
		return (
			<Fragment>
				<tr className="subtitle">
					<td colSpan={this.props.cols_nb}>
						Compte «&nbsp;{this.props.label}&nbsp;»
					</td>
				</tr>
				{this.props.table.map((transaction, j) => (
					<tr key={j} className={colorEvenRows(j)}>
						<td>{transaction.number}</td>
						<td className="td-label">{transaction.label}</td>
						<TdAmount value={transaction.debit} />
						<TdAmount value={transaction.credit} />
						<TdAmount value={transaction.solde} />
					</tr>
				))}
				<tr className="subtotal">
					<td className="td-align-right" colSpan={2}>
						Total compte «&nbsp;{this.props.label}&nbsp;»
					</td>
					<TdAmount value={accumulate(this.props.table, 'debit')} />
					<TdAmount value={accumulate(this.props.table, 'credit')} />
					<TdAmount value={accumulate(this.props.table, 'solde')} />
				</tr>
			</Fragment>
		);
	}
}

class TableBalance extends Component {
	render() {
		let account_provisions = this.props.table.filter(
			account => account.number[0] === '1'
		);
		let account_coowners = this.props.table.filter(
			account => account.number[0] === '4'
		);
		let account_financial = this.props.table.filter(
			account => account.number[0] === '5'
		);
		let account_charges = this.props.table.filter(
			account => account.number[0] === '6'
		);
		let account_products = this.props.table.filter(
			account => account.number[0] === '7'
		);

		return (
			<TableBase className="table-balance" cols={this.props.cols}>
				<tbody>
					{account_provisions.length ? (
						<SubTableBalance
							table={account_provisions}
							label="Provisions, avances, subventions et emprunts"
							cols_nb={this.props.cols.length}
						/>
					) : null}
					{account_coowners.length ? (
						<SubTableBalance
							table={account_coowners}
							label="Copropriétaires et tiers"
							cols_nb={this.props.cols.length}
						/>
					) : null}
					{account_financial.length ? (
						<SubTableBalance
							table={account_financial}
							label="Comptes financiers"
							cols_nb={this.props.cols.length}
						/>
					) : null}
					{account_charges.length ? (
						<SubTableBalance
							table={account_charges}
							label="Charges"
							cols_nb={this.props.cols.length}
						/>
					) : null}
					{account_products.length ? (
						<SubTableBalance
							table={account_products}
							label="Produits"
							cols_nb={this.props.cols.length}
						/>
					) : null}
					<tr className="total">
						<td className="td-align-right" colSpan={2}>
							Total
						</td>
						<TdAmount value={accumulate(this.props.table, 'debit')} />
						<TdAmount value={accumulate(this.props.table, 'credit')} />
						<TdAmount value={accumulate(this.props.table, 'solde')} />
					</tr>
				</tbody>
			</TableBase>
		);
	}
}

export default TableBalance;
