const nb_formatter = new Intl.NumberFormat('fr-FR', {
	minimumFractionDigits: 2,
});
/**
 * @param {number} nb
 * @return {string}
 */
export function formatNumbers(nb) {
	let formated;
	if (nb < 0.005 && nb > -0.005) {
		// prevent from -0,00 for very small negative values
		nb = 0;
	}
	formated = nb_formatter.format(nb);
	formated = formated.replace(/\s+/g, '\u00A0');
	return formated;
}

/**
 * @param {Date} date
 * @return {string} : DD/MM/YYYY
 */
export function formateDate(date) {
	let day = date.getDate();
	let month = date.getMonth() + 1;
	const year = date.getFullYear();
	if (day < 10) {
		day = '0' + day;
	}
	if (month < 10) {
		month = '0' + month;
	}
	return `${day}/${month}/${year}`;
}

/**
 * @param {string} color
 * @return {string}
 */
export function chooseColor(color) {
	if (!color) {
		return 'blue';
	} else if (color === 'pro') {
		return 'blue';
	} else if (color === 'ben') {
		// return 'green';
		return 'blue';
	} else {
		return color;
	}
}

/**
 * @param {array} array
 * @param {string, number} key
 * @return {string, number}
 */
export function accumulate(array, key, second_key) {
	// make a total with an array of object
	if (second_key) {
		return array.reduce(
			(accumulator, el) => accumulator + parseFloat(el[key][second_key]),
			0
		);
	}
	return array.reduce((accumulator, el) => accumulator + parseFloat(el[key]), 0);
}

/**
 * @argument {args} classList of [string, array, false, null, undefined]
 * @returns {string}
 */
export const makeClassName = (...classList) =>
	classList.reduce((classList, className) => {
		if (!className) return classList;
		if (Array.isArray(className)) className = makeClassName(className);
		if (!classList) return className;
		return classList + ' ' + className;
	}, '');
// faster than classList.flat().filter(c => c).join(' ')

/**
 * @param {blob} blob
 * @param {string} filename
 */
export function autoDownload(blob, filename) {
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(blob);
	} else {
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style = 'display: none';
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}
}
