// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// component
import FormItem from './item/FormItem';
import CheckboxItem from './item/CheckboxItem';

class Form extends Component {
	constructor(props) {
		super(props);

		this.initialState = {};
		props.form_items.forEach(form_item => {
			switch (form_item.type) {
				case 'checkbox':
					this.initialState[form_item.name] = false;
					break;
				default:
					this.initialState[form_item.name] = '';
			}
		});
		this.state = { ...this.initialState };

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(evt) {
		const input_name = evt.target.getAttribute('name');
		// Fill state attribute with input value of an input with the same name as the state attribute
		let nextState = {};

		switch (evt.target.type) {
			case 'checkbox':
				nextState[input_name] = evt.target.checked;
				break;
			default:
				nextState[input_name] = evt.target.value;
		}

		this.setState(nextState);
	}

	resetInputs() {
		this.setState({ ...this.initialState });
	}

	handleSubmit(evt) {
		evt.preventDefault();
		this.props.callback({ ...this.state });
		this.resetInputs();
	}

	buildFormItems() {
		return this.props.form_items.map((form_item, i) => {
			switch (form_item.type) {
				case 'checkbox':
					return (
						<CheckboxItem
							key={i}
							input={form_item}
							checked={this.state[form_item.name]}
							callback={this.handleChange}
						/>
					);

				default:
					return (
						<FormItem
							key={i}
							input={form_item}
							value={this.state[form_item.name]}
							callback={this.handleChange}
						/>
					);
			}
		});
	}

	render() {
		let form_item = this.buildFormItems();

		return (
			<form className="form" onSubmit={this.handleSubmit}>
				<div className="form-item-list">{form_item}</div>
				<div className="submit-button-wrapper">
					<button className="btn btn-orange submit-button" type="submit">
						Valider
					</button>
				</div>
			</form>
		);
	}
}

Form.propTypes = {
	form_items: PropTypes.arrayOf(PropTypes.object).isRequired,
	callback: PropTypes.func.isRequired,
};

Form.defaultProps = {
	form_items: [],
};

export default Form;
