//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableCapitalCalls from '../../../components/table/TableCapitalCalls';
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
// import ToggleButtons from '../../../components/button/ToggleButtons';
import Loading from '../../../components/Loading';
//action
import {
	setCapitalCalls,
	setCapitalCallsExercices,
	setCapitalCallsCurrentExercice,
} from '../../../action_creators/accountActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { getActivityParameters } from '../../../functions/coownerships';

class CapitalCalls extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = { is_loading: false };

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.capital_calls.list.length) {
			this.getCapitalCalls();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		const capital_calls = this.props.capital_calls;
		if (prevProps.capital_calls.current_exercice !== capital_calls.current_exercice) {
			// exercice change
			this.updateCapitalCalls();
		}
	}

	async getCapitalCalls() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/appel-fonds?` + getActivityParameters(this.props.coownerships.current),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setCapitalCallsExercices(response.exercices);
			this.props.setCapitalCalls(response.appelFonds);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateCapitalCalls() {
		const calls = this.props.capital_calls;
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/appel-fonds?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&start=${calls.exercices[calls.current_exercice].start}`
				+ `&end=${calls.exercices[calls.current_exercice].end}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setCapitalCalls(response.appelFonds);
		} else {
			this.props.setCapitalCalls([]);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	goBack(evt) {
		this.props.history.push(`/coownership/${this.props.match.params.id}/account/`);
	}

	handleExercice(evt) {
		this.props.setCapitalCallsCurrentExercice(evt.target.value);
	}

	render() {
		return (
			<div className="grid grid-1 capital-calls">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Appels de fonds</h2>
					</div>
					<div className="card-body table-wrapper">
						<div className="standfirst toggle-plus-select">
							{this.props.capital_calls.exercices.length > 0 && (
								<SelectorItemObject
									id="select-capitall-calls-exerice"
									label="Période"
									current={this.props.capital_calls.current_exercice}
									options={this.props.capital_calls.exercices}
									callback={this.handleExercice}
								/>
							)}
						</div>
						{this.state.is_loading === false ? (
							this.props.capital_calls.list.length ? (
								<TableCapitalCalls
									cols={[
										'LIBELLÉ',
										'DATE ÉCRITURE',
										'MONTANT TTC',
										// 'RESTANT',
										'OPTIONS',
									]}
									table={this.props.capital_calls.list}
									className={'table-capital-calls'}
									picto_color={this.props.coownerships.current.syndic}
									activity={this.props.coownerships.current}
								/>
							) : null
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		capital_calls: state.capital_calls,
	};
};
const mapDispatchToProps = {
	setCapitalCalls,
	setCapitalCallsExercices,
	setCapitalCallsCurrentExercice,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CapitalCalls));
