import * as types from '../action_types/accountActionTypes';

const initialState = {
	pages: {},
	nb_pages: 0,
};

function countersReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case types.SET_COUNTERS:
			nextState = {
				...state,
				pages: {
					...state.pages,
				},
			};
			nextState.pages[action.current_page] = action.counters;
			return nextState || state;

		case types.SET_NB_PAGES_COUNTERS:
			nextState = {
				...state,
				nb_pages: action.nb,
			};
			return nextState || state;

		case types.REMOVE_COUNTERS:
			return initialState;

		default:
			return state;
	}
}

export default countersReducer;
