//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import Loading from '../../../components/Loading';
import TableResolutions from '../../../components/table/TableResolutions';
// import FileDownloadedFromAPI from '../../../components/FileDownloadedFromAPI';
// action
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import {
	getRequest,
	postRequest,
	responseManagment,
	responseBlobManagment,
} from '../../../functions/ajax';
import { getActivityParameters } from '../../../functions/coownerships';
import { autoDownload, makeClassName } from '../../../functions';
import FileItem from '../../../components/form/item/FileItem';

class MyCorrespondenceVote extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			send_loading: false,
			dl_loading: false,
			ul_loading: false,
			use_sign_request: false,
			resolutions: [],
			votes: {},
			ag: {},
			file: null,
			submitState: 'send',
		};

		this.responseManagment = responseManagment.bind(this);
		this.responseBlobManagment = responseBlobManagment.bind(this);

		this.goBack = this.goBack.bind(this);
		this.handleVote = this.handleVote.bind(this);
		this.handleChangeFiles = this.handleChangeFiles.bind(this);

		this.handleSubmitState = this.handleSubmitState.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.download = this.download.bind(this);
		this.upload = this.upload.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getResolutions();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getResolutions() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/ag-resolutions?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&ag_id=${this.props.match.params.ag_id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			const { resolutions, ag, use_sign_request } = response;
			this.setState({
				resolutions,
				votes: resolutions.reduce((votes, { id, vote }) => {
					votes['vote-' + id] = vote;
					return votes;
				}, {}),
				use_sign_request,
				ag,
			});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleVote(e) {
		this.setState({
			votes: { ...this.state.votes, [e.target.name]: e.target.value },
		});
	}

	handleChangeFiles(files) {
		if (this.state.file) {
			// There is already a file in this component
			this.props.setLog({
				type: 'error',
				message: "Veuillez retirer une image, avant d'en ajouter une nouvelle.",
			});
		} else {
			this.setState({ file: files[0] }, this.upload);
		}
	}

	goBack(evt) {
		this.props.history.push(`/coownership/${this.props.match.params.id}/judicials/`);
	}

	handleSubmitState(e) {
		if (this.state.submitState !== e.target.value) {
			this.setState({ submitState: e.target.value });
		}
	}

	async handleSubmit(e) {
		e.preventDefault();
		const loader = this.state.submitState + '_loading';
		this.setState({ [loader]: true });
		const response = await postRequest(
			`/ag-resolutions-vote?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&ag_id=${this.props.match.params.ag_id}`
				+ `&use_signrequest=`
				+ (this.state.submitState === 'send' ? 1 : 0),
			{ ...this.state.votes },
			this.props.user.token
		);
		const success = this.responseManagment(response);
		if (success) {
			this.props.setLog({
				type: 'success',
				message: response.message,
			});
			if (this.state.submitState === 'dl') {
				this.download();
			}
		}

		if (this._isMounted && (!success || this.state.submitState !== 'dl')) {
			this.setState({ [loader]: false });
		}
	}

	async download() {
		this.setState({ dl_loading: true });
		const response = await getRequest(
			`/formulaire-vote-correspondance?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&ag_id=${this.props.match.params.ag_id}`,
			this.props.user.token
		);
		if (this.responseBlobManagment(response)) {
			autoDownload(
				response,
				`Formulaire-de-correspondance-pour-l-ag-du-${this.state.ag.date}`
			);
		}
		if (this._isMounted) {
			this.setState({ dl_loading: false });
		}
	}

	async upload() {
		this.setState({ ul_loading: true });
		const response = await postRequest(
			`/deposer-formulaire-vote-correspondance?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&ag_id=${this.props.match.params.ag_id}`,
			{ file: this.state.file },
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setLog({
				type: 'success',
				message: response.message,
			});
			this.goBack();
		}
		if (this._isMounted) {
			this.setState({ ul_loading: false, file: null });
		}
	}

	render() {
		const {
			is_loading,
			resolutions,
			votes,
			send_loading,
			dl_loading,
			ul_loading,
			use_sign_request,
		} = this.state;

		return (
			<div className="grid grid-1 my-correspondence-vote">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Votes par correspondance</h2>
					</div>
					<div className="card-body table-wrapper">
						{is_loading === false ? (
							resolutions.length ? (
								<>
									<TableResolutions
										cols={[
											'',
											'QUESTIONS',
											'MAJORITÉ',
											'CLÉ',
											'VOTE',
											'ANNEXE',
										]}
										table={resolutions}
										votes={votes}
										handleVote={this.handleVote}
									/>
									<p className="mention mt-3">
										Votre vote sera maintenu pour un deuxième tour,
										s'il y en a un.
									</p>
									<form className="form" onSubmit={this.handleSubmit}>
										<div className="bordered mb-4">
											<div className="btn-group" role="group">
												{use_sign_request && (
													<>
														<button
															className={makeClassName(
																'btn btn-orange',
																send_loading && 'loading'
															)}
															onClick={
																this.handleSubmitState
															}
															value="send"
														>
															<img
																src={`/images/pictogrammes/envelope-solid.svg`}
																alt={`section envoi`}
																className="picto"
															/>{' '}
															Recevoir un email pour signer
															le document en ligne
														</button>
														<div className="divider"></div>
													</>
												)}
												<button
													className={makeClassName(
														'btn btn-orange',
														dl_loading && 'loading'
													)}
													onClick={this.handleSubmitState}
													value="dl"
												>
													<img
														src={`/images/pictogrammes/download-solid.svg`}
														alt={`section téléchargement`}
														className="picto"
													/>{' '}
													Télécharger le vote par correspondance
													et le signer manuellement
												</button>
											</div>
										</div>
										<FileItem
											name="vote-form"
											title="Déposer le formulaire de vote par correspondance signé (si vous l'avez téléchargé et non signé en ligne)"
											label_btn="Sélectionner"
											type="pdf"
											nb_max={1}
											max_Mo={10}
											loading={ul_loading}
											callback={this.handleChangeFiles}
											setLog={log => this.props.setLog(log)}
										/>
									</form>
								</>
							) : (
								<p>
									Aucune résolution n'est prête à être votée pour cette
									assemblée générale.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({ coownerships: state.coownerships, user: state.user });
const mapDispatchToProps = { setLog };

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(MyCorrespondenceVote)
);
