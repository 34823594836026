//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import TableAdvances from '../../../components/table/TableAdvances';
import TableReminders from '../../../components/table/TableReminders';
import Loading from '../../../components/Loading';
import CoownerAccountCard from './cards/CoownerAccountCard';
import CardButton from '../../../components/button/CardButton';
//action
import {
	setAdvances,
	setShortReminders,
} from '../../../action_creators/accountActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { getActivityParameters } from '../../../functions/coownerships';

// TODO revoir la sauvegarde des relances par 4
class MyAccount extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			are_loading_advances: false,
			are_loading_reminders: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getAdvances();
		this.getReminders();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getAdvances() {
		if (!this.props.account.advances.length) {
			this.setState({ are_loading_advances: true });
			const response = await getRequest(
				`/comptabilite-avances?`
					+ getActivityParameters(this.props.coownerships.current),
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setAdvances(response.avances);
			}

			if (this._isMounted) {
				this.setState({ are_loading_advances: false });
			}
		}
	}

	async getReminders() {
		if (!this.props.reminders.shortList.length) {
			this.setState({ are_loading_reminders: true });
			const response = await getRequest(
				`/relance?`
					+ getActivityParameters(this.props.coownerships.current)
					+ `&acquitter=${false}`
					+ `&max=4`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setShortReminders(response.relances);
			}

			if (this._isMounted) {
				this.setState({ are_loading_reminders: false });
			}
		}
	}

	render() {
		return (
			<div className="grid grid-7 my-account">
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>Mes avances</h2>
						<em>à la date du jour</em>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_advances === false ? (
							this.props.account.advances.length ? (
								<TableAdvances
									table={this.props.account.advances}
									className="table-advances"
								/>
							) : (
								<p>Vous ne possédez pas d'avances.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>Mes relances</h2>
						<em>impayées</em>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_reminders === false ? (
							this.props.reminders.shortList.length ? (
								<TableReminders
									cols={[
										'DATE',
										'MONTANT',
										'RESTANT',
										'TYPE',
										'DOCUMENTS',
									]}
									table={this.props.reminders.shortList}
									picto_color={this.props.coownerships.current.syndic}
									activity={this.props.coownerships.current}
								/>
							) : null
						) : (
							<Loading />
						)}
						<div className="btn-wrapper">
							<Link
								className="btn"
								to={`/coownership/${this.props.match.params.id}/account/reminders`}
							>
								Voir tout
							</Link>
						</div>
					</div>
				</div>
				<CoownerAccountCard />
				<CardButton
					title="Appels de Fonds"
					btn_label="Accéder aux Appels de Fonds"
					url={`/coownership/${this.props.match.params.id}/account/capital_calls`}
				/>
				<CardButton
					title="Simulation par clé de répartition"
					btn_label="Simuler"
					btn_class="btn-orange"
					url={`/coownership/${this.props.match.params.id}/account/simulation`}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		account: state.account,
		reminders: state.reminders,
	};
};

const mapDispacthToProps = {
	setAdvances,
	setShortReminders,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyAccount));
