import {
	SET_GLOBAL_CHARGES_AMOUNTS,
	SET_CHARGES_TRANSACTIONS,
	SET_CHARGES_EXERCICES,
	SET_CHARGES_CURRENT_EXERCICE,
	VALID_CHARGES_CACHE,
	REMOVE_CHARGES
} from "../action_types/coownershipAmountsActionTypes";

const initialState = {
	global_amounts: [],
	transactions: {},
	exercices: [],
	current_exercice: 0,
	history: {
		global: { exercice: 0 },
		transactions: {
			exercice: 0,
			cache_valid: true
		}
	}
};

function chargesReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case SET_GLOBAL_CHARGES_AMOUNTS:
			nextState = {
				...state,
				global_amounts: action.amounts,
				history: {
					...state.history,
					global: { exercice: state.current_exercice }
				}
			};
			return nextState || state;

		case SET_CHARGES_TRANSACTIONS:
			nextState = {
				...state,
				transactions: action.transactions,
				history: {
					...state.history,
					transactions: {
						...state.history.transactions,
						exercice: state.current_exercice
					}
				}
			};
			return nextState || state;

		case SET_CHARGES_EXERCICES:
			nextState = {
				...state,
				exercices: action.exercices
			};
			return nextState || state;

		case SET_CHARGES_CURRENT_EXERCICE:
			nextState = {
				...state,
				current_exercice: action.exercice
			};
			return nextState || state;

		case VALID_CHARGES_CACHE:
			nextState = {
				...state,
				history: {
					...state.history,
					transactions: {
						...state.history.transactions,
						cache_valid: action.is_valid
					}
				}
			};
			return nextState || state;

		case REMOVE_CHARGES:
			return initialState;

		default:
			return state;
	}
}

export default chargesReducer;
