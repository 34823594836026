import * as types from '../action_types/coownershipsActionTypes';

const initialState = {
	list: {},
	current: {},
};

function coownershipsReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case types.SET_COOWNERSHIPS:
			nextState = {
				...state,
				list: action.coownerships,
			};
			return nextState || state;

		case types.SET_CURRENT_COOWNERSHIP:
			nextState = {
				...state,
				current: action.coownership,
			};
			return nextState || state;

		case types.REMOVE_CURRENT_COOWNERSHIP:
			nextState = {
				...state,
				current: initialState.current,
			};
			return nextState || state;

		case types.REMOVE_COOWNERSHIPS:
			return initialState;

		default:
			return state;
	}
}

export default coownershipsReducer;
