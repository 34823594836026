//module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from 'progressbar.js';

class GraphCircle extends Component {
	componentDidMount() {
		const circleWidth = 30;
		let progress_bar = new ProgressBar.Circle('#budgetGraph', {
			trailColor: this.props.trail_color,
			trailWidth: circleWidth,
			color: this.props.stroke_color,
			strokeWidth: circleWidth,
			duration: 1000,
			easing: 'easeInOut',
			step: function (state, circle) {
				let value = `${Math.round(circle.value() * 100)}%`;
				circle.setText(value);
			},
		});
		// Out of delacration to keep initial text style object values
		progress_bar.text.style.fontSize = '24px';
		progress_bar.text.style.fontWeight = '600';
		progress_bar.animate(this.props.ratio);
	}

	render() {
		return <div id={this.props.id} className="graph graph-circle"></div>;
	}
}

GraphCircle.propTypes = {
	id: PropTypes.string.isRequired,
	ratio: PropTypes.number.isRequired,
	trail_color: PropTypes.string.isRequired,
	stroke_color: PropTypes.string.isRequired,
};

export default GraphCircle;
