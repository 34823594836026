// module
import React, { Component } from 'react';
import { API_PDF_SRC_URL } from '../../constants/api';
import RadioItem from '../form/item/RadioItem';
// components
import { TableBase, colorOddRows } from './Table';

class TableResolutions extends Component {
	render() {
		return (
			<TableBase className="table-resolutions" cols={this.props.cols}>
				<tbody>
					{this.props.table.map((resolution, i) => {
						const radioName = 'vote-' + resolution.id;
						return (
							<tr key={i} className={colorOddRows(i)}>
								<td className="td-align-left">{resolution.order}</td>
								<td className="td-label">
									{resolution.label}
									{!!resolution.parente && (
										<p className="font-size-less">
											Cette résolution dépend{' '}
											{resolution.type === 'resolution_approbation'
												? "de l'approbation"
												: 'du rejet'}{' '}
											de la résolution «&nbsp;
											{resolution.parente}
											&nbsp;»
										</p>
									)}
									{!!resolution.description && (
										<p className="font-size-less">
											{resolution.description}
										</p>
									)}
								</td>
								<td className="td-align-left">{resolution.majority}</td>
								<td className="td-align-left">
									{resolution.cle.label} (Réf&nbsp;:{' '}
									{resolution.cle.reference})
								</td>
								<td className="td-align-left">
									<form>
										<RadioItem
											input={{
												name: radioName,
												value: 'approuve',
												label: 'Approuver',
												required: true,
											}}
											formValue={
												this.props.votes[radioName] || false
											}
											callback={this.props.handleVote}
										/>
										<RadioItem
											input={{
												name: radioName,
												value: 'rejete',
												label: 'Rejeter',
												required: true,
											}}
											formValue={
												this.props.votes[radioName] || false
											}
											callback={this.props.handleVote}
										/>
										<RadioItem
											input={{
												name: radioName,
												value: 'abstention',
												label: "S'abstenir",
												required: true,
											}}
											formValue={
												this.props.votes[radioName] || false
											}
											callback={this.props.handleVote}
										/>
									</form>
								</td>
								<td>
									{resolution.files.length > 0
										&& resolution.files.map((file, i) => (
											<a
												key={i}
												href={API_PDF_SRC_URL + file.url}
												download={`${file.label}.pdf`}
												className="table-picto"
											>
												{file.label}
											</a>
										))}
								</td>
							</tr>
						);
					})}
				</tbody>
			</TableBase>
		);
	}
}

// TableResolutions.propTypes = {...Table.propTypes};

export default TableResolutions;
