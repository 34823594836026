// module
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase, colorEvenRows } from './Table';
import TdAmount from './TdAmount';
// functions
import { accumulate } from '../../functions'

class TableState2D extends Component {
    render() {
        return (
            <TableBase className={this.props.className} cols={this.props.cols}>
                <tbody>
                    {this.props.table.map((key, i) => (
                        <Fragment key={i}>
                            <tr className="subtitle">
                                <td colSpan={this.props.cols.length}>
                                    Clé de répartition{" "}
                                    <span className="general-key">
                                        «&nbsp;{key.label}&nbsp;»
                                    </span>
                                </td>
                            </tr>
                            {key.accounts.map((account, j) => (
                                <tr key={j} className={colorEvenRows(j)}>
                                    <td className="td-label">
                                        {account.number} - {account.label}
                                    </td>
                                    <TdAmount
                                        value={account.cumulCopropriete}
                                        negative={this.props.negative}
                                    />
                                    <TdAmount
                                        value={account.cumulCoproprietaire}
                                        negative={this.props.negative}
                                    />
                                </tr>
                            ))}
                            <tr className="subtotal">
                                <td className="td-align-right">
                                    Total clé de répartition{" "}
                                    <span className="general-key">
                                        «&nbsp;{key.label}&nbsp;»
                                    </span>
                                </td>
                                <TdAmount
                                    value={key.cumulCopropriete}
                                    negative={this.props.negative}
                                />
                                <TdAmount
                                    value={key.cumulCoproprietaire}
                                    negative={this.props.negative}
                                />
                            </tr>
                        </Fragment >
                    ))}
                    <tr className="total">
                        <td className={
                            `td-align-right${this.props.negative  ? ' negative' : ''}`
                        }>
                            Total
                        </td>
                        <TdAmount
                            value={accumulate(this.props.table, "cumulCopropriete")}
                            negative={this.props.negative}
                        />
                        <TdAmount
                            value={accumulate(this.props.table, "cumulCoproprietaire")}
                            negative={this.props.negative}
                        />
                    </tr>
                </tbody>
            </TableBase>
        );
    }
}

TableState2D.propTypes = {
    // ...Table.propTypes,
    negative: PropTypes.bool,
};
TableState2D.defaultProps = {
    negative: false
};

export default TableState2D;