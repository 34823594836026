// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase } from './Table';
// const
import { API_PDF_SRC_URL } from '../../constants/api';
// functions
import {
    setAnimatedCellsHeight,
    toggleAnimatedCells,
    resetAnimatedCells,
} from '../../functions/animatedCells';

class TableIncidents extends Component {
    constructor(props) {
        super(props);
        
        this.handleDeploy = this.handleDeploy.bind(this);
    }

    handleDeploy(evt){
        setAnimatedCellsHeight();
        toggleAnimatedCells(evt.currentTarget.id);
    }

    componentDidUpdate(prevProps) {
        // Re-hide animated cell when page change
        if (prevProps.current_page !== this.props.current_page) {
            resetAnimatedCells();
        }
    }

    render() {
        return (
            <TableBase className="table-incidents" cols={this.props.cols}>
                {this.props.table.map((el, i) => (
                    <tbody key={i} className="table-hidden-rows">
                        <tr
                            id={`tbody-${i}`}
                            className="tr-clickable"
                            onClick={this.handleDeploy}
                        >
                            <td className="date">{el.date}</td>
                            <td>{el.label}</td>
                            <td className="td-label">{el.description}</td>
                            <td>
                                <img
                                    src="/images/pictogrammes/lens-grey.svg"
                                    alt="bouton détails"
                                    className={`table-picto tbody-${i}`}
                                />
                            </td>
                        </tr>
                        {Object.values(el.etapes).map((value, j) => (
                            <tr key={j} className={`hidden hideable tbody-${i}`}>
                                <td>
                                    <div className="animated-cell">{value.date}</div>
                                </td>
                                <td>
                                    <div className="animated-cell">{value.author}</div>
                                </td>
                                <td>
                                    <div className="animated-cell td-label">
                                        {value.description}
                                    </div>
                                </td>
                                <td>
                                    <div className="animated-cell">
                                        {value.files.map((file, k) => (
                                            <a href={API_PDF_SRC_URL + file} key={k}>
                                                <img
                                                    className="table-picto"
                                                    src={`/images/pictogrammes/image-white.svg`}
                                                    alt="Pictogramme pièce jointe"
                                                />
                                                <span className="sr-only">
                                                    Pièce jointe
                                                </span>
                                            </a>
                                        ))}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                ))}
            </TableBase>
        );
    }
}

TableIncidents.propTypes = {
    // ...Table.propTypes,
    current_page: PropTypes.any.isRequired,
};

export default TableIncidents;
