//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import BudgetCard from './cards/BudgetCard';
import ChargesCard from './cards/ChargesCard';
import TenantChargesCard from './cards/TenantChargesCard';
import ExercicesCard from './cards/ExercicesCard';
import Table from '../../../components/table/Table';
import Loading from '../../../components/Loading';
//action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setGlobalWorks,
	setGlobalVisits,
} from '../../../action_creators/coownershipAmountsActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { getActivityParameters } from '../../../functions/coownerships';

class MyCoownership extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading_works: false,
			is_loading_visits: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	async UNSAFE_componentWillMount() {
		if (!this.props.works.globals.length) {
			this.getWorks();
		}
		if (!this.props.visits.globals.length) {
			this.getVisits();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getWorks() {
		this.setState({ is_loading_works: true });
		const response = await getRequest(
			`/travaux-global?`
				+ getActivityParameters(this.props.coownerships.current)
				+ '&max=5',
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setGlobalWorks(response.travaux);
		}

		if (this._isMounted) {
			this.setState({ is_loading_works: false });
		}
	}

	async getVisits() {
		this.setState({ is_loading_visits: true });
		const response = await getRequest(
			`/visites?`
				+ getActivityParameters(this.props.coownerships.current)
				+ '&max=4',
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setGlobalVisits(response.visites);
		}

		if (this._isMounted) {
			this.setState({ is_loading_visits: false });
		}
	}

	render() {
		return (
			<div className="grid grid-6 my-coownership">
				<BudgetCard />
				<ExercicesCard />
				<ChargesCard />
				<TenantChargesCard />
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>Travaux</h2>
						<em>en cours</em>
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading_works === false ? (
							this.props.works.globals.length ? (
								<Fragment>
									<Table
										cols={['LIBELLÉ', 'DÉBUT']}
										keys={['label', 'start']}
										table={this.props.works.globals}
										className="table-works"
									/>
									<div className="btn-wrapper">
										<Link
											className="btn"
											to={`/coownership/${this.props.match.params.id}/coownership/works`}
										>
											Voir tout
										</Link>
									</div>
								</Fragment>
							) : (
								<p>Aucun travail n'est en cours.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>Visites techniques</h2>
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading_visits === false ? (
							this.props.visits.globals.length ? (
								<Fragment>
									<Table
										cols={['LIBELLÉ', 'EFFECTUÉ PAR', 'DATE']}
										keys={['label', 'effectuePar', 'date']}
										table={this.props.visits.globals}
										className="table-visits"
									/>
									<div className="btn-wrapper">
										<Link
											className="btn"
											to={`/coownership/${this.props.match.params.id}/coownership/visits`}
										>
											Voir tout
										</Link>
									</div>
								</Fragment>
							) : (
								<p>Aucune visite technique disponible.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		works: state.works,
		visits: state.visits,
	};
};
const mapDispatchToProps = {
	setLog,
	setGlobalWorks,
	setGlobalVisits,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyCoownership));
