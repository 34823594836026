//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableCoowners from '../../../components/table/TableCoowners';
import PrintButton from '../../../components/button/PrintButton';
import Pagination from '../../../components/Pagination';
import Loading from '../../../components/Loading';
// action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setCoowners,
	removeCoowners,
	setNbPagesCoowners,
} from '../../../action_creators/councilActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';
import { getActivityParameters } from '../../../functions/coownerships';

class Coowners extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			current_page: 1,
			is_loading: false,
		};

		this.redirectToPage = this.redirectToPage.bind(this);
		this.responseManagment = responseManagment.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.checkPageParam();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		// Update coowners when page change
		if (prevProps.match.params.page !== this.props.match.params.page) {
			// Prevent infinite loading from page redirection during or after ajax request
			if (this.state.is_loading === true) {
				this.setState({ is_loading: false });
			}
			this.checkPageParam();
		}
	}

	checkPageParam() {
		let nb_current_page = this.props.match.params.page;

		if (nb_current_page === undefined) {
			// No page mentionned = page 1
			this.getCoowners();
		} else if (isNaN(nb_current_page) || nb_current_page < 1) {
			// Page param is invalid
			this.redirectToPage();
		} else {
			nb_current_page = parseInt(nb_current_page);
			if (nb_current_page !== this.state.current_page) {
				// Set new page in state
				this.setState({ current_page: nb_current_page });
			}
			// Check if there are no coowners on current page
			if (!this.props.council.coowners[nb_current_page]) {
				this.getCoowners(nb_current_page);
			}
		}
	}

	async getCoowners(asked_page = 1) {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/coproprietaires?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&page=${asked_page}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			// Check if asked page match with API pages
			if (response.current_page > asked_page) {
				return this.redirectToPage();
			} else if (response.current_page > 0 && response.current_page < asked_page) {
				return this.redirectToPage(response.current_page);
			}
			if (!this.props.council.nb_pages_coowners) {
				this.props.setNbPagesCoowners(response.nb_pages);
			}
			this.props.setCoowners(response.coproprietaires, response.current_page);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	/**
	 * Redirect the incident page of the given number
	 * @param {number|string} page_number
	 */
	redirectToPage(page_number = 1) {
		this.props.history.push(
			`/coownership/${this.props.match.params.id}/coownership_council/coowners/${page_number}`
		);
	}

	handlePrint(evt) {
		printTable(
			'table-coowners',
			'coproprietaires.pdf',
			'Liste des copropriétaires',
			this.props.coownerships.current
		);
	}

	goBack(evt) {
		this.props.history.push(
			`/coownership/${this.props.match.params.id}/coownership_council/`
		);
	}

	render() {
		const council = this.props.council;
		return (
			<div className="grid grid-1">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Liste copropriétaires</h2>
						<em>à la date du jour</em>
						<PrintButton
							picto_color={this.props.coownerships.current.syndic}
							print={this.handlePrint}
						/>
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading === false ? (
							council.coowners[this.state.current_page]
							&& council.coowners[this.state.current_page].length ? (
								<TableCoowners
									cols={[
										'NOM',
										'SOLDE',
										'COMPTE',
										'ADRESSE',
										'CONSEILLER',
										'LOTS',
									]}
									table={council.coowners[this.state.current_page]}
									url_access={`/coownership/${this.props.match.params.id}/coownership_council/coowner/`}
									picto_color={this.props.coownerships.current.syndic}
								/>
							) : (
								<p>Il n'y aucun copropriétaire.</p>
							)
						) : (
							<Loading />
						)}
					</div>
					{council.nb_pages_coowners > 1 && (
						<Pagination
							page_max={council.nb_pages_coowners}
							callback={this.redirectToPage}
							current_page={this.state.current_page}
						/>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		council: state.council,
	};
};
const mapDispatchToProps = {
	setLog,
	setCoowners,
	setNbPagesCoowners,
	removeCoowners,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Coowners));
