//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import {
	SelectorItem,
	SelectorItemObject,
} from '../../../components/form/item/SelectorItem';
import PrintButton from '../../../components/button/PrintButton';
import TableExerciceState from '../../../components/table/TableExerciceState';
import Loading from '../../../components/Loading';
//action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setExercicesStateTransactions,
	setExercicesStateExercices,
	setExercicesStateCurrentExercice,
	setExercicesStateCommonOperation,
} from '../../../action_creators/coownershipAmountsActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';
// const
import { OPERATIONS, OPERATIONS_LABEL } from '../../../constants/operation';
import { getActivityParameters } from '../../../functions/coownerships';

class ExercicesState extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			hide_details: true,
		};

		this.goBack = this.goBack.bind(this);
		this.responseManagment = responseManagment.bind(this);
		this.toggleHideDetails = this.toggleHideDetails.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.handleCommonOperation = this.handleCommonOperation.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
	}

	UNSAFE_componentWillMount() {
		const exercices_state = this.props.exercices_state;
		const history = exercices_state.history;
		if (
			!exercices_state.exercices.length ||
			// Update transactions if exercice has change in another page
			exercices_state.current_exercice !== history.transactions.exercice ||
			// Update transactions if common operation has change in another page
			exercices_state.common_operation !== history.transactions.common_operation ||
			// Get transactions with exerice setted from another page
			!Object.keys(exercices_state.transactions).length
		) {
			this.getExercicesState();
		}
		// Do nothing if exercice and operation have not change and there are already transactions
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.exercices_state.current_exercice
				!== this.props.exercices_state.current_exercice
			|| prevProps.exercices_state.common_operation
				!== this.props.exercices_state.common_operation
		) {
			this.getExercicesState();
		}
	}

	async getExercicesState() {
		this.setState({ is_loading: true });
		const { coownerships, exercices_state, user } = this.props;
		const { exercices, current_exercice, common_operation } = exercices_state;
		const response = await getRequest(
			`/etat-exercice-detail?`
				+ getActivityParameters(coownerships.current)
				+ (exercices.length
					? `&start=${exercices[current_exercice].start}`
					  + `&end=${exercices[current_exercice].end}`
					: '')
				+ `&current-operation=${OPERATIONS[common_operation]}`,
			user.token
		);
		if (this.responseManagment(response)) {
			if (!exercices.length) {
				this.props.setExercicesStateExercices(response.exercices);
			}
			this.props.setExercicesStateTransactions(response.etatExerciceDetail);
		} else {
			this.props.setExercicesStateTransactions({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	toggleHideDetails(evt) {
		this.setState({ hide_details: !this.state.hide_details });
	}
	handleExercice(evt) {
		this.props.setExercicesStateCurrentExercice(evt.target.value);
	}
	handleCommonOperation(evt) {
		this.props.setExercicesStateCommonOperation(evt.target.value);
	}

	handlePrint(evt) {
		printTable(
			'table-exercice-state',
			'etat-exercice-copropriete.pdf',
			'État exercice',
			this.props.coownerships.current
		);
	}

	goBack(evt) {
		this.props.history.push(
			`/coownership/${this.props.match.params.id}/coownership/`
		);
	}

	render() {
		// For limited line length
		const exercices_state = this.props.exercices_state;
		return (
			<div className="grid grid-1 state exercices">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>État exercice</h2>
						<PrintButton
							picto_color={this.props.coownerships.current.syndic}
							print={this.handlePrint}
						/>
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading === false ? (
							Object.keys(exercices_state.transactions).length ? (
								<Fragment>
									<div className="standfirst">
										<button
											className="btn details-btn"
											onClick={this.toggleHideDetails}
										>
											Voir les détails
										</button>
										<SelectorItem
											id="select-exercices-operation"
											label="Type d'opération"
											current={exercices_state.common_operation}
											options={OPERATIONS_LABEL}
											callback={this.handleCommonOperation}
										/>
										{exercices_state.exercices.length ? (
											<SelectorItemObject
												id="select-exercices-exerice"
												label="Période"
												current={exercices_state.current_exercice}
												options={exercices_state.exercices}
												callback={this.handleExercice}
											/>
										) : null}
									</div>
									<TableExerciceState
										cols={[
											'LIBELLÉ',
											'COPROPRIÉTÉ',
											'TVA GLOBAL',
											'COPROPRIÉTAIRE',
											'TVA COPROPRIÉTAIRE',
											'PIÈCES',
										]}
										hide_details={this.state.hide_details}
										table={exercices_state.transactions}
										activity={this.props.coownerships.current}
									/>
								</Fragment>
							) : (
								<p>
									Il n'y aucun exercice sur la copropriété pour cette
									période.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		exercices_state: state.exercices_state,
	};
};
const mapDispatchToProps = {
	setLog,
	setExercicesStateTransactions,
	setExercicesStateExercices,
	setExercicesStateCurrentExercice,
	setExercicesStateCommonOperation,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExercicesState));
