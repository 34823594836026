// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
// component
import DashBoard from '../coownership/DashBoard';
import MyBank from '../coownership/MyBank';
import MyData from '../coownership/data/MyData';
import MyKeys from '../coownership/data/MyKeys';
import Counters from '../coownership/data/Counters';
import Incidents from '../coownership/Incidents';
import MyAccount from '../coownership/account/MyAccount';
import MyReminders from '../coownership/account/MyReminders';
import MyCoownerAccount from '../coownership/account/MyCoownerAccount';
import CapitalCalls from '../coownership/account/CapitalCalls';
import Simulation from '../coownership/account/Simulation';
import MyCoownership from '../coownership/coownershipAmount/MyCoownership';
import ExercicesState from '../coownership/coownershipAmount/ExercicesState';
// import ProductsState from "../coownership/coownershipAmount/ProductsState";
import ChargesState from '../coownership/coownershipAmount/ChargesState';
import TenantChargesState from '../coownership/coownershipAmount/TenantChargesState';
import Works from '../coownership/coownershipAmount/Works';
import Visits from '../coownership/coownershipAmount/Visits';
import MyJudicials from '../coownership/judicials/MyJudicials';
import MyCorrespondenceVote from '../coownership/judicials/MyCorrespondenceVote';
import LandDeclarations from '../coownership/judicials/LandDeclarations';
import MyFiles from '../coownership/MyFiles';
import CouncilRouter from './CouncilRouter';
import Contact from '../coownership/contact/Contact';
import Help from '../coownership/contact/Help';
import LegalNotice from '../LegalNotice';
import clearCoownershipActions from '../../constants/clearCoownershipActionList';
//actions
import { setCurrentCoownership } from '../../action_creators/coownershipsActionCreators';

class CoownershipRouter extends Component {
	UNSAFE_componentWillMount() {
		// Clear store from other coownerships datas
		Object.keys(clearCoownershipActions).forEach(action => {
			this.props[action]();
		});
		if (Object.keys(this.props.coownerships.list).length) {
			const current_coowner_id = this.props.match.params.id;
			if (current_coowner_id) {
				const current_coownership =
					this.props.coownerships.list[current_coowner_id];
				if (current_coownership) {
					this.props.setCurrentCoownership(current_coownership);
					return;
				}
			}
		}
		this.props.history.push('/');
	}

	render() {
		if (Object.keys(this.props.coownerships.current).length) {
			return (
				<Switch>
					{/* DashBoard */}
					<Route exact path="/coownership/:id/dashboard">
						<DashBoard />
					</Route>
					<Route exact path="/coownership/:id/bank">
						<MyBank />
					</Route>
					{/* Data */}
					<Route exact path="/coownership/:id/data">
						<MyData />
					</Route>
					<Route exact path="/coownership/:id/data/keys">
						<MyKeys />
					</Route>
					<Route exact path="/coownership/:id/data/counters">
						<Counters />
					</Route>
					{/* Incidents */}
					<Route exact path="/coownership/:id/incidents">
						<Incidents />
					</Route>
					<Route exact path="/coownership/:id/incidents/:page">
						<Incidents />
					</Route>
					{/* Account */}
					<Route exact path="/coownership/:id/account">
						<MyAccount />
					</Route>
					<Route exact path="/coownership/:id/account/reminders">
						<MyReminders />
					</Route>
					<Route exact path="/coownership/:id/account/coowner_account">
						<MyCoownerAccount />
					</Route>
					<Route exact path="/coownership/:id/account/capital_calls">
						<CapitalCalls />
					</Route>
					<Route exact path="/coownership/:id/account/simulation">
						<Simulation />
					</Route>
					{/* Coownership */}
					<Route exact path="/coownership/:id/coownership">
						<MyCoownership />
					</Route>
					<Route exact path="/coownership/:id/coownership/exercices">
						<ExercicesState />
					</Route>
					{/* Not keeped
					<Route exact path="/coownership/:id/coownership/products">
						<ProductsState />
					</Route> 
					*/}
					<Route exact path="/coownership/:id/coownership/charges">
						<ChargesState />
					</Route>
					<Route exact path="/coownership/:id/coownership/tenant_charges">
						<TenantChargesState />
					</Route>
					<Route exact path="/coownership/:id/coownership/works">
						<Works />
					</Route>
					<Route exact path="/coownership/:id/coownership/visits">
						<Visits />
					</Route>
					{/* Judicial */}
					<Route exact path="/coownership/:id/judicials">
						<MyJudicials />
					</Route>
					<Route exact path="/coownership/:id/judicials/:ag_id">
						<MyCorrespondenceVote />
					</Route>
					{/* <Route exact path="/coownership/:id/judicials/land_declarations">
						<LandDeclarations />
					</Route> */}
					{/* Files */}
					<Route exact path="/coownership/:id/files">
						<MyFiles />
					</Route>
					{/* Coownership council */}
					<Route path="/coownership/:id/coownership_council">
						<CouncilRouter />
					</Route>
					{/* Contact */}
					<Route exact path="/coownership/:id/contact">
						<Contact />
					</Route>
					<Route exact path="/coownership/:id/help">
						<Help />
					</Route>
					<Route exact path="/coownership/:id/help/legal-notice">
						<LegalNotice />
					</Route>
					{/* Redirection */}
					<Route path="/coownership/:id">
						<Redirect
							to={`/coownership/${this.props.match.params.id}/dashboard`}
						/>
					</Route>
				</Switch>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
	};
};
const mapDispatchToProps = Object.assign(
	{ setCurrentCoownership },
	clearCoownershipActions
);

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CoownershipRouter)
);
