import { green, blue, dark_blue } from '../constants/colors';

export function createBankGraph(response) {
	const series = Object.entries(response.years).map(([key, value], i, array) => {
		let serie = { name: key, data: value };
		if (i === array.length - 1) {
			serie.visible = true;
		} else {
			serie.visible = false;
		}
		// eslint-disable-next-line default-case
		switch (i) {
			case 0:
				serie.color = dark_blue;
				break;
			case 1:
				serie.color = blue;
				break;
			case 2:
				serie.color = green;
				break;
		}
		return serie;
	});
	return { x: response.months, series, solde: response.solde };
}
