// functions
import { getRequest } from './ajax';
// constants
import { STORED_COOWNERSHIPS } from '../constants/localStorage';

/**
 * Get coownerships
 * @param {string} token
 * @return {object}
 * Needs to be bind
 */
export async function getCoownerships(token) {
	const response = await getRequest(`/activities`, token);
	if (this.responseManagment(response)) {
		if (Object.keys(response.activities).length) {
			localStorage.setItem(
				STORED_COOWNERSHIPS,
				JSON.stringify(response.activities)
			);
			this.props.setCoownerships(response.activities);
			return response.activities;
		} else {
			this.props.setLog({
				type: 'error',
				message:
					'Les utilisateurs de mon espace copropriétaire doivent avoir des copropriétés.',
			});
		}
	}
	return [];
}

export function getActivityParameters(activity) {
	return `activity=${activity.id}&personne-num=${activity.coowner.num}`;
}
