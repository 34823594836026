//module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
// functions
import { formatNumbers } from '../../functions';
// constants
import { white, grey } from '../../constants/colors';

class GraphDoubleRows extends Component {
	constructor(props) {
		super(props);

		this.handleLabelPosition = this.handleLabelPosition.bind(this);
	}

	componentDidMount() {
		Highcharts.chart(this.props.id, {
			chart: {
				type: 'bar',
				height: 800,
				width: 1000,
				events: {
					render: this.handleLabelPosition,
				},
			},
			title: {
				text: null,
			},
			plotOptions: {
				bar: {
					borderRadius: 0,
					shadow: false,
					groupPadding: 0.1,
					pointPadding: 0,
					pointWidth: 30,
					minPointLength: 1,
					states: {
						hover: {
							enabled: false,
						},
					},
				},
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						overflow: 'allow',
						color: grey,
						style: {
							fontSize: '15px',
							textOutline: null,
							fontWeight: 400,
							zIndex: 0,
						},
						formatter: function () {
							return `${formatNumbers(this.y)}\u00A0€`;
						},
						useHTML: true,
					},
				},
			},
			legend: { enabled: false },
			series: [
				{
					data: this.props.first_row,
					name: 'Budget dépensé',
					dataLabels: [
						{
							inside: true,
							formatter: function () {
								return `${this.percentage || 0}\u00A0%`;
							},
							align: 'right',
							color: white,
							textOutline: null,
							style: {
								fontSize: '15px',
								textOutline: null,
								fontWeight: 400,
								zIndex: 0,
							},
							useHTML: true,
						},
						{
							inside: false,
							formatter: function () {
								return `${formatNumbers(this.y)}\u00A0€`;
							},
							overflow: 'allow',
							crop: false,
							color: grey,
							style: {
								fontSize: '15px',
								textOutline: null,
								fontWeight: 400,
								zIndex: 0,
							},
							useHTML: true,
						},
					],
				},
				{
					data: this.props.second_row,
					name: 'Budget alloué',
					dataLabels: {
						formatter: function () {
							return `${formatNumbers(this.y)}\u00A0€`;
						},
						overflow: 'allow',
						crop: false,
						color: grey,
						style: {
							fontSize: '15px',
							textOutline: null,
							fontWeight: 400,
						},
						useHTML: true,
					},
				},
			],
			colors: this.props.colors,
			tooltip: {
				// next 3 lines fix zIndex problems
				borderWidth: 0,
				backgroundColor: 'none',
				shadow: false,
				useHTML: true,
				formatter: function () {
					// prettier-ignore
					return (
                        '<ul>'
                        + `<li>${this.series.name}&nbsp;: ${formatNumbers(this.y)}&nbsp;€.</li>`
                        + (this.percentage !== undefined && this.percentage !== null
                            ? `<li>Soit ${this.percentage}% du Budget alloué.</li>`
                            : '')
                        + '</ul>'
                    );
				},
				style: {
					zIndex: 100,
				},
			},
			xAxis: {
				type: 'category',
				categories: this.props.x,
				title: {
					enabled: false,
				},
				labels: {
					style: {
						fontSize: '17px',
					},
				},
			},
			yAxis: {
				lineWidth: 0,
				gridLineWidth: 0,
				lineColor: 'transparent',
				minorTickLength: 0,
				tickLength: 0,
				title: {
					enabled: false,
				},
				labels: {
					enabled: false,
				},
			},
			credits: {
				enabled: false,
			},
		});
	}

	handleLabelPosition(evt) {
		let labels_spent = document.querySelectorAll(
			`#${this.props.id} div.highcharts-data-label-color-0`
		);
		let labels_allocated = document.querySelectorAll(
			`#${this.props.id} div.highcharts-data-label-color-1`
		);
		Object.values(labels_spent).forEach((label, i) => {
			if (label.style.top === '-9999px') {
				if (labels_spent[i - 2]) {
					label.style.top =
						parseInt(labels_spent[i - 2].style.top) + 77.5 + 'px';
				} else if (labels_spent[i + 2]) {
					label.style.top =
						parseInt(labels_spent[i + 2].style.top) - 77.5 + 'px';
				}
				if (parseInt(label.style.left) < 0) {
					let child = label.querySelector('span');
					child.style.color = grey;
					label.style.left = '0px';
					if (labels_spent[i + 1]) {
						labels_spent[i + 1].style.left = '50px'; // 100% benchmark
					}
				}
			} else if (parseInt(label.style.left) > 555) {
				label.style.left = '555px';
				if (labels_spent[i - 1]) {
					labels_spent[i - 1].style.left = '505px';
				}
			}
		});
		Object.values(labels_allocated).forEach((label, i) => {
			if (parseInt(label.style.left) < 50) {
				label.style.left = '50px';
			} else if (parseInt(label.style.left) > 555) {
				label.style.left = '555px';
			}
		});
	}

	render() {
		return <div id={this.props.id} className={`graph graph-double-rows`}></div>;
	}
}

GraphDoubleRows.propTypes = {
	id: PropTypes.string.isRequired,
	colors: PropTypes.arrayOf(PropTypes.string).isRequired,
	x: PropTypes.array.isRequired,
	first_row: PropTypes.array.isRequired,
	second_row: PropTypes.array.isRequired,
};

export default GraphDoubleRows;
