//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import { SelectorItemObject } from '../../../../components/form/item/SelectorItem';
import GraphColumns from '../../../../components/graph/GraphColumns';
import Loading from '../../../../components/Loading';
//action
import { setLog } from '../../../../action_creators/logActionCreators';
import {
	setGlobalTenantChargesAmounts,
	setTenantChargesExercices,
	setTenantChargesCurrentExercice,
} from '../../../../action_creators/coownershipAmountsActionCreators';
// functions
import { getRequest, responseManagment } from '../../../../functions/ajax';
// constants
import { blue, dark_blue } from '../../../../constants/colors';
import { getActivityParameters } from '../../../../functions/coownerships';

class TenantChargesCard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			display_tutorial_bubble: false,
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.toggleBubble = this.toggleBubble.bind(this);
		this.hideBubble = this.hideBubble.bind(this);
	}

	async UNSAFE_componentWillMount() {
		if (!this.props.tenant_charges.exercices.length) {
			this.getGlobalTenantCharges();
		} else if (
			// Update transactions if exerice has change in another page
			this.props.tenant_charges.current_exercice
				!== this.props.tenant_charges.history.global.exercice ||
			// Get transactions with exerice setted from another page
			!Object.keys(this.props.tenant_charges.global_amounts).length
		) {
			this.updateGlobalTenantCharges();
		}
		// Do nothing if exercices have not change and there are already global amounts
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.tenant_charges.current_exercice
			!== this.props.tenant_charges.current_exercice
		) {
			this.updateGlobalTenantCharges();
		}
	}

	async getGlobalTenantCharges() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/etat-charges-locataires-global?`
				+ getActivityParameters(this.props.coownerships.current),
			this.props.user.token
		);
		if (
			this.responseManagment(response) &&
			// for displayed 'Aucun ... n'est disponible...'
			// better here than in render
			!(
				response.totalCoproprietaire === 0
				&& response.totalLocataire === 0
				&& !response.exercices.length
			)
		) {
			this.props.setTenantChargesExercices(response.exercices);
			this.props.setGlobalTenantChargesAmounts({
				coowner_charges: response.totalCoproprietaire || null,
				tenant_charges: response.totalLocataire || null,
			});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateGlobalTenantCharges() {
		this.setState({ is_loading: true });
		const { coownerships, tenant_charges, user } = this.props;
		const { exercices, current_exercice } = tenant_charges;
		const response = await getRequest(
			`/etat-charges-locataires-global?`
				+ getActivityParameters(coownerships.current)
				+ `&start=${exercices[current_exercice].start}`
				+ `&end=${exercices[current_exercice].end}`,
			user.token
		);
		if (this.responseManagment(response)) {
			this.props.setGlobalTenantChargesAmounts({
				coowner_charges: response.totalCoproprietaire || null,
				tenant_charges: response.totalLocataire || null,
			});
		} else {
			this.props.setGlobalTenantChargesAmounts({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleExercice(evt) {
		this.props.setTenantChargesCurrentExercice(evt.target.value);
	}

	toggleBubble(evt) {
		this.setState({
			display_tutorial_bubble: !this.state.display_tutorial_bubble,
		});
	}
	hideBubble() {
		if (this.state.display_tutorial_bubble) {
			this.setState({ display_tutorial_bubble: false });
		}
	}

	render() {
		const tenant_charges = this.props.tenant_charges;
		const amounts = tenant_charges.global_amounts;
		return (
			<div className={`card unscrollable tenant-charges`}>
				<div className="card-header">
					<h2>État des charges locataires</h2>
				</div>
				<div
					className={
						'card-body'
						+ (Object.keys(amounts).length && this.state.is_loading === false
							? ' card-graph'
							: '')
					}
				>
					{this.state.is_loading === false ? (
						Object.keys(amounts).length ? (
							<Fragment>
								<div className="standfirst">
									{tenant_charges.exercices.length ? (
										<SelectorItemObject
											id={`select-card-tenant-charges-exerice`}
											label="Période"
											current={tenant_charges.current_exercice}
											options={tenant_charges.exercices}
											callback={this.handleExercice}
										/>
									) : null}
								</div>
								<GraphColumns
									id="chargeTenantGraph"
									cols="3"
									colors={[blue, dark_blue, blue]}
									datas={[
										[
											'Charges Copropriétaire',
											amounts.coowner_charges,
										],
										['Charges Locataires', amounts.tenant_charges],
										[
											'Charges Nettes Copropriétaire',
											amounts.coowner_charges
												- amounts.tenant_charges || null,
										],
									]}
								/>
								<div
									className={
										'tutorial-bubble'
										+ (this.state.display_tutorial_bubble
											? ''
											: ' hidden')
									}
									onClick={this.toggleBubble}
								>
									<p className="inner-bubble">
										Les{' '}
										<span className="medium">
											"Charges Nettes Copropriétaire"
										</span>{' '}
										correspondent à la somme due par le copropriétaire
										sur les{' '}
										<span className="medium">
											charges copropriétaires
										</span>{' '}
										après le paiement des charges par le{' '}
										<span className="medium">locataire</span>.
									</p>
								</div>
								<div className="btn-wrapper">
									<Link
										className="btn"
										to={`/coownership/${this.props.match.params.id}/coownership/tenant_charges`}
									>
										Accéder
									</Link>
								</div>
							</Fragment>
						) : (
							<p>
								Aucune charge locataire n'est disponible pour cette
								période.
							</p>
						)
					) : (
						<Loading />
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		tenant_charges: state.tenant_charges,
	};
};
const mapDispatchToProps = {
	setLog,
	setGlobalTenantChargesAmounts,
	setTenantChargesExercices,
	setTenantChargesCurrentExercice,
};
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TenantChargesCard)
);
