//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import {
	SelectorItem,
	SelectorItemObject,
} from '../../../components/form/item/SelectorItem';
import PrintButton from '../../../components/button/PrintButton';
import TableState2D from '../../../components/table/TableState2D';
import Loading from '../../../components/Loading';
//action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setChargesTransactions,
	setChargesExercices,
	setChargesCurrentExercice,
	validChargesCache,
} from '../../../action_creators/coownershipAmountsActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';
// const
import { OPERATIONS, OPERATIONS_LABEL } from '../../../constants/operation';
import { getActivityParameters } from '../../../functions/coownerships';

class ChargesState extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			common_operation: 0,
			is_loading: false,
		};

		this.goBack = this.goBack.bind(this);
		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.handleCommonOperation = this.handleCommonOperation.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.charges.exercices.length) {
			this.getChargesState();
		} else if (
			// Update transactions if exerice has change in another component
			this.props.charges.current_exercice
				!== this.props.charges.history.transactions.exercice ||
			// Refresh because operation have been setted lastly
			this.props.charges.history.transactions.cache_valid === false ||
			// Get transactions with exerice setted from another component
			!this.props.charges.transactions.length
		) {
			this.updateChargesState();
			this.props.validChargesCache(true);
		}
		// Do nothing if exercices have not change and there are already transactions
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.charges.current_exercice !== this.props.charges.current_exercice) {
			this.props.validChargesCache(true);
			this.updateChargesState();
		} else if (prevState.common_operation !== this.state.common_operation) {
			this.props.validChargesCache(false);
			this.updateChargesState();
		}
	}

	async getChargesState() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/etat-charges-detail?`
				+ getActivityParameters(this.props.coownerships.current),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setChargesExercices(response.exercices);
			this.props.setChargesTransactions(response.etatChargeDetail);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateChargesState() {
		this.setState({ is_loading: true });
		const { charges, coownerships, user } = this.props;
		const response = await getRequest(
			`/etat-charges-detail?`
				+ getActivityParameters(coownerships.current)
				+ `&start=${charges.exercices[charges.current_exercice].start}`
				+ `&end=${charges.exercices[charges.current_exercice].end}`
				+ `&current-operation=${OPERATIONS[this.state.common_operation]}`,
			user.token
		);
		if (this.responseManagment(response)) {
			this.props.setChargesTransactions(response.etatChargeDetail);
		} else {
			this.props.setChargesTransactions({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleExercice(evt) {
		this.props.setChargesCurrentExercice(evt.target.value);
	}
	handleCommonOperation(evt) {
		this.setState({ common_operation: parseInt(evt.target.value) });
	}
	handlePrint(evt) {
		printTable(
			'table-charges-state',
			'etat-charges-copropriete.pdf',
			'État des charges',
			this.props.coownerships.current
		);
	}

	goBack(evt) {
		this.props.history.push(
			`/coownership/${this.props.match.params.id}/coownership/`
		);
	}

	render() {
		return (
			<div className="grid grid-1 state charges">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>État des charges</h2>
						<PrintButton
							picto_color={this.props.coownerships.current.syndic}
							print={this.handlePrint}
						/>
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading === false ? (
							Object.keys(this.props.charges.transactions).length ? (
								<Fragment>
									<div className="standfirst">
										<SelectorItem
											id="select-charges-type"
											label="Type d'opération"
											current={this.state.common_operation}
											options={OPERATIONS_LABEL}
											callback={this.handleCommonOperation}
										/>
										{this.props.charges.exercices.length ? (
											<SelectorItemObject
												id="select-charges-exerice"
												label="Période"
												current={
													this.props.charges.current_exercice
												}
												options={this.props.charges.exercices}
												callback={this.handleExercice}
											/>
										) : null}
									</div>
									<TableState2D
										cols={[
											'LIBELLÉS',
											'CHARGE COPROPRIÉTÉ',
											'CHARGE COPROPRIÉTAIRE',
										]}
										table={this.props.charges.transactions}
										className={
											'two-dimensions-table table-charges-state'
										}
										negative={true}
									/>
								</Fragment>
							) : (
								<p>
									Il n'y a aucune charge sur la copropriété pour cette
									période.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		charges: state.charges,
	};
};
const mapDispatchToProps = {
	setLog,
	setChargesTransactions,
	setChargesExercices,
	setChargesCurrentExercice,
	validChargesCache,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargesState));
