// Account
export const SET_GLOBAL_ACCOUNT_AMOUNTS = 'SET_GLOBAL_ACCOUNT_AMOUNTS';
export const SET_ACCOUNT_EXERCICES = 'SET_ACCOUNT_EXERCICES';
export const SET_ACCOUNT_CURRENT_EXERCICE = 'SET_ACCOUNT_CURRENT_EXERCICE';
export const SET_ACCOUNT_NUMBER = 'SET_ACCOUNT_NUMBER';
export const SET_ACCOUNT_TRANSACTIONS = 'SET_ACCOUNT_TRANSACTIONS';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
// Advances
export const SET_ADVANCES = 'SET_ADVANCES';
// Reminders
export const SET_REMINDERS = 'SET_REMINDERS';
export const SET_SHORT_REMINDERS = 'SET_SHORT_REMINDERS';
export const SET_REMINDERS_EXERCICES = 'SET_REMINDERS_EXERCICES';
export const SET_REMINDERS_CURRENT_EXERCICE = 'SET_REMINDERS_CURRENT_EXERCICE';
export const REMOVE_REMINDERS = 'REMOVE_REMINDERS';
// Capital Calls
export const SET_CAPITAL_CALLS = 'SET_CAPITAL_CALLS';
export const SET_CAPITAL_CALLS_EXERCICES = 'SET_CAPITAL_CALLS_EXERCICES';
export const SET_CAPITAL_CALLS_CURRENT_EXERCICE = 'SET_CAPITAL_CALLS_CURRENT_EXERCICE';
export const REMOVE_CAPITAL_CALLS = 'REMOVE_CAPITAL_CALLS';
// Land Declaration
export const SET_LAND_DECLARATIONS = 'SET_LAND_DECLARATIONS';
export const REMOVE_LAND_DECLARATIONS = 'REMOVE_LAND_DECLARATIONS';
export const SET_NB_PAGES_LAND_DECLARATIONS = 'SET_NB_PAGES_LAND_DECLARATIONS';
// Counter
export const SET_COUNTERS = 'SET_COUNTERS';
export const REMOVE_COUNTERS = 'REMOVE_COUNTERS';
export const SET_NB_PAGES_COUNTERS = 'SET_NB_PAGES_COUNTERS';
// Debit Mandate
export const SET_DEBIT_MANDATES = 'SET_DEBIT_MANDATES';
export const REMOVE_DEBIT_MANDATES = 'REMOVE_DEBIT_MANDATES';
export const SET_NB_PAGES_DEBIT_MANDATES = 'SET_NB_PAGES_DEBIT_MANDATES';
