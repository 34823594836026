// module
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// component
import Form from './Form';
import DateFieldset from './item/DateFieldset';
// functions
import { formateDate } from '../../functions';
// constants
import { OLDER_API_DATE } from '../../constants/api';

class FormTenantChargesDateFilter extends Form {
	constructor(props) {
		super(props);

		this.initialState = {
			start_date: null,
			end_date: null,
		};
		this.state = {
			...this.initialState,
			older_date: new Date(`${OLDER_API_DATE}-01-01`),
			latest_date: new Date(),
		};
	}

	componentDidMount() {
		this.updateInitialDates();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.exercices !== this.props.exercices) {
			this.updateInitialDates();
		}

		if (prevProps.shouldReset !== this.props.shouldReset) {
			this.resetInputs();
		}
	}

	updateInitialDates() {
		if (this.props.exercices.length) {
			this.setState({
				older_date: new Date(
					// = older date in exercice
					this.props.exercices[this.props.exercices.length - 1].start
				),
				latest_date: new Date(this.props.exercices[0].end),
			});
		}
	}

	checkDates() {
		if (
			!this.checkDate(this.state.start_date, 'début')
			|| !this.checkDate(this.state.end_date, 'fin')
		) {
			return false;
		}

		let start_date = new Date([
			this.state.start_date.year,
			this.state.start_date.month,
			this.state.start_date.day,
		]);
		let end_date = new Date([
			this.state.end_date.year,
			this.state.end_date.month,
			this.state.end_date.day,
		]);

		if (start_date.getTime() > end_date.getTime()) {
			this.props.setLog({
				type: 'error',
				message: `La date de début doit être plus ancienne que la date de fin, ou du même jour.`,
			});
			return false;
		}
		if (start_date.getTime() < this.state.older_date.getTime()) {
			this.props.setLog({
				type: 'error',
				message: `Date trop ancienne, vous ne pouvez visualiser des charges qu'à partir du ${formateDate(
					this.state.older_date
				)}`,
			});
			return false;
		}
		if (end_date.getTime() > this.state.latest_date.getTime()) {
			this.props.setLog({
				type: 'error',
				message: `Date trop récente, vous ne pouvez visualiser des charges que jusqu'au ${formateDate(
					this.state.latest_date
				)}`,
			});
			return false;
		}

		return true;
	}

	checkDate(date, label) {
		if (
			!date
			|| date.day.length !== 2
			|| date.month.length !== 2
			|| date.year.length !== 4
			|| date.day < 1
			|| date.month < 1
			|| date.year < 1
		) {
			this.props.setLog({
				type: 'error',
				message: `Date de ${label} incomplète. Veuillez respecter le format : jj/mm/aaaa.`,
			});
			return false;
		}

		return true;
	}

	handleSubmit(evt) {
		evt.preventDefault();
		if (this.checkDates()) {
			this.props.callback({ ...this.state });
			// this form is not reseted when he is submitted
		}
	}

	buildFormItems() {
		return (
			<Fragment>
				<DateFieldset
					day_id="start_day"
					month_id="start_month"
					year_id="start_year"
					label="Date de début"
					shouldReset={this.props.shouldReset}
					hasReset={() => this.props.hasReset()}
					callback={date => this.setState({ start_date: date })}
				/>
				<DateFieldset
					day_id="end_day"
					month_id="end_month"
					year_id="end_year"
					label="Date de fin"
					shouldReset={this.props.shouldReset}
					hasReset={() => this.props.hasReset()}
					callback={date => this.setState({ end_date: date })}
				/>
			</Fragment>
		);
	}
}

FormTenantChargesDateFilter.propTypes = {
	exercices: PropTypes.arrayOf(PropTypes.object).isRequired,
	callback: PropTypes.func.isRequired,
	setLog: PropTypes.func.isRequired,
	shouldReset: PropTypes.bool,
	hasReset: PropTypes.func,
};

export default FormTenantChargesDateFilter;
