// module
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class LegalNotice extends Component {
	goBack(evt) {
		if (this.props.history.length < 3) {
			this.props.history.push('/');
		} else {
			this.props.history.goBack();
		}
	}

	render() {
		return (
			<div className="grid grid-1 legal-notice">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={evt => this.goBack(evt)}>
							Revenir en arrière
						</button>
						<h2>Mentions Légales Espace Copropriétaire</h2>
					</div>
					<div className="card-body">
						<section>
							<h3>
								Mon Espace Copropriétaire est un espace appartenant et
								exploité par la société MY
							</h3>
							<h4>Informations légales</h4>
							<p>
								MY SA 11 rue blanche 75009 – PARIS <br />
								SA au capital de 26 000 € <br />
								SIREN : 887 497 683 R.C.S. PARIS <br />
								TVA Intracommunautaire : FR7887497683 <br />
								Directeur de la publication : M. DE DECKER <br />
								<br />
								Conformément à la loi n°2004-575 du 21 juin 2004 pour la
								confiance dans l’économie numérique, nous vous informons
								que le site internet est la propriété de MY. Le site
								internet est hébergé sur les matériels informatiques de la
								société 1&1 dont le siège social est : 7 place de la Gare
								BP 70109 57201 - SARREGUEMINES. <br />
								<br />
								Le site est régi par le droit français ; les visiteurs
								ayant accès au site de l’étranger, doivent s'assurer du
								respect des lois localement applicables. Les mentions
								légales pouvant être modifiées à tout moment et sans
								préavis, nous vous engageons à les consulter
								régulièrement.
							</p>
							<h4>Propriété intellectuelle</h4>
							<p>
								MY détient, se réserve et conserve tous les droits de
								propriété, notamment intellectuelle, y compris les droits
								de reproduction sur le présent site et les éléments qu’il
								contient. En conséquence et notamment toute reproduction
								partielle ou totale du présent site et des éléments qu’il
								contient est strictement interdite sans autorisation
								écrite de MY. <br />
								Les logos et tous autres signes distinctifs contenus sur
								ce site sont la propriété de MY ou font l'objet d'une
								autorisation d'utilisation. Aucun droit ou licence ne
								saurait être attribué sur l'un quelconque de ces éléments
								sans l'autorisation écrite de MY ou du tiers détenteur des
								droits.
							</p>
							<h4>Exclusion de garantie et de responsabilité </h4>
							<p>
								L'utilisateur du site web
								“www.monespacecoproprietaire.com” reconnaît avoir pris
								connaissance des présentes conditions d'utilisation du
								site et s'engage à les respecter. MY et son espace « Mon
								espace Propriétaire » ne sauraient être tenues pour
								responsables des dommages directs ou indirects qui
								pourraient résulter de l'accès au site ou de l'utilisation
								du site et/ou de ces informations, y compris
								l'inaccessibilité, les pertes de données, détériorations,
								destructions ou virus qui pourraient affecter l'équipement
								informatique de l'utilisateur et/ou de la présence de
								virus sur son site. Bien que MY s'efforce de fournir un
								contenu fiable sur son site, il ne garantit pas que son
								contenu soit exempt d'inexactitudes ou d'omissions et ne
								saurait être tenu pour responsable des erreurs ou
								omissions, d'une absence de disponibilité des informations
								et des services. MY se réserve à tout moment et sans
								préavis le droit d'apporter des améliorations et/ou des
								modifications au contenu de son site. En conséquence,
								l'utilisateur reconnaît se servir de ses informations sous
								sa responsabilité exclusive.
							</p>
							<h4>Liens vers d'autres sites</h4>
							<p>
								Le site peut inclure des liens vers d'autres sites ou
								d'autres sources Internet. Dans la mesure où MY ne peut
								contrôler ces sites et ces sources externes, MY ne peut
								être tenu pour responsable de la mise à disposition de ces
								sites et sources externes, et décline ainsi toute
								responsabilité quant aux contenus, publicités, produits,
								services ou tout autre matériel disponible sur ou à partir
								de ces sites ou sources externes. Ces liens sont proposés
								aux utilisateurs du site en tant que service. La décision
								d'activer les liens vous appartient exclusivement. Nous
								vous rappelons que les sites non-affiliés sont soumis à
								leurs propres conditions d'utilisation.
							</p>
						</section>
					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(LegalNotice);
