//module
import React from 'react';
import PropTypes from 'prop-types';

export const SelectorItemBase = props => (
	<div className="item-selector">
		<label htmlFor={props.id}>
			{props.label}
			{props.required ? '\u00A0*' : '\u00A0:'}
		</label>
		<select
			id={props.id}
			name={props.id}
			value={props.current}
			onChange={value => props.callback(value)}
		>
			{props.children}
		</select>
	</div>
);

export const SelectorItem = ({ options, ...props }) => (
	<SelectorItemBase {...props}>
		{options.map((option, i) => (
			<option key={i} value={i}>
				{option}
			</option>
		))}
	</SelectorItemBase>
);

export const SelectorItemObject = ({ options, ...props }) => (
	<SelectorItemBase {...props}>
		{options.map((option, i) => (
			<option key={i} value={i}>
				{option.label}
			</option>
		))}
	</SelectorItemBase>
);

SelectorItemBase.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool,
	current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	callback: PropTypes.func.isRequired,
};

SelectorItem.propTypes = {
	...SelectorItemBase.propTypes,
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SelectorItemObject.propTypes = {
	...SelectorItemBase.propTypes,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
};
