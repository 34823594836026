//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import PrintButton from '../../../components/button/PrintButton';
import FormTenantChargesDateFilter from '../../../components/form/FormTenantChargesDateFilter';
import TableTenantCharges from '../../../components/table/TableTenantCharges';
import Loading from '../../../components/Loading';
//action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setTenantChargesTransactions,
	setTenantChargesExercices,
	setTenantChargesCurrentExercice,
	setTenantChargesLots,
	validTenantChargesCache,
} from '../../../action_creators/coownershipAmountsActionCreators';
// functions
import { getRequest, postRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';
import DropdownMultiSelect from '../../../components/form/item/DropdownMultiSelect';
import { getActivityParameters } from '../../../functions/coownerships';

class TenantChargesState extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			checked_lots: {},
			current_start_date: null,
			current_end_date: null,
			use_custom_date: false,
			reset_date: false,
		};

		this.goBack = this.goBack.bind(this);
		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.handleDate = this.handleDate.bind(this);
		this.handleCheckedLots = this.handleCheckedLots.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.setOwnPrintFilters = this.setOwnPrintFilters.bind(this);
	}

	UNSAFE_componentWillMount() {
		const tenant_charges = this.props.tenant_charges;
		const history = tenant_charges.history;
		if (!tenant_charges.exercices.length) {
			// Get transactions without using dates
			this.getTenantChargesState();
		} else if (
			// Exercice has changed in another component
			tenant_charges.current_exercice !== history.transactions.exercice ||
			// Refresh because lot or custom date have been setted lastly
			history.transactions.cache_valid === false ||
			// there is exercice but no transactions so it load
			!tenant_charges.transactions.length
		) {
			// Update transactions if exerice has change in another component
			// Or if we get an exercice from another component
			this.updateTenantChargesStateWithExercice();
			this.props.validTenantChargesCache(true);
		}
		// Do nothing if exercice has not change and there are already transactions
	}

	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		const tenant_charges = this.props.tenant_charges;
		// Filter change exercice
		if (
			prevProps.tenant_charges.current_exercice !== tenant_charges.current_exercice
			|| prevProps.tenant_charges.exercices.length
				!== tenant_charges.exercices.length
		) {
			this.props.validTenantChargesCache(true);
			// Reset Custom Date
			this.setState({
				current_start_date: null,
				current_end_date: null,
				// to choose the right way when lot change
				use_custom_date: false,
				// to reset form values
				reset_date: true,
				// Reset lots when date change because lots change across time
				checked_lots: {},
			});
			this.updateTenantChargesStateWithExercice();
		}

		// Filter set custom date
		if (
			this.state.current_start_date
			&& this.state.current_end_date
			&& (prevState.current_start_date !== this.state.current_start_date
				|| prevState.current_end_date !== this.state.current_end_date)
		) {
			this.props.validTenantChargesCache(false);
			this.updateTenantChargesStateWithCustomDate();
		} /* Filter change lot */ else if (
			prevState.checked_lots !== this.state.checked_lots
		) {
			this.props.validTenantChargesCache(false);
			if (this.state.use_custom_date === true) {
				this.updateTenantChargesStateWithCustomDate();
			} else {
				this.updateTenantChargesStateWithExercice();
			}
		}
	}

	// First time get exercices to set current_date
	async getTenantChargesState() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/etat-charges-locataires-detail?`
				+ getActivityParameters(this.props.coownerships.current),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setTenantChargesExercices(response.exercices);
			this.props.setTenantChargesTransactions(response.clesReturn);
			this.props.setTenantChargesLots(response.lots);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	updateTenantChargesStateWithExercice() {
		const current_exercice =
			this.props.tenant_charges.exercices[
				this.props.tenant_charges.current_exercice
			];
		this.updateTenantChargesState(current_exercice.start, current_exercice.end);
	}

	updateTenantChargesStateWithCustomDate() {
		this.updateTenantChargesState(
			this.state.current_start_date,
			this.state.current_end_date
		);
	}

	async updateTenantChargesState(start_date, end_date) {
		this.setState({ is_loading: true });
		let response;
		if (Object.values(this.state.checked_lots).length) {
			console.log();
			response = await postRequest(
				`/etat-charges-locataires-detail?`
					+ getActivityParameters(this.props.coownerships.current)
					+ `&start=${start_date}`
					+ `&end=${end_date}`,
				{ lots: Object.values(this.state.checked_lots).join(',') },
				this.props.user.token
			);
		} else {
			response = await getRequest(
				`/etat-charges-locataires-detail?`
					+ getActivityParameters(this.props.coownerships.current)
					+ `&start=${start_date}`
					+ `&end=${end_date}`,
				this.props.user.token
			);
		}
		if (this.responseManagment(response)) {
			this.props.setTenantChargesTransactions(response.clesReturn);
			this.props.setTenantChargesLots(response.lots);
		} else {
			this.props.setTenantChargesTransactions({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleDate(dates) {
		this.setState({
			current_start_date: `${dates.start_date.year}-${dates.start_date.month}-${dates.start_date.day}`,
			current_end_date: `${dates.end_date.year}-${dates.end_date.month}-${dates.end_date.day}`,
			use_custom_date: true,
			// Reset lots when date change because lots change across time
			checked_lots: {},
		});
		this.props.validTenantChargesCache(false);
	}

	handleCheckedLots(checked_lots) {
		this.setState({ checked_lots });
	}

	handleExercice(evt) {
		this.props.setTenantChargesCurrentExercice(evt.target.value);
	}
	handlePrint(evt) {
		printTable(
			'table-tenant-charges-state',
			'etat-charges-locataires-copropriete.pdf',
			'État des charges locataires',
			this.props.coownerships.current,
			this.setOwnPrintFilters
		);
	}

	setOwnPrintFilters() {
		const { tenant_charges } = this.props;
		const lots = tenant_charges.lots.reduce((str, lot, i) => {
			if (this.state.checked_lots[i]) {
				str = (str ? str + ', ' : str) + lot.label;
			}
			return str;
		}, '');
		
		let start_date, end_date;
		if (this.state.use_custom_date) {
			start_date = new Date(this.state.current_start_date).toLocaleDateString();
			end_date = new Date(this.state.current_end_date).toLocaleDateString();
		} else {
			const current_exercice =
				tenant_charges.exercices[tenant_charges.current_exercice];
			start_date = new Date(current_exercice.start).toLocaleDateString();
			end_date = new Date(current_exercice.end).toLocaleDateString();
		}
		return (
			`Période : ${start_date} au ${end_date}` + (lots ? ` - Lots : ${lots}` : '')
		);
	}

	goBack(evt) {
		this.props.history.push(
			`/coownership/${this.props.match.params.id}/coownership/`
		);
	}

	render() {
		const { tenant_charges } = this.props;
		return (
			<div className="grid grid-1 state tenant-charges">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>État des charges locataires</h2>
						<PrintButton
							picto_color={this.props.coownerships.current.syndic}
							print={this.handlePrint}
						/>
					</div>
					<div className="card-body table-wrapper">
						<div className="standfirst">
							<div className="dates-wrapper">
								{tenant_charges.exercices.length ? (
									<Fragment>
										<SelectorItemObject
											id="select-tenant-charges-exerice"
											label="Période"
											current={tenant_charges.current_exercice}
											options={tenant_charges.exercices}
											callback={this.handleExercice}
										/>
										<div className="divider">--&nbsp;OU&nbsp;--</div>
									</Fragment>
								) : null}
								<FormTenantChargesDateFilter
									exercices={tenant_charges.exercices}
									setLog={log => this.props.setLog(log)}
									callback={this.handleDate}
									shouldReset={this.state.reset_date}
									hasReset={() => this.setState({ reset_date: false })}
								/>
							</div>
							{tenant_charges.lots.length ? (
								<DropdownMultiSelect
									id="select-tenant-charges-lots"
									label="Lots"
									// values={this.state.checked_lots}
									options={tenant_charges.lots}
									callback={this.handleCheckedLots}
								/>
							) : null}
						</div>
						{this.state.is_loading === false ? (
							Object.keys(tenant_charges.transactions).length ? (
								<TableTenantCharges
									cols={[
										'LIBELLÉ',
										'CHARGES COPROPRIÉTAIRE',
										'TVA COPROPRIÉTAIRE',
										'CHARGES LOCATAIRES',
										'TVA LOCATAIRES',
									]}
									table={tenant_charges.transactions}
								/>
							) : (
								<p>
									Il n'y aucune charge locataire sur la copropriété pour
									cette période.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		tenant_charges: state.tenant_charges,
	};
};
const mapDispatchToProps = {
	setLog,
	setTenantChargesTransactions,
	setTenantChargesExercices,
	setTenantChargesCurrentExercice,
	setTenantChargesLots,
	validTenantChargesCache,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TenantChargesState)
);
