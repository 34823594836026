import {
	SET_GLOBAL_WORKS,
	SET_WORKS,
	SET_WORK_STEPS,
	SET_WORKS_EXERCICES,
	SET_WORKS_CURRENT_EXERCICE,
    REMOVE_WORKS
} from "../action_types/coownershipAmountsActionTypes";

const initialState = {
    globals: [],
    list: {
        true: {},
        false: {}
    },
    exercices: [],
    current_exercice: 0,
}

function worksReducer(state = initialState, action) {
    let nextState;

    switch (action.type) {

        case SET_GLOBAL_WORKS:
            nextState = {
                ...state,
                globals: action.works
            };
            return nextState || state;

        case SET_WORKS:
            nextState = {
                ...state,
                // Necessary to copy all object impacted
                list: {
                    ...state.list
                }
            };
            nextState.list[action.in_progress] = action.works;
            return nextState || state;

        case SET_WORK_STEPS:
            nextState = {
                ...state,
                list: {
                    ...state.list,
                }
            };
            // Necessary to copy all object impacted
            nextState.list[action.in_progress] = {
                ...nextState.list[action.in_progress]
            }
            nextState.list[action.in_progress][action.id] = {
                ...nextState.list[action.in_progress][action.id],
                steps: action.steps
            }
            return nextState || state;

        case SET_WORKS_EXERCICES:
            nextState = {
                ...state,
                exercices: action.exercices
            };
            return nextState || state;

        case SET_WORKS_CURRENT_EXERCICE:
            nextState = {
                ...state,
                current_exercice: action.exercice
            };
            return nextState || state;

        case REMOVE_WORKS:
            return initialState;

        default:
            return state;
    }
}

export default worksReducer;