//actions
import {
	removeAccount,
	removeCapitalCalls,
	removeReminders,
	removeCounters,
	removeLandDeclarations,
} from '../action_creators/accountActionCreators';
import { removeBank } from '../action_creators/banksActionCreators';
import {
	removeBudget,
	removeExercicesState,
	removeProducts,
	removeCharges,
	removeTenantCharges,
	removeWorks,
	removeVisits,
} from '../action_creators/coownershipAmountsActionCreators';
import {
	removeCouncil,
	removeBalance,
	removeGreatBook,
} from '../action_creators/councilActionCreators';
import { removeDocs } from '../action_creators/docsActionCreators';
import { removeIncidentsAndFilters } from '../action_creators/incidentsActionCreators';
import { removeKeys } from '../action_creators/keysActionCreators';
import { removeLots } from '../action_creators/lotsActionCreators';
import { removeNotifications } from '../action_creators/notificationsActionCreators';
import { removeSyndic } from '../action_creators/syndicActionCreators';

export default {
	removeAccount,
	removeReminders,
	removeCapitalCalls,
	removeBank,
	removeBudget,
	removeExercicesState,
	removeProducts,
	removeCharges,
	removeTenantCharges,
	removeWorks,
	removeCouncil,
	removeBalance,
	removeGreatBook,
	removeDocs,
	removeIncidentsAndFilters,
	removeKeys,
	removeLots,
	removeNotifications,
	removeSyndic,
	removeLandDeclarations,
	removeCounters,
	removeVisits,
};
