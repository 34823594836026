// module
import React, { Component, Fragment } from 'react';
// components
import { TableBase, colorOddRows } from './Table';
import TdAmount from './TdAmount';
// functions
import { accumulate } from '../../functions';

const TdAmountsGroup = props => (
	<Fragment>
		<TdAmount value={props.value.coproprietaire.amount} negative={true} />
		<TdAmount value={props.value.coproprietaire.tva} negative={true} />
		<TdAmount value={props.value.locataire.amount} negative={true} />
		<TdAmount value={props.value.locataire.tva} negative={true} />
	</Fragment>
);

class TableTenantCharges extends Component {
	render() {
		return (
			<TableBase className="table-tenant-charges-state" cols={this.props.cols}>
			<tbody>
				{this.props.table.map((key, i) => (
					<Fragment key={i}>
						<tr className="subtitle">
							<td colSpan={this.props.cols.length}>
								Clé de répartition 
                                {" "}«&nbsp;{key.label}&nbsp;»
							</td>
						</tr>
						{key.accounts.map((account, j) => (
							<Fragment key={j}>
								<tr className="subsubtotal">
									<td className="td-label">
										{account.number} -{account.label}
									</td>
									<TdAmountsGroup value={account} />
								</tr>
								{account.ecritures.map((ecriture, k) => (
									<tr key={k} className={colorOddRows(k)}>
										<td className="td-label">
                                            {ecriture.libelle}
                                        </td>
										<TdAmountsGroup value={ecriture} />
									</tr>
								))}
							</Fragment>
						))}
						<tr className="subtotal">
							<td className="td-align-right">{key.label}</td>
							<TdAmountsGroup value={key} />
						</tr>
					</Fragment>
				))}
					<tr className="total">
						<td className="td-align-right">Total</td>
						<TdAmount
							value={accumulate(
								this.props.table,
								'coproprietaire',
								'amount'
							)}
							negative={true}
						/>
                        <TdAmount 
							value={accumulate(
								this.props.table,
								'coproprietaire',
								'tva'
                            )}
                            negative={true} 
                        />
						<TdAmount
							value={accumulate(
								this.props.table,
								'locataire',
								'amount'
							)}
							negative={true}
						/>
                        <TdAmount 
							value={accumulate(
								this.props.table,
								'locataire',
								'tva'
                            )}
                            negative={true} 
                        />
					</tr>
				</tbody>
			</TableBase>
		);
	}
}

export default TableTenantCharges;
