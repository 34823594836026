// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import TableVisits from '../../../components/table/TableVisits';
import Loading from '../../../components/Loading';
// action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setVisits,
	setVisitsExercices,
	setVisitsCurrentExercice,
} from '../../../action_creators/coownershipAmountsActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { getActivityParameters } from '../../../functions/coownerships';

class Visits extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = { is_loading: false };

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.visits.list.length) {
			this.getVisits();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// exercice change
		if (prevProps.visits.current_exercice !== this.props.visits.current_exercice) {
			this.updateVisits();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getVisits() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/visites?` + getActivityParameters(this.props.coownerships.current),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setVisitsExercices(response.exercices);
			this.props.setVisits(response.visites);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateVisits() {
		this.setState({ is_loading: true });
		const current_exercice =
			this.props.visits.exercices[this.props.visits.current_exercice];
		const response = await getRequest(
			`/visites?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&start=${current_exercice.start}`
				+ `&end=${current_exercice.end}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setVisits(response.visites);
		} else {
			this.props.setVisits({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	goBack(evt) {
		this.props.history.push(
			`/coownership/${this.props.match.params.id}/coownership/`
		);
	}

	handleExercice(evt) {
		this.props.setVisitsCurrentExercice(evt.target.value);
	}

	render() {
		return (
			<div className="grid grid-1">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Visites techniques</h2>
					</div>
					<div className="card-body table-wrapper">
						<div className="standfirst">
							{this.props.visits.exercices.length ? (
								<SelectorItemObject
									id="select-visits-exerice"
									label="Période"
									current={this.props.visits.current_exercice}
									options={this.props.visits.exercices}
									callback={this.handleExercice}
								/>
							) : null}
						</div>
						{this.state.is_loading === false ? (
							this.props.visits.list.length ? (
								<TableVisits
									cols={[
										'LIBELLÉ',
										'EFFECTUÉ PAR',
										'DATE',
										'DESCIPTION',
										'OPTIONS / PDF',
									]}
									table={this.props.visits.list}
									picto_color={this.props.coownerships.current.syndic}
									activity={this.props.coownerships.current}
								/>
							) : (
								<p>
									Il n'y a pas de visites techniques sur cette
									copropriété.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	coownerships: state.coownerships,
	user: state.user,
	visits: state.visits,
});

const mapDispatchToProps = {
	setLog,
	setVisits,
	setVisitsExercices,
	setVisitsCurrentExercice,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Visits));
