// module
import React from 'react';
import PropTypes from 'prop-types';

function CheckboxItem(props) {
	const { input, checked, callback } = props;
	const { name, required, label } = input;

	return (
		<div className="form-item form-group form-group-check-box">
			<input
				className="form-item-check-box"
				type="checkbox"
				id={name}
				name={name}
				value={true}
				checked={checked}
				required={required}
				onChange={callback ? callback : undefined}
			/>
			<label
				htmlFor={name}
				className={'form-item-check-box-label' + (checked ? ' checked' : '')}
				dangerouslySetInnerHTML={{ __html: label }}
			/>
		</div>
	);
}

CheckboxItem.propTypes = {
	input: PropTypes.object.isRequired,
	checked: PropTypes.bool,
	callback: PropTypes.func,
};

export default CheckboxItem;
