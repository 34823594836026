//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableGreatBook from '../../../components/table/TableGreatBook';
import PrintButton from '../../../components/button/PrintButton';
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import Loading from '../../../components/Loading';
// action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setGreatBookTransactions,
	setGreatBookExercices,
	setGreatBookCurrentExercice,
} from '../../../action_creators/councilActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';
import { getActivityParameters } from '../../../functions/coownerships';

class GreatBook extends Component {
	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!this.props.great_book.transactions.length) {
			this.getGreatBook();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.great_book.current_exercice
			!== this.props.great_book.current_exercice
		) {
			this.updateGreatBook();
		}
	}

	async getGreatBook() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/grand-livre?` + getActivityParameters(this.props.coownerships.current),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setGreatBookExercices(response.exercices);
			this.props.setGreatBookTransactions(response.gl);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateGreatBook() {
		this.setState({ is_loading: true });
		const { coownerships, great_book, user } = this.props;
		const response = await getRequest(
			`/grand-livre?`
				+ getActivityParameters(coownerships.current)
				+ `&start=${great_book.exercices[great_book.current_exercice].start}`
				+ `&end=${great_book.exercices[great_book.current_exercice].end}`,
			user.token
		);
		if (this.responseManagment(response)) {
			this.props.setGreatBookTransactions(response.gl);
		} else {
			this.props.setGreatBookTransactions({});
		}
		this.setState({ is_loading: false });
	}

	handleExercice(evt) {
		this.props.setGreatBookCurrentExercice(evt.target.value);
	}
	handlePrint(evt) {
		printTable(
			'table-great-book',
			'grand-livre-copropriete.pdf',
			'Grand livre',
			this.props.coownerships.current
		);
	}

	goBack(evt) {
		this.props.history.push(
			`/coownership/${this.props.match.params.id}/coownership_council/`
		);
	}

	render() {
		return (
			<div className="grid grid-1">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Grand livre</h2>
						<PrintButton
							picto_color={this.props.coownerships.current.syndic}
							print={this.handlePrint}
						/>
					</div>
					<div className="card-body table-wrapper">
						<div className="standfirst">
							{this.props.great_book.exercices.length ? (
								<SelectorItemObject
									id="select-great_book-exerice"
									label="Période"
									current={this.props.great_book.current_exercice}
									options={this.props.great_book.exercices}
									callback={this.handleExercice}
								/>
							) : null}
						</div>
						{this.state.is_loading === false ? (
							this.props.great_book.transactions.length ? (
								<TableGreatBook
									cols={[
										'LIBELLÉ',
										'DATE',
										'DÉBIT',
										'CRÉDIT',
										'JOURNAL',
										'ASSOCIATION',
									]}
									table={this.props.great_book.transactions}
								/>
							) : (
								<p>Il n'y a pas d'écriture à cette période.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		great_book: state.great_book,
	};
};
const mapDispatchToProps = {
	setLog,
	setGreatBookTransactions,
	setGreatBookExercices,
	setGreatBookCurrentExercice,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GreatBook));
