// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase, colorOddRows } from './Table';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';
import { Link } from 'react-router-dom';
import { getActivityParameters } from '../../functions/coownerships';
import { chooseColor } from '../../functions';
import { API_PDF_SRC_URL } from '../../constants/api';

class TableJudicialDocs extends Component {
	render() {
		return (
			<TableBase className="table-judicial-docs" cols={this.props.cols}>
				<tbody>
					{this.props.table.map((doc, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td className="date">{doc.date}</td>
							<td>
								<FileDownloadedFromAPI
									uri={
										`/juridique-pdf?`
										+ getActivityParameters(this.props.activity)
										+ `&type=${doc.type}`
										+ `&id=${doc.id}`
									}
									filename={`${doc.label}-${doc.date}`}
								>
									<span className="doc-anchor">{doc.label}</span>
								</FileDownloadedFromAPI>
							</td>
							<td>
								{!!doc.pdf && (
									<a
										href={API_PDF_SRC_URL + doc.pdf}
										title="Télécharger le formulaire de vote par correspondance"
									>
										<img
											className="table-picto mr-3"
											src={`/images/pictogrammes/pdf-${chooseColor(
												this.props.picto_color
											)}.svg`}
											alt="fichier"
										/>
									</a>
								)}
								{doc.preparation ? (
									<Link
										to={`/coownership/${this.props.coownership_id}/judicials/${doc.id}`}
										className="btn"
									>
										Voter par correspondance
									</Link>
								) : doc.expiration ? (
									<span className="text-red">
										Formulaire de vote par correspondance expiré
										depuis le {doc.expiration}
									</span>
								) : null}
							</td>
						</tr>
					))}
				</tbody>
			</TableBase>
		);
	}
}

TableJudicialDocs.propTypes = {
	// ...Table.propTypes,
	activity: PropTypes.object.isRequired,
};

export default TableJudicialDocs;
