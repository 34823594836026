//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// components
import Loading from './Loading';
// action
import { setLog } from '../action_creators/logActionCreators';
// functions
import { getRequest, responseBlobManagment } from '../functions/ajax';
import { autoDownload } from '../functions';

class FileDownloadedFromAPI extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseBlobManagment = responseBlobManagment.bind(this);
		this.getFile = this.getFile.bind(this);
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getFile() {
		this.setState({ is_loading: true });
		const response = await getRequest(this.props.uri, this.props.user.token);
		if (this.responseBlobManagment(response)) {
			autoDownload(response, this.props.filename);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	render() {
		return (
			<Fragment>
				{this.state.is_loading === false ? (
					<div
						className={this.props.className}
						onClick={this.getFile}
						ref={this.props.clickRef}
					>
						{this.props.children}
					</div>
				) : (
					<Loading />
				)}
			</Fragment>
		);
	}
}

FileDownloadedFromAPI.propTypes = {
	uri: PropTypes.string.isRequired,
	filename: PropTypes.string.isRequired,
	clickRef: PropTypes.object,
};

const mapStateToProps = state => ({ user: state.user });

export default withRouter(connect(mapStateToProps, { setLog })(FileDownloadedFromAPI));
