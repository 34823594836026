// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase, colorOddRows } from './Table';
import TdAmount from './TdAmount';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';
// functions
import { chooseColor, accumulate } from '../../functions';
import { getActivityParameters } from '../../functions/coownerships';

class TableCapitalCalls extends Component {
	render() {
		return (
			<TableBase className="table-capital-calls" cols={this.props.cols}>
				<tbody>
					{this.props.table.map((el, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td className="td-label">{el.label}</td>
							<td className="date">{el.date}</td>
							<TdAmount value={el.amount} />
							{/* <TdAmount value={el.remaining_amount} negative={true} /> */}
							<td>
								<FileDownloadedFromAPI
									uri={
										`/appel-fonds-pdf?`
										+ getActivityParameters(this.props.activity)
										+ `&id=${el.id}`
									}
									filename={`appel-de-fond-${el.date}-${el.id}`}
								>
									<img
										src={`/images/pictogrammes/pdf-${chooseColor(
											this.props.picto_color
										)}.svg`}
										alt="pièce jointe"
										title="Télécharger la pièce jointe"
										className="table-picto"
									/>
								</FileDownloadedFromAPI>
							</td>
						</tr>
					))}
					<tr className="total-capital-calls">
						<td></td>
						<td>TOTAL</td>
						<TdAmount value={accumulate(this.props.table, 'amount')} />
						{/* <TdAmount
							value={accumulate(this.props.table, 'remaining_amount')}
							negative={true}
						/> */}
						<td></td>
					</tr>
				</tbody>
			</TableBase>
		);
	}
}

TableCapitalCalls.propTypes = {
	// ...Table.propTypes,
	picto_color: PropTypes.string,
	activity: PropTypes.object.isRequired,
};

export default TableCapitalCalls;
