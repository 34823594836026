// module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import Table from '../../../components/table/Table';
import TableSyndic from '../../../components/table/TableSyndic';
import Loading from '../../../components/Loading';
//action
import { setLots } from '../../../action_creators/lotsActionCreators';
import { setKeys } from '../../../action_creators/keysActionCreators';
import { setCouncilMembers } from '../../../action_creators/councilActionCreators';
import { setSyndic } from '../../../action_creators/syndicActionCreators';
import { setDataDocs, addDataDoc } from '../../../action_creators/docsActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import {
	getRequest,
	responseManagment,
	responseBlobManagment,
} from '../../../functions/ajax';
// const
import { API_PDF_SRC_URL } from '../../../constants/api';
import { getActivityParameters } from '../../../functions/coownerships';
import CardButton from '../../../components/button/CardButton';

class MyData extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			are_loading_lots: false,
			are_loading_docs: false,
			are_loading_docs_rest: false,
			are_loading_keys: false,
			are_loading_councilors: false,
			is_loading_syndic: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.responseBlobManagment = responseBlobManagment.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getLots();
		this.getDocs();
		this.getKeys();
		this.getCouncilors();
		this.getSyndic();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getLots() {
		if (!this.props.lots.length) {
			this.setState({ are_loading_lots: true });
			const response = await getRequest(
				`/lot?` + getActivityParameters(this.props.coownerships.current),
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setLots(response.lots);
			}

			if (this._isMounted) {
				this.setState({ are_loading_lots: false });
			}
		}
	}

	async getDocs() {
		if (!this.props.docs.length) {
			this.setState({
				are_loading_docs: true,
				are_loading_docs_rest: true,
			});
			// base docs
			const response = await getRequest(
				`/activity-doc?` + getActivityParameters(this.props.coownerships.current),
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setDataDocs(response.documents);
			}

			if (this._isMounted) {
				this.setState({ are_loading_docs: false });
			}

			// carnet : dynamicaly generated
			const response_pdf = await getRequest(
				`/activity-carnet-entretien?`
					+ getActivityParameters(this.props.coownerships.current),
				this.props.user.token
			);
			if (this.responseBlobManagment(response_pdf)) {
				this.props.addDataDoc({
					label: "Carnet d'entretien",
					url: URL.createObjectURL(response_pdf),
				});
			}

			if (this._isMounted) {
				this.setState({ are_loading_docs_rest: false });
			}
		}
	}

	async getKeys() {
		if (!this.props.keys.list.length) {
			this.setState({ are_loading_keys: true });
			const response = await getRequest(
				`/cle?`
					+ getActivityParameters(this.props.coownerships.current)
					+ `&max=4`,
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setKeys(response.keys);
			}

			if (this._isMounted) {
				this.setState({ are_loading_keys: false });
			}
		}
	}

	async getCouncilors() {
		if (!this.props.councilors.length) {
			this.setState({ are_loading_councilors: true });
			const response = await getRequest(
				`/conseil-syndical?`
					+ getActivityParameters(this.props.coownerships.current),
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setCouncilMembers(response.council);
			}

			if (this._isMounted) {
				this.setState({ are_loading_councilors: false });
			}
		}
	}

	async getSyndic() {
		if (!Object.keys(this.props.syndic).length) {
			this.setState({ is_loading_syndic: true });
			const response = await getRequest(
				`/syndic?` + getActivityParameters(this.props.coownerships.current),
				this.props.user.token
			);
			// Check Object.keys for setting syndic type
			if (this.responseManagment(response) && Object.keys(response.syndic).length) {
				// set syndic type from coownership to syndic
				response.syndic.type = this.props.coownerships.current.syndic;
				this.props.setSyndic(response.syndic);
			}

			if (this._isMounted) {
				this.setState({ is_loading_syndic: false });
			}
		}
	}

	render() {
		return (
			<div className="grid grid-5 my-data">
				<div className="card">
					<div className="card-header composed-card-header">
						<h2>
							Mes Lots
							{this.props.lots.length ? ` (${this.props.lots.length})` : ''}
						</h2>
						<em>à la date du jour</em>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_lots === false ? (
							this.props.lots.length ? (
								<Table
									table={this.props.lots}
									keys={['reference', 'label', 'type', 'principal']}
									cols={['REF', 'LIBELLÉ', 'TYPE', 'PRINCIPAL']}
									boolean={['Non', 'Oui']}
								/>
							) : (
								<p>Vous ne possédez pas de lots.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card">
					<div className="card-header">
						<h2>
							Document
							{this.props.docs.length
								? (this.props.docs.length > 1 ? 's' : '')
								  + `(${this.props.docs.length})`
								: ''}
						</h2>
					</div>
					<div className="card-body">
						{this.state.are_loading_docs === false ? (
							this.props.docs.length ? (
								<Fragment>
									{this.props.docs.map((doc, i) => (
										<a
											key={i}
											// Don't add API_PDF_SRC_URL to blob url
											href={
												doc.url.slice(0, 5) === 'blob:'
													? doc.url
													: API_PDF_SRC_URL + doc.url
											}
											download={`${doc.label}.pdf`}
											className="documents"
										>
											{doc.label}
										</a>
									))}
									{/* For load of not firstly requested docs */}
									{this.state.are_loading_docs_rest === true && (
										<Loading />
									)}
								</Fragment>
							) : (
								<p>Aucun document n'est disponible pour le moment.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card key-card">
					<div className="card-header">
						<h2>Clés de répartition</h2>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_keys === false ? (
							this.props.keys.list.length ? (
								<Fragment>
									<Table
										table={this.props.keys.list.slice(0, 4)}
										cols={[
											'REF',
											'LIBELLÉ',
											'NOMBRE DE LOTS',
											'TOTAL TANTIÈMES',
										]}
										keys={[
											'reference',
											'label',
											'nb_lots',
											'total_tantieme',
										]}
									/>
									<div className="btn-wrapper">
										<Link
											className="btn"
											to={`/coownership/${this.props.match.params.id}/data/keys`}
										>
											Voir tout
										</Link>
									</div>
								</Fragment>
							) : (
								<p>
									Vous n'êtes concerné par aucune clé de répartition sur
									cette copropriété.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card council-card">
					<div className="card-header">
						<h2>Conseil syndical</h2>
					</div>
					<div className="card-body table-wrapper">
						{this.state.are_loading_councilors === false ? (
							this.props.councilors.length ? (
								<Table
									table={this.props.councilors}
									keys={['name', 'mail', 'start']}
									cols={['nom', 'contact', 'mandat']}
								/>
							) : (
								<p>Il n'y a aucun conseiller sur cette copropriété</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<div className="card syndic-card">
					<div className="card-header">
						<h2>
							Syndic{' '}
							{this.props.syndic.type === 'pro'
								? 'Professionnel'
								: 'Bénévole'}
						</h2>
					</div>
					<div className="card-body table-wrapper">
						{this.state.is_loading_syndic === false ? (
							Object.keys(this.props.syndic).length ? (
								<TableSyndic
									className="horizontal"
									table={this.props.syndic}
								/>
							) : (
								<p>Il n'y a pas de syndic pour cette copropriété</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<CardButton
					title="Relevés de Compteurs"
					btn_label="Accéder aux Relevés de Compteurs"
					url={`/coownership/${this.props.match.params.id}/data/counters`}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		lots: state.lots,
		docs: state.docs.data_docs,
		keys: state.keys,
		councilors: state.council.members,
		syndic: state.syndic,
	};
};
const mapDispacthToProps = {
	setLots,
	setKeys,
	setCouncilMembers,
	setSyndic,
	setDataDocs,
	addDataDoc,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyData));
