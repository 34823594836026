//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
//component
import GraphLines from '../../../components/graph/GraphLines';
import Loading from '../../../components/Loading';
import CardButton from '../../../components/button/CardButton';
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
// actions
import {
	setBankGraph,
	setBanks,
	setCurrentBank,
} from '../../../action_creators/banksActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { createBankGraph } from '../../../functions/bank';
import { getRequest, responseManagment } from '../../../functions/ajax';
import { formatNumbers } from '../../../functions';
import { getActivityParameters } from '../../../functions/coownerships';

const OVERALL_TREASURY = { label: 'Trésorerie globale', default: false };

class CoownershipCouncil extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleAccount = this.handleAccount.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!Object.keys(this.props.banks.graph).length) {
			this.getBankGraph();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		// Download bank graph when bank change
		if (prevProps.banks.current_bank !== this.props.banks.current_bank) {
			this.getBankGraph();
		}
	}

	async getBankGraph() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/banque-graph?`
				+ getActivityParameters(this.props.coownerships.current)
				+ (this.props.banks.current_bank >= 0 && this.props.banks.list.length
					? `&banque=${this.props.banks.list[this.props.banks.current_bank].id}`
					: ''),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			if (response.months && response.years) {
				const graph = createBankGraph(response);
				if (this.props.banks.list.length && response.years) {
					this.props.setBankGraph(graph);
				} else {
					// init banks with graph
					this.props.setBanks(response.banks, -1, graph);
				}
			} else if (!this.props.banks.list.length) {
				// init banks without graph
				this.props.setBanks(response.banks, -1);
			}
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleAccount(evt) {
		this.props.setCurrentBank(evt.target.value - 1);
	}

	render() {
		return (
			<div
				className={
					'grid grid-5 coownership-council'
					+ (Object.keys(this.props.banks.graph).length
					&& this.props.banks.graph.series.length
						? ' parent-graph'
						: '')
				}
			>
				<div className="card unscrollable">
					<div className="card-header composed-card-header">
						<h2>Trésorerie</h2>
						{this.props.banks.list.length ? (
							<SelectorItemObject
								id="select-bank-account"
								label="Compte"
								current={Number(this.props.banks.current_bank) + 1}
								options={[OVERALL_TREASURY, ...this.props.banks.list]}
								callback={this.handleAccount}
							/>
						) : null}
					</div>
					<div className="card-body">
						{this.state.is_loading === false ? (
							this.props.banks.list.length ? (
								<Fragment>
									{Object.keys(this.props.banks.graph).length
									&& this.props.banks.graph.series.length ? (
										<GraphLines
											id="bank-graph"
											title={
												'Évolution du solde de la banque saisie dans votre comptabilité sur les 12 derniers mois'
												+ (this.props.banks.graph.solde
													? `, solde réel à ce jour\u00A0: ${formatNumbers(
															this.props.banks.graph.solde
													  )}\u00A0€`
													: '')
											}
											x={this.props.banks.graph.x}
											series={this.props.banks.graph.series}
										/>
									) : (
										<p>
											Aucune donnée n'est disponible pour ce compte
											sur les 36 derniers mois.
										</p>
									)}
									<div className="btn-wrapper">
										<Link
											className="btn"
											to={`/coownership/${this.props.match.params.id}/bank`}
										>
											Accéder au détail
										</Link>
									</div>
								</Fragment>
							) : (
								<p>Aucune donnée n'est disponible sur ce compte.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
				<CardButton
					title="Balance"
					btn_label="Accéder au détail de la balance"
					url={`/coownership/${this.props.match.params.id}/coownership_council/balance`}
				/>
				<CardButton
					title="Grand livre"
					btn_label="Accéder au détail du Grand Livre"
					url={`/coownership/${this.props.match.params.id}/coownership_council/great_book`}
				/>
				<CardButton
					title="Procédures judiciaires"
					btn_label="Accéder aux procédures judiciaires en cours"
					url={`/coownership/${this.props.match.params.id}/coownership_council/judicial_procedures`}
				/>
				<CardButton
					title="Liste des copropriétaires"
					btn_label="Accéder à la liste des copropriétaires"
					url={`/coownership/${this.props.match.params.id}/coownership_council/coowners`}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		banks: state.banks,
	};
};
const mapDispatchToProps = {
	setBankGraph,
	setBanks,
	setCurrentBank,
	setLog,
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(CoownershipCouncil)
);
