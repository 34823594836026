//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import TableKeys from '../../../components/table/TableKeys';
import Loading from '../../../components/Loading';
import PrintButton from '../../../components/button/PrintButton';
//action
import { setKeys } from '../../../action_creators/keysActionCreators';
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable } from '../../../functions/print';
import { getActivityParameters } from '../../../functions/coownerships';

class MyKeys extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.getKeys();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getKeys() {
		if (!this.props.keys.saved) {
			this.setState({ is_loading: true });
			const response = await getRequest(
				`/cle?` + getActivityParameters(this.props.coownerships.current),
				this.props.user.token
			);
			if (this.responseManagment(response)) {
				this.props.setKeys(response.keys, true);
			}

			if (this._isMounted) {
				this.setState({ is_loading: false });
			}
		}
	}

	handlePrint(evt) {
		printTable(
			'table-keys',
			'mes-cles-de-repartition.pdf',
			'Mes clés de répartition',
			this.props.coownerships.current
		);
	}

	goBack(evt) {
		this.props.history.push(`/coownership/${this.props.match.params.id}/data/`);
	}

	render() {
		return (
			<div className="grid grid-1">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Mes clés de répartition</h2>
						<PrintButton
							picto_color={this.props.coownerships.current.syndic}
							print={this.handlePrint}
						/>
					</div>
					<div className="card-body table-wrapper" id="toPrint">
						{this.state.is_loading === false ? (
							this.props.keys.list.length ? (
								<TableKeys
									cols={[
										'REF',
										'LIBELLÉ',
										'NOMBRE DE LOTS',
										'TANTIÈMES',
										'TOTAL TANTIÈMES',
									]}
									table={this.props.keys.list}
								/>
							) : (
								<p>Vous n'avez aucune clé de répartition.</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		keys: state.keys,
	};
};
const mapDispacthToProps = {
	setKeys,
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispacthToProps)(MyKeys));
