import { API_URL, APP_TYPE } from '../constants/api';

/**
 *
 * @param {string} uri
 * @param {object} params
 */
async function request(uri, params) {
	return await fetch(API_URL + uri, params)
		.then(response => {
			const content_type = response.headers.get('content-type');
			if (content_type === 'application/json') {
				return response.json();
			}
			// If it's not an error and not JSON, it's blob (for this API).
			return response.blob();
		})
		.catch(error => error);
}

/**
 * Send an AJAX request with POST method
 *
 * @param {string} uri
 * @param {object} data
 * @param {string} token
 */
export async function postRequest(uri, data, token) {
	// transform datas to formDatas
	let formData = new FormData();
	return await Promise.all(
		Object.entries(data).map(([key, value]) => formData.append(key, value))
	).then(() => {
		return request(uri, {
			method: 'POST',
			body: formData,
			headers: token
				? {
					Authorization: `Bearer ${token}`,
					'x-app-type': APP_TYPE,
				}
				: { 'x-app-type': APP_TYPE },
		});
	});
}

/**
 * Send an AJAX request with GET method
 *
 * @param {string} uri
 * @param {string} token
 */
export function getRequest(uri, token) {
	return request(uri, {
		method: 'GET',
		headers: { 
			Authorization: `Bearer ${token}`, 
			'x-app-type': APP_TYPE 
		},
	});
}

/**
 * Manage ajax response for JSON
 *
 * @param {object} response
 * @returns {boolean}
 * Required to bind "this"
 */
export function responseManagment(response) {
	if (response.success === true) {
		// JSON
		return response.success;
	} else {
		// Error
		responseErrorManagment.bind(this, response)();
		return false;
	}
}

/**
 * Manage ajax response for blob
 *
 * @param {object} response
 * Required to bind "this"
 * @returns {boolean}
 */
export function responseBlobManagment(response) {
	if (response.type && response.type !== 'application/json') {
		// Blob
		return true;
	} else if (response.success === true) {
		// response success but no result was found
		return false;
	} else {
		// Error
		responseErrorManagment.bind(this, response)();
		return false;
	}
}

/**
 * Manage ajax response errors
 *
 * @param {object} response
 * Required to bind "this"
 * Required "setLog"
 * Required "withRouter"
 */
export function responseErrorManagment(response) {
	if (response.error) {
		if (response.error.messages && Array.isArray(response.error.messages)) {
			response.error.message = response.error.messages[0];
		}
		switch (response.error.message) {
			case 'Token is expired.':
				this.props.setLog({
					type: 'error',
					message: 'Connection expirée.',
				});
				this.props.history.push('/logout');
				break;

			case 'Token is badly constituted.':
				this.props.setLog({
					type: 'error',
					message: 'Connection invalide.',
				});
				this.props.history.push('/logout');
				break;

			case 'User not found.':
			case 'Username or Password not valid.':
				this.props.setLog({
					type: 'error',
					message: "Mot de passe ou nom d'utilisateur invalide..",
				});
				break;

			case 'Username or Password are empty.':
				this.props.setLog({
					type: 'error',
					message: "Mot de passe ou nom d'utilisateur vide.",
				});
				break;

			case 'Profile picture is empty':
				this.props.setLog({
					type: 'error',
					message: "L'image de profile est mauvaise ou non fournie.",
				});
				break;

			case "Le nom n'est pas renseigné":
				this.props.setLog({
					type: 'error',
					message: 'Vous devez renseigner votre nom.',
				});
				break;

			case "L'email n'est pas renseigné":
				this.props.setLog({
					type: 'error',
					message: 'Vous devez renseigner votre adresse email.',
				});
				break;

			case 'You must choose an other personn.':
				this.props.setLog({
					type: 'error',
					message: "Ce compte n'existe pas sur cette copropriété.",
				});
				break;

			case 'Activity is not recognized or not accessible.':
				this.props.setLog({
					type: 'error',
					message: "Vous avez essayé de vous connecter sur deux comptes en même temps. Vous avez été déconnecté, veuillez vous reconnecter.",
				});
				this.props.history.push('/logout');
				break;

			default:
				this.props.setLog({
					type: 'error',
					message: response.error.message
						? response.error.message
						: 'Une erreur est survenue.',
				});
		}
	} else if (response.toString() === 'TypeError: Failed to fetch') {
		this.props.setLog({
			type: 'error',
			message: "La connexion avec le serveur n'a pas pu être effectuée.",
		});
	} else {
		this.props.setLog({
			type: 'error',
			message: 'Une erreur est survenue.',
		});
	}
}
