// module
import React, { Component } from 'react';
// components
import { TableBase } from './Table';
// functions
import { accumulate } from '../../functions';

class TableKeys extends Component {
	render() {
		return (
			<TableBase className="table-keys" cols={this.props.cols}>
				{this.props.table.map((el, i) => (
					<tbody key={i}>
						<tr>
							<td>{el.reference}</td>
							<td>{el.label}</td>
							<td>{el.nb_lots}</td>
							<td></td>
							<td>{el.total_tantieme}</td>
						</tr>
						{el.lots
							? el.lots.map((lot, j) => {
								if (j === 0) {
									return (
										<tr key={j} className="lots">
											<td rowSpan={el.lots.length}>Vos lots</td>
											<td>{lot.label}</td>
											<td rowSpan={el.lots.length}>
												{el.lots.length}
											</td>
											<td>{lot.tantieme}</td>
											<td rowSpan={el.lots.length}>
												{accumulate(el.lots, 'tantieme')}
											</td>
										</tr>
									);
								} else {
									return (
										<tr key={j} className="lots">
											<td>{lot.label}</td>
											<td>{lot.tantieme}</td>
										</tr>
									);
								}
							}) : null
						}
					</tbody>
				))}
			</TableBase>
		);
	}
}

export default TableKeys;
