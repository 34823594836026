
// module
import React from 'react';
import PropTypes from 'prop-types';
// functions
import { chooseColor } from '../../functions'

function PrintButton(props) {
    return (
        <button className="btn-print" onClick={evt => props.print(evt)}>
            <img
                src={`/images/pictogrammes/printer-${chooseColor(props.picto_color)}.svg`}
                alt="pictogramme d'imprimante"
            />
            <span className="sr-only">Imprimer ou exporter en pdf.</span>
        </button>
    );
}

PrintButton.propTypes = {
    picto_color: PropTypes.string,
    print: PropTypes.func.isRequired,
};

export default PrintButton;

