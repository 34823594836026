// module
import React, { Component } from 'react';
import { chooseColor } from '../../functions';
import { getActivityParameters } from '../../functions/coownerships';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';
// components
import { colorOddRows, TableBase } from './Table';
import {
	setAnimatedCellsHeight,
	toggleAnimatedCells,
	resetAnimatedCells,
} from '../../functions/animatedCells';
import { API_PDF_SRC_URL } from '../../constants/api';

class TableVisits extends Component {
	constructor(props) {
		super(props);

		this.handleSteps = this.handleSteps.bind(this);
		this.updateAnimatedCells = this.updateAnimatedCells.bind(this);
	}

	updateAnimatedCells(index) {
		setAnimatedCellsHeight();
		toggleAnimatedCells(index);
	}

	handleSteps(evt) {
		const { target, currentTarget } = evt;
		if (
			target.tagName === 'IMG'
			&& target.classList.contains('table-picto')
			&& !target.classList.contains('arrow')
		) {
			return;
		}
		const data_index = currentTarget.dataset.index;
		// image animation
		const image = document.getElementById(data_index);
		if (image.classList.contains('clicked')) {
			image.classList.remove('clicked');
		} else {
			image.classList.add('clicked');
		}
		// toggle steps
		setTimeout(() => this.updateAnimatedCells(data_index), 100);
	}

	render() {
		return (
			<TableBase className="table-visits" cols={this.props.cols}>
				{this.props.table.map((visit, i) => (
					<tbody key={i} className="table-hidden-rows">
						<tr
							className={colorOddRows(i) + ' tr-clickable'}
							onClick={this.handleSteps}
							data-index={`index-${i}`}
							title="Afficher les étapes"
						>
							<td className="td-label">{visit.label}</td>
							<td>{visit.effectuePar}</td>
							<td>{visit.date}</td>
							<td className="td-label">{visit.description}</td>
							<td>
								{visit.steps.length > 0 && (
									<img
										src={`/images/pictogrammes/arrow-${chooseColor(
											this.props.picto_color
										)}.svg`}
										alt="bouton détails"
										className={`table-picto arrow mr-2`}
										id={`index-${i}`}
									/>
								)}
								<FileDownloadedFromAPI
									className="d-inline-block"
									uri={
										`/visites-pdf?`
										+ getActivityParameters(this.props.activity)
										+ `&id=${visit.id}`
									}
									filename={`${visit.label}-${visit.date}`}
								>
									<img
										src={`/images/pictogrammes/pdf-${chooseColor(
											this.props.picto_color
										)}.svg`}
										alt="Visite technique PDF"
										title="Télécharger la visite technique"
										className={`table-picto`}
									/>
								</FileDownloadedFromAPI>
							</td>
						</tr>
						{visit.steps.map((step, j) => (
							<tr key={j} className={`hidden hideable index-${i}`}>
								{j === 0 && (
									<td rowSpan={visit.steps.length}>
										<div className="animated-cell">Détails</div>
									</td>
								)}
								<td colSpan={2}>
									<div className="animated-cell">{step.place}</div>
								</td>
								<td>
									<div className="animated-cell td-label">
										{step.description}
									</div>
								</td>
								<td></td>
							</tr>
						))}
					</tbody>
				))}
			</TableBase>
		);
	}
}

export default TableVisits;
