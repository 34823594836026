//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import GraphDoubleColumns from '../../../../components/graph/GraphDoubleColumns';
import Loading from '../../../../components/Loading';
//action
import { setLog } from '../../../../action_creators/logActionCreators';
import { setGlobalChargesAmounts } from '../../../../action_creators/coownershipAmountsActionCreators';
import { getRequest, responseManagment } from '../../../../functions/ajax';
// constants
import { red, dark_red } from '../../../../constants/colors';
import { getActivityParameters } from '../../../../functions/coownerships';

class ChargesCard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
	}

	async UNSAFE_componentWillMount() {
		if (!this.props.charges.global_amounts.length) {
			this.getGlobalCharges();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getGlobalCharges() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/etat-charges-global?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&graph=1`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			const result = response.datas.map(data => this.prepareChargesDate(data));
			this.props.setGlobalChargesAmounts(result);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	prepareChargesDate(charge) {
		const startYear = charge.start.slice(-4);
		const endYear = charge.end.slice(-4);
		charge.date = startYear === endYear ? startYear : `${startYear}-${endYear}`;
		return charge;
	}

	render() {
		return (
			<div className="card unscrollable">
				<div className="card-header composed-card-header">
					<h2>État des charges</h2>
					{this.props.charges.global_amounts.length ? (
						<em>
							{this.props.charges.global_amounts.length > 1
								? `sur les ${this.props.charges.global_amounts.length} dernières années`
								: "sur l'année en cours"}
						</em>
					) : null}
				</div>
				<div className="card-body charges-graph">
					{this.state.is_loading === false ? (
						this.props.charges.global_amounts.length ? (
							<Fragment>
								<GraphDoubleColumns
									id="chargesHistogramme"
									colors={[red, dark_red]}
									x={this.props.charges.global_amounts.map(
										amount => amount.date
									)}
									series={[
										{
											data: this.props.charges.global_amounts.map(
												amount => amount.current || null
											),
											name: 'Charges Courantes',
										},
										{
											data: this.props.charges.global_amounts.map(
												amount => amount.other || null
											),
											name: 'Charges Exceptionnelles',
										},
									]}
									cols={this.props.charges.global_amounts.length}
								/>
								<div className="btn-wrapper">
									<Link
										className="btn"
										to={`/coownership/${this.props.match.params.id}/coownership/charges`}
									>
										Accéder
									</Link>
								</div>
							</Fragment>
						) : (
							<p>Aucune charge disponible.</p>
						)
					) : (
						<Loading />
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		charges: state.charges,
	};
};
const mapDispatchToProps = {
	setLog,
	setGlobalChargesAmounts,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChargesCard));
