// module
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// component
import Form from './Form';
import FormItem from './item/FormItem';
import DateFieldset from './item/DateFieldset';
// constants
import { OLDER_API_DATE } from '../../constants/api';

const current_date = new Date();
const max_year = current_date.getFullYear() + 2;

class FormSimulation extends Form {
	constructor(props) {
		super(props);

		this.state = {
			amount: 0,
			key: '',
			date: '',
		};
		this.initialState = { ...this.state };

		this.handleDate = this.handleDate.bind(this);
		this.handleKey = this.handleKey.bind(this);
		this.handleAmount = this.handleAmount.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		// Update key when keys change
		if (prevProps.keys !== this.props.keys) {
			if (this.props.keys.length) {
				this.setState({ key: this.props.keys[0].id });
			} else {
				// Reset key when keys are void
				this.setState({ key: '' });
			}
		}
	}

	handleAmount(evt) {
		if (isNaN(evt.target.value) || evt.target.value === '') {
			this.props.setLog({
				type: 'error',
				message: 'Le montant doit être un nombre.',
			});
		} else if (evt.target.value <= 0) {
			this.props.setLog({
				type: 'error',
				message: 'Le montant doit être un nombre positif.',
			});
		} else {
			this.setState({ amount: evt.target.value });
		}
	}

	handleDate(date) {
		if (this.checkDate(date)) {
			// Format date for API
			let formated_date = `${date.year}-${date.month}-${date.day}`;
			// Reuse when submitting
			this.setState({
				date: formated_date,
				amount: 0,
			});
			this.props.handleDate(formated_date);
		}
	}

	handleKey(evt) {
		this.setState({ key: evt.target.value });
	}

	checkDate(date) {
		if (date.day.length !== 2 || date.month.length !== 2 || date.year.length !== 4) {
			// date is not ready to be checked
			return false;
		}
		if (date.day < 1 || date.month < 1 || date.year < 1) {
			this.props.setLog({
				type: 'error',
				message: 'La date est imcomplète. Veuillez respecter le format : jj/mm/aaaa.',
			});
			return false;
		}
		if (date.year < OLDER_API_DATE) {
			this.props.setLog({
				type: 'error',
				message: `Date trop ancienne, veuillez faire une simulation postérieure à ${OLDER_API_DATE}.`,
			});
			return false;
		}else if (date.year > max_year) {
			this.props.setLog({
				type: 'error',
				message: `Vous ne pouvez pas faire de simulation après ${max_year}.`,
			});
			return false;
		}

		return true;
	}

	handleSubmit(evt) {
		evt.preventDefault();
		if (this.state.key) {
			this.props.callback({ ...this.state });
		} else {
			this.props.setLog({
				type: 'error',
				message: 'La date est imcomplète. Veuillez respecter le format : jj/mm/aaaa.',
			});
		}
	}

	buildFormItems() {
		return (
			<Fragment>
				<DateFieldset
					day_id="day"
					month_id="month"
					year_id="year"
					label="Date de l'état"
					required={true}
					callback={this.handleDate}
				/>
				{/* Select values are not mapped like SelectorItem */}
				<div className="form-item form-group">
					{/* Should customise a select due to options and label with annotation */}
					<div className="item-selector">
						<label htmlFor="key">
							Clé&nbsp;* <em>à la date de l'état</em>
						</label>
						<select
							id="key"
							name="key"
							value={this.state.key}
							onChange={this.handleKey}
						>
							{this.props.keys.length
								? this.props.keys.map((key, i) => (
										<option key={i} value={key.id}>
											{key.name}
										</option>
								  ))
								: null
							}
						</select>
					</div>
				</div>
				<FormItem
					input={{
						type: 'number',
						name: 'amount',
						label: 'Montant',
						required: true,
					}}
					value={this.state.amount}
					callback={this.handleAmount}
				/>
			</Fragment>
		);
	}
}

FormSimulation.propTypes = {
	callback: PropTypes.func.isRequired,
	handleDate: PropTypes.func.isRequired,
	setLog: PropTypes.func.isRequired,
};

export default FormSimulation;
