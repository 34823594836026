// module
import React, { Component } from 'react';
// components
import { TableBase, colorEvenRows } from './Table';
import TdAmount from './TdAmount';

class TableAdvances extends Component {
    render() {
        return (
            <TableBase className="table-advances" cols={[]}>
                <tbody>
                    {this.props.table.map((el, i) => (
                        <tr key={i} className={colorEvenRows(i)}>
                            <td className="td-label">{el.label}</td>
                            <TdAmount value={el.amount}/>
                        </tr>
                    ))}
                </tbody>
            </TableBase>
        );
    }
}

export default TableAdvances;