import {
	SET_GREAT_BOOK_TRANSACTIONS,
    SET_GREAT_BOOK_EXERCICES,
    SET_GREAT_BOOK_CURRENT_EXERCICE,
    REMOVE_GREAT_BOOK
} from "../action_types/councilActionTypes";

const initialState = {
	transactions: [],
	exercices: [],
	current_exercice: 0
};

function greatBookReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case SET_GREAT_BOOK_TRANSACTIONS:
			nextState = {
				...state,
				transactions: action.transactions
			};
			return nextState || state;

		case SET_GREAT_BOOK_EXERCICES:
			nextState = {
				...state,
				exercices: action.exercices
			};
			return nextState || state;

		case SET_GREAT_BOOK_CURRENT_EXERCICE:
			nextState = {
				...state,
				current_exercice: action.exercice
			};
			return nextState || state;

		case REMOVE_GREAT_BOOK:
			return initialState;

		default:
			return state;
	}
}

export default greatBookReducer;
