// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase } from './Table';
import Loading from '../Loading';
// functions
import { chooseColor } from '../../functions';
import {
	setAnimatedCellsHeight,
	toggleAnimatedCells,
	resetAnimatedCells,
} from '../../functions/animatedCells';
// const
import { API_PDF_SRC_URL } from '../../constants/api';

class TableWorks extends Component {
	constructor(props) {
		super(props);

		this.state = {
			is_loading: true,
			current_work: 0,
		};

		this.handleSteps = this.handleSteps.bind(this);
		this.updateAnimatedCells = this.updateAnimatedCells.bind(this);
	}

	componentDidUpdate(prevProps) {
		// Re-hide hiddenable tbody when exercice change
		if (prevProps.exercice !== this.props.exercice) {
			resetAnimatedCells();
		}
		// Toggle tr if steps have been loaded
		if (
			prevProps.table[this.state.current_work]
			&& !prevProps.table[this.state.current_work].steps
			&& this.props.table[this.state.current_work].steps
		) {
			this.setState({ is_loading: false });
			// let time to render before remove class to see animation
			setTimeout(
				() => this.updateAnimatedCells(`work-${this.state.current_work}`),
				100
			);
		}
	}

	updateAnimatedCells(trId) {
		setAnimatedCellsHeight();
		toggleAnimatedCells(trId);
	}

	handleSteps(evt) {
		if (this.setState.is_loading) return;
		const id = evt.currentTarget.dataset.id;
		const trId = evt.currentTarget.id;
		const image = document.querySelectorAll(`.table-picto.arrow.${trId}`);
		if (image[0].classList.contains('clicked')) {
			image[0].classList.remove('clicked');
		} else {
			image[0].classList.add('clicked');
		}

		if (this.props.table[id].steps) {
			this.updateAnimatedCells(trId);
		} else {
			this.setState({
				is_loading: true,
				current_work: parseInt(id),
			});
			this.props.getSteps(id);
		}
	}

	render() {
		return (
			<TableBase className="table-works" cols={this.props.cols}>
				{Object.values(this.props.table).map(work => (
					<tbody key={work.id} className="table-hidden-rows">
						<tr
							id={`work-${work.id}`}
							data-id={work.id}
							className="tr-clickable"
							onClick={this.handleSteps}
							title="Afficher les étapes"
						>
							<td className="td-label">{work.label}</td>
							<td>{work.keys.map(key => `${key} `)}</td>
							<td>{work.inProgress ? 'En cours' : 'Clôturés'}</td>
							<td className="date">{work.start}</td>
							<td>
								<img
									src={`/images/pictogrammes/arrow-${chooseColor(
										this.props.picto_color
									)}.svg`}
									alt="bouton détails"
									className={`table-picto arrow work-${work.id}`}
								/>
							</td>
						</tr>
						{this.state.is_loading === true
							&& this.state.current_work === work.id && (
								<tr>
									<td colSpan={this.props.cols.length}>
										<Loading />
									</td>
								</tr>
							)}
						{work.steps
							? work.steps.map((step, j) => (
									<tr
										key={j}
										className={`hidden hideable work-${work.id}`}
									>
										{j === 0 && (
											<td rowSpan={work.steps.length}>
												<div className="animated-cell">
													Détails
												</div>
											</td>
										)}
										<td colSpan={2} className={'td-label'}>
											<div
												className="animated-cell"
												dangerouslySetInnerHTML={{
													__html: step.label,
												}}
											/>
										</td>
										<td>
											<div className="animated-cell">
												{step.date}
											</div>
										</td>
										<td>
											<div className="animated-cell">
												{step.files.map((file, k) => (
													<a
														href={API_PDF_SRC_URL + file.url}
														title={file.label}
														key={k}
													>
														<img
															className="table-picto"
															src={`/images/pictogrammes/pdf-grey.svg`}
															alt="fichier"
														/>
													</a>
												))}
											</div>
										</td>
									</tr>
							  ))
							: null}
					</tbody>
				))}
			</TableBase>
		);
	}
}

TableWorks.propTypes = {
	// ...Table.propTypes,
	// Associative array with ids
	table: PropTypes.object.isRequired,
	picto_color: PropTypes.string,
	exercice: PropTypes.any.isRequired,
};

export default TableWorks;
