// module
import React, { Component } from 'react';
import { chooseColor } from '../../functions';
import { getActivityParameters } from '../../functions/coownerships';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';
// components
import { colorOddRows, TableBase } from './Table';

class TableDebitMandates extends Component {
	render() {
		return (
			<TableBase className="table-debit-mandates" cols={this.props.cols}>
				<tbody>
					{this.props.table.map((mandat, i) => (
						<tr key={i} className={colorOddRows(i)}>
							<td>{mandat.reference}</td>
							<td>{mandat.debtor}</td>
							<td>{mandat.bic}</td>
							<td>{mandat.iban}</td>
							<td>{mandat.creditor}</td>
							<td>{mandat.bank}</td>
							<td>
								<FileDownloadedFromAPI
									uri={
										`/mandats-prelevements-pdf?`
										+ getActivityParameters(this.props.activity)
										+ `&id=${mandat.id}`
									}
									filename={'mandat-prelevement-' + mandat.reference}
								>
									<img
										src={`/images/pictogrammes/pdf-${chooseColor(
											this.props.picto_color
										)}.svg`}
										alt="Mandat de Prélèvement PDF"
										title="Télécharger le mandat de prélèvement"
										className={`table-picto`}
									/>
								</FileDownloadedFromAPI>
							</td>
						</tr>
					))}
				</tbody>
			</TableBase>
		);
	}
}

export default TableDebitMandates;
