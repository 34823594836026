import * as types from '../action_types/councilActionTypes';

const initialState = {
    members: [],
    coowners: {},
    nb_pages_coowners: 0,
    judicial_procedures: []
};

function councilReducer(state = initialState, action) {
    let nextState;

    switch (action.type) {

        case types.SET_COUNCIL_MEMBERS:
            nextState = {
                ...state,
                members: action.members
            };
            return nextState || state;

        case types.SET_COOWNERS:
            nextState = {
                ...state,
                coowners: {
                    ...state.coowners
                }
            };
            nextState.coowners[action.current_page] = action.coowners;
            return nextState || state;

        case types.SET_NB_PAGES_COOWNERS:
            nextState = {
                ...state,
                nb_pages_coowners: action.nb
            };
            return nextState || state;

        case types.SET_JUDICIAL_PROCEDURES:
            nextState = {
                ...state,
                judicial_procedures: action.judicial_procedures
            };
            return nextState || state;

        case types.REMOVE_COOWNERS:
            nextState = {
                ...state,
                coowners: {},
                nb_pages_coowners: 0
            };
            return nextState || state;


        case types.REMOVE_COUNCIL:
            return initialState;

        default:
            return state;
    }
}

export default councilReducer;