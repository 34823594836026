import * as types from '../action_types/docsActionTypes';

const initialState = {
    data_docs: [],
    folders: [],
    files: [],
    judicials: {
        docs: [],
        dates: [],
        current_date: 0,
    }
};

function keysReducer(state = initialState, action) {
    let nextState;

    switch (action.type) {

        case types.REMOVE_DOCS:
            return initialState;

        case types.SET_DATA_DOCS:
            nextState = {
                ...state,
                data_docs: action.docs
            };
            return nextState || state;

        case types.ADD_DATA_DOC:
            nextState = {
                ...state,
                data_docs: state.data_docs.slice()
            };
            nextState.data_docs.push(action.doc);
            return nextState || state;

        case types.SET_FOLDER:
            nextState = {
                ...state,
                folders: action.folders,
                files: action.files
            };
            return nextState || state;

        case types.SET_JUDICIALS_DOCS:
            nextState = {
                ...state,
                judicials: {
                    ...state.judicials,
                    docs: action.docs
                }
            };
            return nextState || state;

        case types.SET_JUDICIALS_DATES:
            nextState = {
                ...state,
                judicials: {
                    ...state.judicials,
                    dates: action.dates
                }
            };
            return nextState || state;

        case types.SET_JUDICIALS_CURRENT_DATE:
            nextState = {
                ...state,
                judicials: {
                    ...state.judicials,
                    current_date: action.date
                }
            };
            return nextState || state;

        default:
            return state;
    }
}

export default keysReducer;