//module
import React, { Component /* , createRef */ } from 'react';
import PropTypes from 'prop-types';
import { makeClassName } from '../../../functions';

class DropdownMultiSelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			checkeds: {},
			app: null,
			appListener: null,
		};

		// this.dropdownRef = createRef();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleClickOut = this.handleClickOut.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
	}

	componentDidMount() {
		const app = document.getElementsByClassName('app')[0];
		this.setState({ app });
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.open !== this.state.open && this.state.open) {
			const listener = this.state.app.addEventListener(
				'click',
				this.handleClickOut
			);
			this.setState({ appListener: listener });
		}
	}

	handleClick(evt) {
		// evt.preventDefault();
		if (!this.state.open) {
			this.setState({ open: true });
		}
	}

	handleClickOut(evt) {
		// if (
		// 	!!this.dropdownRef.current
		// 	&& !this.dropdownRef.current.contains(evt.target)
		// ) {
		this.setState({ open: false });
		this.state.app.removeEventListener('click', this.state.appListener);
		// }
	}

	handleAdd(i) {
		this.setState({
			checkeds: { ...this.state.checkeds, [i]: this.props.options[i].id },
		});
	}
	handleRemove(i) {
		const checkeds = Object.assign({}, this.state.checkeds);
		delete checkeds[i];
		this.setState({ checkeds });
	}

	handleSubmit(e) {
		e.preventDefault();
		this.props.callback(this.state.checkeds);
	}

	render() {
		const { id, options, label, required } = this.props;
		const { checkeds, open } = this.state;
		return (
			<form className="form form-multi-dropdown" onSubmit={this.handleSubmit}>
				<div className="item-selector item-selector-dropdown">
					<label htmlFor={id}>
						{label}
						{required ? '\u00A0*' : '\u00A0:'}
					</label>
					<div
						className={makeClassName('dropdown multi-select', open && 'open')}
						id={id}
						onClick={this.handleClick}
						// ref={this.dropdownRef}
					>
						{options.map(
							(option, i) =>
								!!checkeds[i] && (
									<div
										className="label"
										key={i}
										onClick={e => {
											e.preventDefault();
											this.handleRemove(i);
										}}
									>
										{option.label}
									</div>
								)
						)}
						<ul className="dropdown-menu">
							{options.map(
								(option, i) =>
									!checkeds[i] && (
										<li
											className="item"
											role="option"
											key={i}
											aria-selected={!!checkeds[i]}
											onClick={e => {
												e.preventDefault();
												this.handleAdd(i);
											}}
										>
											{option.label}
										</li>
									)
							)}
						</ul>
					</div>
				</div>
				<div className="submit-button-wrapper">
					<button className="btn submit-button" type="submit">
						Valider
					</button>
				</div>
			</form>
		);
	}
}
export default DropdownMultiSelect;

DropdownMultiSelect.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool,
	// values: PropTypes.object.isRequired,
	callback: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
};
