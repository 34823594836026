//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import PrintButton from '../../../components/button/PrintButton';
import TableAccount from '../../../components/table/TableAccount';
import Loading from '../../../components/Loading';
//action
import { setLog } from '../../../action_creators/logActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { printTable, getSelectFilters } from '../../../functions/print';
import { getActivityParameters } from '../../../functions/coownerships';

class CoownerAccount extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			coowner: {},
			transactions: [],
			exercices: [],
			current_exercice: 0,
			account: '',
		};

		this.responseManagment = responseManagment.bind(this);
		this.goBack = this.goBack.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.handlePrint = this.handlePrint.bind(this);
		this.setOwnPrintFilters = this.setOwnPrintFilters.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.checkParams();
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.current_exercice !== this.state.current_exercice) {
			this.updateAccount();
		}
	}

	checkParams() {
		let { coowner_id } = this.props.match.params;

		if (coowner_id === undefined) {
			this.goBack();
		} else if (isNaN(coowner_id) || coowner_id < 1) {
			this.goBack();
		} else {
			this.getAccount(coowner_id);
		}
	}

	async getAccount(coowner_id) {
		if (!this.state.transactions.length) {
			this.setState({ is_loading: true });
			const response = await getRequest(
				`/compte-coproprietaire?`
					+ getActivityParameters(this.props.coownerships.current)
					+ `&id_coproprietaire=${coowner_id}`,
				this.props.user.token
			);
			if (this._isMounted) {
				if (this.responseManagment(response)) {
					this.setState({
						transactions: response.compteCoproprietaire,
						exercices: response.exercices,
						account: response.compteNumber,
						coowner: {
							id: coowner_id,
							name: response.personneName,
						},
						is_loading: false,
					});
				} else {
					this.setState({ is_loading: false });
				}
			}
		}
	}

	async updateAccount() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/compte-coproprietaire?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&start=${this.state.exercices[this.state.current_exercice].start}`
				+ `&end=${this.state.exercices[this.state.current_exercice].end}`
				+ `&id_coproprietaire=${this.state.coowner.id}`,
			this.props.user.token
		);
		if (this._isMounted) {
			if (this.responseManagment(response)) {
				this.setState({
					transactions: response.compteCoproprietaire,
					is_loading: false,
				});
			} else {
				this.setState({
					transactions: [],
					is_loading: false,
				});
			}
		}
	}

	handleExercice(evt) {
		this.setState({ current_exercice: evt.target.value });
	}

	handlePrint(evt) {
		printTable(
			'table-account',
			`compte-coproprietaire-${this.state.coowner.name}.pdf`,
			`Le compte copropriétaire de ${this.state.coowner.name}`,
			this.props.coownerships.current,
			this.setOwnPrintFilters
		);
	}

	setOwnPrintFilters() {
		return `Compte : ${this.state.account} | ${getSelectFilters()}`;
	}

	goBack(evt) {
		this.props.history.push(
			`/coownership/${this.props.match.params.id}/coownership_council/coowners`
		);
	}

	render() {
		return (
			<div className="grid grid-1 my-coowner-account">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Compte copropriétaire de {this.state.coowner.name}</h2>
						<PrintButton
							picto_color={this.props.coownerships.current.syndic}
							print={this.handlePrint}
						/>
					</div>
					<div className="card-body table-wrapper">
						<div className="standfirst">
							{this.state.account ? (
								<h3>Compte {this.state.account}</h3>
							) : null}
							{this.state.exercices.length ? (
								<SelectorItemObject
									id="select-account-exerice"
									label="Période"
									current={this.state.current_exercice}
									options={this.state.exercices}
									callback={this.handleExercice}
								/>
							) : null}
						</div>
						{this.state.is_loading === false ? (
							this.state.transactions.length ? (
								<TableAccount
									cols={[
										'LIBELLÉS',
										'DATE',
										'DÉBIT',
										'CRÉDIT',
										'SOLDE',
									]}
									table={this.state.transactions}
								/>
							) : (
								<p>
									Il n'y a aucune opération sur ce compte à cette
									période.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
	};
};
const mapDispatchToProps = {
	setLog,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoownerAccount));
