//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// components
import {
	SelectorItemObject,
	SelectorItem,
} from '../../../../components/form/item/SelectorItem';
import GraphColumns from '../../../../components/graph/GraphColumns';
import Loading from '../../../../components/Loading';
//action
import { setLog } from '../../../../action_creators/logActionCreators';
import {
	setGlobalExercicesStateAmounts,
	setExercicesStateExercices,
	setExercicesStateCurrentExercice,
	setExercicesStateCommonOperation,
} from '../../../../action_creators/coownershipAmountsActionCreators';
// functions
import { getRequest, responseManagment } from '../../../../functions/ajax';
// const
import { OPERATIONS, OPERATIONS_LABEL } from '../../../../constants/operation';
import { blue, green, red } from '../../../../constants/colors';
import { getActivityParameters } from '../../../../functions/coownerships';

class ExercicesCard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.handleCommonOperation = this.handleCommonOperation.bind(this);
	}

	async UNSAFE_componentWillMount() {
		const exercices_state = this.props.exercices_state;
		const history = exercices_state.history;
		if (
			!exercices_state.exercices.length ||
			// Update transactions if exerice has change in another page
			exercices_state.current_exercice !== history.global.exercice ||
			// Update transactions if operation has change in another page
			exercices_state.common_operation !== history.global.common_operation ||
			// Get transactions with exerice setted from another page
			!Object.keys(exercices_state.global_amounts).length
		) {
			this.getGlobalExericesState();
		}
		// Do nothing if exercices have not change and there are already global amounts
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	componentDidUpdate(prevProps) {
		const ex_state = this.props.exercices_state;
		if (
			prevProps.exercices_state.current_exercice !== ex_state.current_exercice
			|| prevProps.exercices_state.common_operation !== ex_state.common_operation
		) {
			this.getGlobalExericesState();
		}
	}

	// get and update value should be the same do to cross exercices and operation
	async getGlobalExericesState() {
		const ex_state = this.props.exercices_state;
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/etat-exercice-global?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&current-operation=${OPERATIONS[ex_state.common_operation]}`
				+ (ex_state.exercices.length
					? `&start=${ex_state.exercices[ex_state.current_exercice].start}`
					  + `&end=${ex_state.exercices[ex_state.current_exercice].end}`
					: ''),
			this.props.user.token
		);
		if (
			this.responseManagment(response) &&
			// for displayed 'Aucun ... n'est disponible...'
			// better here than in render
			!(
				response.charges === 0
				&& response.produits === 0
				&& !response.exercices.length
			)
		) {
			if (!ex_state.exercices.length) {
				this.props.setExercicesStateExercices(response.exercices);
			}
			this.props.setGlobalExercicesStateAmounts({
				charges: response.charges || null,
				solde: response.solde || null,
				products: response.produits || null,
			});
		} else {
			this.props.setGlobalExercicesStateAmounts({});
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	handleExercice(evt) {
		this.props.setExercicesStateCurrentExercice(evt.target.value);
	}
	handleCommonOperation(evt) {
		this.props.setExercicesStateCommonOperation(evt.target.value);
	}

	render() {
		const ex_state = this.props.exercices_state;
		return (
			<div className={`card unscrollable exercices`}>
				<div className="card-header">
					<h2>État des exercices</h2>
				</div>
				<div
					className={
						'card-body'
						+ (Object.keys(ex_state.global_amounts).length
						&& this.state.is_loading === false
							? ' card-graph'
							: '')
					}
				>
					{this.state.is_loading === false ? (
						Object.keys(ex_state.global_amounts).length ? (
							<Fragment>
								<div className="standfirst">
									{ex_state.exercices.length ? (
										<SelectorItemObject
											id={`select-card-exercices-exerice`}
											label="Période"
											current={ex_state.current_exercice}
											options={ex_state.exercices}
											callback={this.handleExercice}
										/>
									) : null}
									<SelectorItem
										id={`select-card-exercices-operation`}
										label="Opérations"
										current={ex_state.common_operation}
										options={OPERATIONS_LABEL}
										callback={this.handleCommonOperation}
									/>
								</div>
								<GraphColumns
									id="exerciceGraph"
									cols="3"
									colors={[green, red, blue]}
									datas={[
										[
											'Total Produits',
											ex_state.global_amounts.products,
										],
										[
											'Total Charges',
											ex_state.global_amounts.charges,
										],
										['Solde', ex_state.global_amounts.solde],
									]}
								/>
								<div className="btn-wrapper">
									<Link
										className="btn"
										to={`/coownership/${this.props.match.params.id}/coownership/exercices`}
									>
										Accéder
									</Link>
								</div>
							</Fragment>
						) : (
							<p>Aucun exercice n'est disponible pour cette période.</p>
						)
					) : (
						<Loading />
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		exercices_state: state.exercices_state,
	};
};
const mapDispatchToProps = {
	setLog,
	setGlobalExercicesStateAmounts,
	setExercicesStateExercices,
	setExercicesStateCurrentExercice,
	setExercicesStateCommonOperation,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExercicesCard));
