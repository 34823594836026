// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
// component
import CoownershipCouncil from '../coownership/council/CoownershipCouncil';
import JudicialProcedures from '../coownership/council/JudicialProcedures';
import Coowners from '../coownership/council/Coowners';
import CoownerAccount from '../coownership/council/CoownerAccount';
import Balance from '../coownership/council/Balance';
import GreatBook from '../coownership/council/GreatBook';

class CouncilRouter extends Component {
	UNSAFE_componentWillMount() {
		if (!this.props.coownerships.current.coowner.cs) {
			// If user isn't a member of council of this coownership redirect to Dashboard
			this.props.history.push(
				`/coownership/${this.props.match.params.id}/dashboard`
			);
		}
	}

	render() {
		if (this.props.coownerships.current.coowner.cs) {
			return (
				<Switch>
					<Route exact path="/coownership/:id/coownership_council">
						<CoownershipCouncil />
					</Route>
					<Route
						exact
						path="/coownership/:id/coownership_council/judicial_procedures"
					>
						<JudicialProcedures />
					</Route>
					<Route exact path="/coownership/:id/coownership_council/coowners">
						<Coowners />
					</Route>
					<Route
						exact
						path="/coownership/:id/coownership_council/coowners/:page"
					>
						<Coowners />
					</Route>
					<Route
						exact
						path="/coownership/:id/coownership_council/coowner/:coowner_id"
					>
						<CoownerAccount />
					</Route>
					<Route exact path="/coownership/:id/coownership_council/balance">
						<Balance />
					</Route>
					<Route exact path="/coownership/:id/coownership_council/great_book">
						<GreatBook />
					</Route>
					{/* Redirection */}
					<Route path="/coownership/:id/coownership_council">
						<Redirect
							to={`/coownership/${this.props.match.params.id}/coownership_council`}
						/>
					</Route>
				</Switch>
			);
		} else {
			return null;
		}
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
	};
};

export default withRouter(connect(mapStateToProps)(CouncilRouter));
