import * as types from '../action_types/coownershipsActionTypes';

export const setCoownerships = (coownerships) => ({
	type: types.SET_COOWNERSHIPS,
	coownerships
});
export const removeCoownerships = () => ({
	type: types.REMOVE_COOWNERSHIPS
});
export const setCurrentCoownership = (coownership) => ({
	type: types.SET_CURRENT_COOWNERSHIP,
	coownership
});

export const removeCurrentCoownership = () => ({
	type: types.REMOVE_CURRENT_COOWNERSHIP
});