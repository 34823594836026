import {
    SET_BALANCE_TRANSACTIONS,
    SET_BALANCE_EXERCICES,
    SET_BALANCE_CURRENT_EXERCICE,
    REMOVE_BALANCE
} from '../action_types/councilActionTypes';

const initialState = {
    transactions: [],
    exercices: [],
    current_exercice: 0,
}

function balanceReducer(state = initialState, action) {
    let nextState;

    switch (action.type) {

        case SET_BALANCE_TRANSACTIONS:
            nextState = {
                ...state,
                transactions: action.transactions
            };
            return nextState || state;

        case SET_BALANCE_EXERCICES:
            nextState = {
                ...state,
                exercices: action.exercices
            };
            return nextState || state;

        case SET_BALANCE_CURRENT_EXERCICE:
            nextState = {
                ...state,
                current_exercice: action.exercice
            };
            return nextState || state;

        case REMOVE_BALANCE:
            return initialState;

        default:
            return state;
    }
}

export default balanceReducer;