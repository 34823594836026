//module
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeClassName } from '../../functions';

const CardButton = props => (
	<div className="card card-btn">
		<div className="card-header">
			<h2>
				<Link to={props.url} className="extended-link">
					{props.title}
				</Link>
			</h2>
		</div>
		<div className="card-body">
			<div className="btn-wrapper">
				<Link
					className={makeClassName('btn btn-large', props.btn_class)}
					to={props.url}
				>
					{props.btn_label}
				</Link>
			</div>
		</div>
	</div>
);

CardButton.propTypes = {
	title: PropTypes.string.isRequired,
	btn_label: PropTypes.string.isRequired,
	btn_class: PropTypes.string,
	url: PropTypes.string.isRequired,
};

export default CardButton;
