import * as types from '../action_types/accountActionTypes';

// ACCOUNT
export const removeAccount = () => ({
	type: types.REMOVE_ACCOUNT,
});
export const setGlobalAccountAmounts = amounts => ({
	type: types.SET_GLOBAL_ACCOUNT_AMOUNTS,
	amounts,
});
export const setAccountExercices = exercices => ({
	type: types.SET_ACCOUNT_EXERCICES,
	exercices,
});
export const setAccountCurrentExercice = exercice => ({
	type: types.SET_ACCOUNT_CURRENT_EXERCICE,
	exercice,
});
export const setAccountTransactions = transactions => ({
	type: types.SET_ACCOUNT_TRANSACTIONS,
	transactions,
});
export const setAccountNumber = number => ({
	type: types.SET_ACCOUNT_NUMBER,
	number,
});
// ADVANCES
export const setAdvances = advances => ({
	type: types.SET_ADVANCES,
	advances,
});
// REMINDERS
export const setReminders = reminders => ({
	type: types.SET_REMINDERS,
	reminders,
});
export const setShortReminders = reminders => ({
	type: types.SET_SHORT_REMINDERS,
	reminders,
});
export const setRemindersExercices = exercices => ({
	type: types.SET_REMINDERS_EXERCICES,
	exercices,
});
export const setRemindersCurrentExercice = exercice => ({
	type: types.SET_REMINDERS_CURRENT_EXERCICE,
	exercice,
});
export const removeReminders = () => ({
	type: types.REMOVE_REMINDERS,
});
// CAPITAL CALLS
export const setCapitalCalls = capital_calls => ({
	type: types.SET_CAPITAL_CALLS,
	capital_calls,
});
export const setCapitalCallsExercices = exercices => ({
	type: types.SET_CAPITAL_CALLS_EXERCICES,
	exercices,
});
export const setCapitalCallsCurrentExercice = exercice => ({
	type: types.SET_CAPITAL_CALLS_CURRENT_EXERCICE,
	exercice,
});
export const removeCapitalCalls = () => ({
	type: types.REMOVE_CAPITAL_CALLS,
});
// LAND_DECLARATION
export const setLandDeclarations = (land_declarations, current_page) => ({
	type: types.SET_LAND_DECLARATIONS,
	land_declarations,
	current_page,
});
export const setNbPagesLandDeclarations = nb => ({
	type: types.SET_NB_PAGES_LAND_DECLARATIONS,
	nb,
});
export const removeLandDeclarations = () => ({
	type: types.REMOVE_LAND_DECLARATIONS,
});
// COUNTER
export const setCounters = (counters, current_page) => ({
	type: types.SET_COUNTERS,
	counters,
	current_page,
});
export const setNbCounters = nb => ({
	type: types.SET_NB_PAGES_COUNTERS,
	nb,
});
export const removeCounters = () => ({
	type: types.REMOVE_COUNTERS,
});
// DEBIT_MANDATE
export const setDebitMandates = (debit_mandates, current_page) => ({
	type: types.SET_DEBIT_MANDATES,
	debit_mandates,
	current_page,
});
export const setNbPagesDebitMandates = nb => ({
	type: types.SET_NB_PAGES_DEBIT_MANDATES,
	nb,
});
export const removeDebitMandates = () => ({
	type: types.REMOVE_DEBIT_MANDATES,
});
