import * as types from '../action_types/docsActionTypes';

export const removeDocs = () => ({
	type: types.REMOVE_DOCS
});
// DATA DOCS
export const setDataDocs = (docs) => ({
	type: types.SET_DATA_DOCS,
	docs
});
export const addDataDoc = (doc) => ({
	type: types.ADD_DATA_DOC,
	doc
});
// JUDICIAL DOCS
export const setJudicialsDocs = (docs) => ({
	type: types.SET_JUDICIALS_DOCS,
	docs
});
export const setJudicialsDates = (dates) => ({
	type: types.SET_JUDICIALS_DATES,
	dates
});
export const setJudicialsCurrentDate = (date) => ({
	type: types.SET_JUDICIALS_CURRENT_DATE,
	date
});
// MY FILES
export const setFolder = (folders, files) => ({
	type: types.SET_FOLDER,
	folders,
	files
});