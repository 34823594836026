// module
import React, { Component, Fragment } from 'react';
// components
import PropTypes from 'prop-types';
// function
import { formatNumbers } from '../../functions';
// const
import { API_PDF_SRC_URL } from '../../constants/api';

class TableSyndic extends Component {
    render() {
        return (
            <table className={`table table-syndic ${this.props.className}`}>
                <tbody>
                    <tr>
                        <th>nom</th>
                        <td>{this.props.table.name}</td>
                    </tr>
                    <tr>
                        <th>adresse</th>
                        <td>
                            {this.props.table.address
                                ? this.props.table.address.full
                                : null}
                        </td>
                    </tr>
                    <tr>
                        <th>contact</th>
                        <td>{this.props.table.email}</td>
                    </tr>
                    <tr>
                        <th>téléphone</th>
                        <td>{this.props.table.phone}</td>
                    </tr>
                    <tr>
                        <th>mandat</th>
                        <td>{this.props.table.start}</td>
                    </tr>
                    {this.props.table.type === 'pro' && (
                        <Fragment>
                            <tr>
                                <th>Société</th>
                                <td>
                                    {this.props.table.siret
                                        ? `Numéro de Siret\u00A0: ${this.props.table.siret} | `
                                        : null}
                                    {this.props.table.ape
                                        ? `Code APE\u00A0: ${this.props.table.ape} | `
                                        : null}
                                    {this.props.table.rcs
                                        ? `Ville d'immatriculation\u00A0: ${this.props.table.rcs} | `
                                        : null}
                                    {this.props.table.capital
                                        ? `Capital\u00A0: ${formatNumbers(
                                            this.props.table.capital
                                        )}\u00A0€`
                                        : null}
                                </td>
                            </tr>
                            <tr>
                                <th>Carte S</th>
                                <td>{this.props.table.carteS}</td>
                            </tr>
                            <tr>
                                <th>Garant</th>
                                <td>
                                    {this.props.table.garantFinancier
                                        ? `${this.props.table.garantFinancier.description} | `
                                        + `Montant Maximal\u00A0: ${formatNumbers(
                                            this.props.table.garantFinancier.amount
                                        )}\u00A0€`
                                        : null}
                                </td>
                            </tr>
                        </Fragment>
                    )}
                    {this.props.table.files
                        ? this.props.table.files.map((file, i) => (
                              <tr key={i}>
                                  {i === 0 && (
                                      <th rowSpan={this.props.table.files.length}>
                                          documents
                                      </th>
                                  )}
                                  <td>
                                      <a href={API_PDF_SRC_URL + file.url}>
                                          {file.libelle}
                                      </a>
                                  </td>
                              </tr>
                          ))
                        : null}
                </tbody>
            </table>
        );
    }
}

TableSyndic.propTypes = {
    table: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default TableSyndic;
