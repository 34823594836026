// module
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DateFieldset extends Component {
	constructor(props) {
		super(props);

		this.state = {
			day: '',
			month: '',
			year: '',
		};
		this.initialState = { ...this.state };

		this.handleDay = this.handleDay.bind(this);
		this.handleMonth = this.handleMonth.bind(this);
		this.handleYear = this.handleYear.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevState.day !== this.state.day
			|| prevState.month !== this.state.month
			|| prevState.year !== this.state.year
		) {
			this.props.callback({ ...this.state });
		}

		if (this.props.shouldReset === true) {
			this.setState({ ...this.initialState });
			this.props.hasReset();
		}
	}

	handleDay(evt) {
		const { value } = evt.target;
		if (!isNaN(value) && value <= 31 && value >= 0 && value.length <= 2) {
			this.setState({ day: value });
			if (value.length === 2) {
				document.getElementById(this.props.month_id).focus();
			}
		}
	}

	handleMonth(evt) {
		const { value } = evt.target;
		if (!isNaN(value) && value <= 12 && value >= 0 && value.length <= 2) {
			this.setState({ month: value });
			if (value.length === 2) {
				document.getElementById(this.props.year_id).focus();
			}
		}
	}

	handleYear(evt) {
		const { value } = evt.target;
		if (!isNaN(value) && value >= 0 && value.length <= 4) {
			this.setState({ year: value });
		}
	}
	render() {
		const { day, month, year } = this.state;
		const { label, required, day_id, month_id, year_id } = this.props;
		return (
			<fieldset>
				<span className="fake-label">
					{label}
					{required ? '\u00A0*' : ''}
				</span>
				<div className="d-inline-block">
					<label className="sr-only" htmlFor={day_id}>
						Jour
					</label>
					<input
						type="number"
						id={day_id}
						name={day_id}
						className="day"
						placeholder="00"
						value={day}
						onChange={this.handleDay}
						required={required}
					/>
					<label className="sr-only" htmlFor={month_id}>
						Mois
					</label>
					<input
						type="number"
						id={month_id}
						name={month_id}
						className="month"
						placeholder="00"
						value={month}
						onChange={this.handleMonth}
						required={required}
					/>
					<label className="sr-only" htmlFor={year_id}>
						Année
					</label>
					<input
						type="number"
						id={year_id}
						name={year_id}
						className="year"
						placeholder="0000"
						value={year}
						onChange={this.handleYear}
						required={required}
					/>
				</div>
			</fieldset>
		);
	}
}

DateFieldset.propTypes = {
	day_id: PropTypes.string.isRequired,
	month_id: PropTypes.string.isRequired,
	year_id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool,
	callback: PropTypes.func.isRequired,
	shouldReset: PropTypes.bool,
	hasReset: PropTypes.func,
};

DateFieldset.defaultProps = {
	required: false,
};

export default DateFieldset;
