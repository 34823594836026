// module
import React from 'react';
import { Link } from 'react-router-dom';
import { makeClassName } from '../functions';

const Coownership = ({ coownership }) => (
	<div className={makeClassName('coownership-teaser', !coownership.syndic && 'grey')}>
		<h2>
			<Link
				to={`/coownership/${coownership.coowner.id}/dashboard`}
				className="extended-link"
			>
				{coownership.name}
			</Link>
		</h2>
		<div>{coownership.coowner.name}</div>
		<div className="coownership_nb_lot">
			<p>Nombre de lots détenus</p>
			<span>{coownership.nb_lots}</span>
		</div>
		<button className="btn">Entrer</button>
	</div>
);

export default Coownership;
