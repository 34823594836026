// module
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
// components
import { TableBase, colorOddRows } from './Table';
import TdAmount, { TdAmountAnimated } from './TdAmount';
// const
import { API_PDF_SRC_URL } from '../../constants/api';
import FileDownloadedFromAPI from '../FileDownloadedFromAPI';
import { getActivityParameters } from '../../functions/coownerships';
import { chooseColor } from '../../functions';

const TdAmountsGroup = props => (
	<Fragment>
		<TdAmount value={props.value.copropriete.amount} negative={props.negative} />
		<TdAmount value={props.value.copropriete.tva} negative={props.negative} />
		<TdAmount value={props.value.coproprietaire.amount} negative={props.negative} />
		<TdAmount value={props.value.coproprietaire.tva} negative={props.negative} />
	</Fragment>
);

const TrTotal = props => (
	<tr className="total">
		<td className={`td-align-right${props.negative ? ' negative' : ''}`}>
			{props.label}
		</td>
		<TdAmountsGroup value={props.value} negative={props.negative} />
		<td></td>
	</tr>
);

class SubTableExerciceState extends Component {
	render() {
		return Object.values(this.props.table).map((key, i) => (
			<Fragment key={i}>
				<tr className="subtitle">
					<td colSpan={this.props.cols_nb}>
						Clé de répartition «&nbsp;{key.label}&nbsp;»
					</td>
				</tr>
				{Object.values(key.accounts).map((account, j) => (
					<Fragment key={j}>
						<tr className="subsubtotal">
							<td className="td-label">
								{account.number} -{account.label}
							</td>
							<TdAmountsGroup
								value={account}
								negative={this.props.negative}
							/>
							<td></td>
						</tr>
						{Object.values(account.ecritures).map((ecriture, k) => (
							<tr
								key={k}
								className={
									'hideable'
									+ (colorOddRows(k) ? ` ${colorOddRows(k)}` : '')
								}
							>
								<td className="td-label">
									<div className="animated-cell">
										{ecriture.libelle}
										<br />
										Date d'écriture&nbsp;: {ecriture.date} Numéro de
										pièce {ecriture.numero}
									</div>
								</td>
								<TdAmountAnimated
									value={ecriture.copropriete.amount}
									negative={this.props.negative}
								/>
								<TdAmountAnimated
									value={ecriture.copropriete.tva}
									negative={this.props.negative}
								/>
								<TdAmountAnimated
									value={ecriture.coproprietaire.amount}
									negative={this.props.negative}
								/>
								<TdAmountAnimated
									value={ecriture.coproprietaire.tva}
									negative={this.props.negative}
								/>
								<td>
									<div className="animated-cell">
										{ecriture.files.length
											? ecriture.files.map((file, i) => (
													<a
														key={i}
														href={API_PDF_SRC_URL + file.url}
														className="table-picto"
														title="Télécharger la pièce jointe à la charge"
													>
														{file.label}
													</a>
											  ))
											: null}
										{ecriture.chargeHonoraireId ? (
											<FileDownloadedFromAPI
												uri={
													`/charge-honoraires-pdf?`
													+ getActivityParameters(
														this.props.activity
													)
													+ `&id=${ecriture.chargeHonoraireId}`
												}
												filename={`charge-honoraire-${ecriture.date}-${ecriture.chargeHonoraireId}`}
											>
												<img
													src={`/images/pictogrammes/invoice-${chooseColor(
														this.props.picto_color
													)}.svg`}
													alt="pièce jointe"
													title="Télécharger la charge honoraire"
													className="table-picto"
												/>
											</FileDownloadedFromAPI>
										) : null}
									</div>
								</td>
							</tr>
						))}
					</Fragment>
				))}
				<tr className="subtotal">
					<td className="td-align-right">
						Total clé de répartition{' '}
						<span className="general-key">«&nbsp;{key.label}&nbsp;»</span>
					</td>
					<TdAmountsGroup value={key} negative={this.props.negative} />
					<td></td>
				</tr>
			</Fragment>
		));
	}
}

class TableExerciceState extends Component {
	render() {
		return (
			<TableBase
				className={
					'table-exercice-state' + (this.props.hide_details ? ' hidden' : '')
				}
				cols={this.props.cols}
			>
				<tbody>
					<tr className="title">
						<td colSpan={this.props.cols.length} className="negative">
							CHARGES
						</td>
					</tr>
					<SubTableExerciceState
						table={this.props.table.charges.keys}
						cols_nb={this.props.cols.length}
						negative={true}
						activity={this.props.activity}
					/>
					<TrTotal
						label="Total Charges"
						value={this.props.table.charges}
						negative={true}
					/>
				</tbody>
				<tbody>
					<tr className="title">
						<td colSpan={this.props.cols.length}>PRODUITS</td>
					</tr>
					<SubTableExerciceState
						table={this.props.table.produits.keys}
						cols_nb={this.props.cols.length}
						activity={this.props.activity}
					/>
					<TrTotal label="Total Produits" value={this.props.table.produits} />
					<TrTotal
						label="Total Charges"
						value={this.props.table.charges}
						negative={true}
					/>
					<TrTotal label="Solde" value={this.props.table.solde} />
				</tbody>
			</TableBase>
		);
	}
}

TableExerciceState.propTypes = {
	// ...Table.propTypes,
	table: PropTypes.object.isRequired,
	hide_details: PropTypes.bool,
	activity: PropTypes.object.isRequired,
};

export default TableExerciceState;
