//module
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import Modal from '../../../../components/Modal';
import GraphCircle from '../../../../components/graph/GraphCircle';
import GraphDoubleRows from '../../../../components/graph/GraphDoubleRows';
import Loading from '../../../../components/Loading';
//action
import { setLog } from '../../../../action_creators/logActionCreators';
import { setBudget } from '../../../../action_creators/coownershipAmountsActionCreators';
// functions
import { formatNumbers } from '../../../../functions';
import { getRequest, responseManagment } from '../../../../functions/ajax';
// constants
import { green, light_grey } from '../../../../constants/colors';
import { getActivityParameters } from '../../../../functions/coownerships';

class BudgetCard extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			display_budget_modal: false,
			is_loading: false,
		};

		this.responseManagment = responseManagment.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
	}

	async UNSAFE_componentWillMount() {
		if (!Object.keys(this.props.budget).length) {
			this.getBudget();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getBudget() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/budget?` + getActivityParameters(this.props.coownerships.current),
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setBudget({
				voted_budget: response.budgetVote,
				used_budget: response.chargesConstatees,
				details: response.budgetRubrique,
				start: response.start,
				end: response.end,
			});
		}
		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	toggleModal(is_visible) {
		this.setState({ display_budget_modal: is_visible });
	}

	render() {
		const { budget, coownerships } = this.props;
		return (
			<div className="card unscrollable budget-card">
				<div className="card-header composed-card-header">
					<h2>Budget</h2>
					<em>Charges générales uniquement</em>
				</div>
				<div className="card-body">
					{this.state.is_loading === false ? (
						Object.keys(budget).length
						&& (budget.voted_budget > 0
							|| (budget.details && budget.details.length)) ? (
							<Fragment>
								{budget.voted_budget > 0 ? (
									<Fragment>
										<div className="standfirst">
											<p>
												<strong>
													Du {budget.start} au {budget.end}
												</strong>
											</p>
										</div>
										{/* react-progressbar.js is bad so we use the pure js plugin with our own component */}
										<GraphCircle
											id="budgetGraph"
											ratio={
												budget.used_budget / budget.voted_budget
											}
											trail_color={light_grey}
											stroke_color={green}
										/>
										<div className="caption">
											<span>
												{formatNumbers(budget.used_budget)}
												&nbsp;
											</span>
											<span>
												/&nbsp;
												{formatNumbers(budget.voted_budget)}
												&nbsp;€
											</span>
										</div>
									</Fragment>
								) : (
									<p>Budget global indisponible.</p>
								)}
								<div className="btn-wrapper">
									<button
										className="btn"
										onClick={e => this.toggleModal(true)}
									>
										Accéder
									</button>
								</div>
							</Fragment>
						) : (
							<p>
								Budget indisponible. Veuillez contacter votre
								gestionnaire.
							</p>
						)
					) : (
						<Loading />
					)}
				</div>
				{/* Modal */}
				<Modal
					id="budget-modal"
					is_visible={this.state.display_budget_modal}
					callback={this.toggleModal}
					picto_color={coownerships.current.syndic}
				>
					<h2>Détails de la répartition du budget</h2>
					{this.state.is_loading === false ? (
						budget.details && budget.details.length ? (
							<GraphDoubleRows
								id="budgetDetailedGraph"
								colors={[green, light_grey]}
								x={budget.details.map(detail => detail.label)}
								first_row={budget.details.map(({ vote, depense }) => ({
									y: depense,
									percentage: vote
										? Math.round((depense / vote) * 100)
										: null,
								}))}
								second_row={budget.details.map(detail => detail.vote)}
							/>
						) : (
							<p>Aucun budget disponible.</p>
						)
					) : (
						<Loading />
					)}
					<p className="modal-caption">
						Pour chaque poste est indiqué en gris le budget total alloué et en
						vert le budget dépensé à la date du jour.
					</p>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = ({ coownerships, user, budget }) => ({
	coownerships,
	user,
	budget,
});
const mapDispatchToProps = {
	setLog,
	setBudget,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BudgetCard));
