//module
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
// functions
import { formatNumbers } from '../../functions';
// constants
import { grey, black, white } from '../../constants/colors';

class GraphDoubleColumns extends Component {
	constructor(props) {
		super(props);

		this.handleLabelPosition = this.handleLabelPosition.bind(this);
	}

	componentDidMount() {
		Highcharts.chart(this.props.id, {
			chart: {
				type: 'column',
				height: 400,
				scrollablePlotArea: {
					minWidth: this.props.series.length
						? this.props.series[0].data.length * 260
						: 260,
					scrollPositionX: 1,
				},
				events: {
					render: this.handleLabelPosition,
				},
			},
			title: {
				text: null,
			},
			plotOptions: {
				column: {
					borderRadius: 10,
					shadow: false,
					groupPadding: 0.04,
					pointPadding: 0.02,
					minPointLength: 25,
					maxPointWidth: 150,
				},
				series: {
					borderWidth: 0,
					dataLabels: {
						enabled: true,
						inside: true,
						overflow: 'allow',
						color: white,
						crop: false,
						allowOverlap: true,
						style: {
							fontSize: '15px',
							textOutline: null,
							fontWeight: 500,
						},
						formatter: function () {
							return `${formatNumbers(this.y)}\u00A0€`;
						},
						useHTML: true,
					},
					enableMouseTracking: false,
				},
			},
			series: this.props.series,
			colors: this.props.colors,
			legend: {
				itemStyle: {
					fontSize: '18px',
					fontWeight: 400,
					color: grey,
				},
				itemMarginBottom: 10,
				itemMarginTop: 10,
				itemDistance: 30,
				itemHoverStyle: { color: black },
				symbolPadding: 10,
			},
			xAxis: {
				type: 'category',
				categories: this.props.x,
				title: {
					enabled: false,
				},
				labels: {
					style: {
						fontSize: '16px',
					},
				},
				tickWidth: 1,
				tickLength: 25,
			},
			yAxis: {
				lineWidth: 0,
				gridLineWidth: 0,
				lineColor: 'transparent',
				minorTickLength: 0,
				tickLength: 0,
				title: {
					enabled: false,
				},
				labels: {
					enabled: false,
				},
			},
			credits: {
				enabled: false,
			},
		});
	}

	handleLabelPosition(evt) {
		let labels = document.querySelectorAll(
			`#${this.props.id} div.highcharts-data-label`
		);
		Object.values(labels).forEach(label => {
			if (parseInt(label.style.top) > 260) {
				label.style.top = '260px';
			}
		});
	}

	render() {
		return (
			<div
				id={this.props.id}
				className={`graph graph-double-columns graph-${this.props.cols}-col`}
			></div>
		);
	}
}

GraphDoubleColumns.propTypes = {
	id: PropTypes.string.isRequired,
	cols: PropTypes.any.isRequired,
	colors: PropTypes.arrayOf(PropTypes.string).isRequired,
	x: PropTypes.array.isRequired,
	series: PropTypes.array.isRequired,
};

export default GraphDoubleColumns;
