//module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';

class Header extends Component {
	render() {
		let header_top_left_item;
		let user_menu_hover;
		let contact_link;

		if (Object.keys(this.props.coownerships.current).length) {
			header_top_left_item = (
				<h1 className="coownership-link">
					<NavLink exact to="/">
						{this.props.coownerships.current.name} - {this.props.coownerships.current.coowner.name}
					</NavLink>
				</h1>
			);

			user_menu_hover = (
				<div className="user-menu-hover">
					<nav>
						<ul>
							<li>
								<NavLink exact to="/">
									Mes copropriétés
								</NavLink>
							</li>
							<li>
								<NavLink to="/info">Mes informations</NavLink>
							</li>
							<li>
								<Link to="/logout">Déconnexion</Link>
							</li>
						</ul>
					</nav>
				</div>
			);

			if (this.props.coownerships.current.syndic) {
				contact_link = (
					<NavLink
						exact
						to={`/coownership/${this.props.coownerships.current.coowner.id}/contact`}
					>
						Contact
					</NavLink>
				);
			}
		} else {
			header_top_left_item = (
				<p className="header-text">
					<span>Votre accès est aussi disponible sur le </span>
					<a
						href="https://play.google.com/store/apps/details?id=com.espacecoproprietaire&hl=fr"
						target="_blank"
						rel="nofollow noopener noreferrer"
					>
						Play Store
					</a>
				</p>
			);

			user_menu_hover = (
				<div className="user-menu-hover">
					<Link to="/logout">Déconnexion</Link>
				</div>
			);
		}

		return (
			<header>
				{header_top_left_item}
				<div className="menu-top">
					{contact_link}
					<div className="user-menu">
						{Object.keys(this.props.user).length ? (
							<span className="user-menu-title">
								{this.props.user.first_name
									? `${this.props.user.first_name} ${this.props.user.name[0]}.`
									: this.props.user.name}
							</span>
						) : null}
						{user_menu_hover}
					</div>
				</div>
			</header>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		coownerships: state.coownerships,
	};
};

export default withRouter(connect(mapStateToProps)(Header));
