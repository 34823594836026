import {
	SET_GLOBAL_EXERCICES_STATE_AMOUNTS,
	SET_EXERCICES_STATE_TRANSACTIONS,
	SET_EXERCICES_STATE_EXERCICES,
	SET_EXERCICES_STATE_CURRENT_EXERCICE,
	SET_EXERCICES_STATE_COMMON_OPERATION,
	REMOVE_EXERCICES_STATE
} from "../action_types/coownershipAmountsActionTypes";

const initialState = {
	global_amounts: {},
	transactions: {},
	exercices: [],
	current_exercice: 0,
	common_operation: 0,
	history: {
		global: {
			exercice: 0,
			common_operation: 0
		},
		transactions: {
			exercice: 0,
			common_operation: 0
		}
	}
};

function exercicesReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case SET_GLOBAL_EXERCICES_STATE_AMOUNTS:
			nextState = {
				...state,
				global_amounts: action.amounts,
				history: {
					...state.history,
					global: {
						...state.global,
						common_operation: state.common_operation,
						exercice: state.current_exercice
					}
				}
			};
			return nextState || state;

		case SET_EXERCICES_STATE_TRANSACTIONS:
			nextState = {
				...state,
				transactions: action.transactions,
				history: {
					...state.history,
					transactions: {
						...state.global,
						common_operation: state.common_operation,
						exercice: state.current_exercice
					}
				}
			};
			return nextState || state;

		case SET_EXERCICES_STATE_EXERCICES:
			nextState = {
				...state,
				exercices: action.exercices
			};
			return nextState || state;

		case SET_EXERCICES_STATE_CURRENT_EXERCICE:
			nextState = {
				...state,
				current_exercice: action.exercice
			};
			return nextState || state;

		case SET_EXERCICES_STATE_COMMON_OPERATION:
			nextState = {
				...state,
				common_operation: action.common_operation
			};
			return nextState || state;

		case REMOVE_EXERCICES_STATE:
			return initialState;

		default:
			return state;
	}
}

export default exercicesReducer;
