import {SET_BUDGET, REMOVE_BUDGET} from '../action_types/coownershipAmountsActionTypes';

function budgetReducer(state = {}, action) {
    let nextState;

    switch (action.type) {

        case SET_BUDGET:
            nextState = action.budget;
            return nextState || state;

        case REMOVE_BUDGET:
            return {};

        default:
            return state;
    }
}

export default budgetReducer;