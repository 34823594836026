// module
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// components
import { SelectorItemObject } from '../../../components/form/item/SelectorItem';
import TableWorks from '../../../components/table/TableWorks';
import Loading from '../../../components/Loading';
import ToggleButtons from '../../../components/button/ToggleButtons';
// action
import { setLog } from '../../../action_creators/logActionCreators';
import {
	setWorks,
	setWorkSteps,
	setWorksExercices,
	setWorksCurrentExercice,
} from '../../../action_creators/coownershipAmountsActionCreators';
// functions
import { getRequest, responseManagment } from '../../../functions/ajax';
import { getActivityParameters } from '../../../functions/coownerships';

class Works extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			is_loading: false,
			in_progress: true,
		};

		this.responseManagment = responseManagment.bind(this);
		this.toggleInProgress = this.toggleInProgress.bind(this);
		this.handleExercice = this.handleExercice.bind(this);
		this.getSteps = this.getSteps.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (!Object.keys(this.props.works.list[this.state.in_progress]).length) {
			this.getWorks();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			// progress change
			prevState.in_progress !== this.state.in_progress &&
			// in progress and void
			this.state.in_progress === true
			&& !Object.keys(this.props.works.list[this.state.in_progress]).length
		) {
			this.getWorks();
		}
		if (
			// exercice change
			prevProps.works.current_exercice !== this.props.works.current_exercice ||
			// progress change
			(prevState.in_progress !== this.state.in_progress &&
				// not in progress and void
				!this.state.in_progress
				&& !Object.keys(this.props.works.list[this.state.in_progress]).length)
		) {
			this.updateWorks();
		}
	}
	componentDidMount() {
		this._isMounted = true;
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	async getWorks() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/travaux-detail?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&in_progress=${this.state.in_progress}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setWorksExercices(response.exercices);
			this.props.setWorks(response.travaux, this.state.in_progress);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async updateWorks() {
		this.setState({ is_loading: true });
		const response = await getRequest(
			`/travaux-detail?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&start=${
					this.props.works.exercices[this.props.works.current_exercice].start
				}`
				+ `&end=${
					this.props.works.exercices[this.props.works.current_exercice].end
				}`
				+ `&in_progress=${this.state.in_progress}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setWorks(response.travaux, this.state.in_progress);
		} else {
			this.props.setWorks({}, this.state.in_progress);
		}

		if (this._isMounted) {
			this.setState({ is_loading: false });
		}
	}

	async getSteps(id) {
		const response = await getRequest(
			`/travaux-etapes?`
				+ getActivityParameters(this.props.coownerships.current)
				+ `&travaux=${id}`,
			this.props.user.token
		);
		if (this.responseManagment(response)) {
			this.props.setWorkSteps(response.etapes, id, this.state.in_progress);
		}
	}

	goBack(evt) {
		this.props.history.push(
			`/coownership/${this.props.match.params.id}/coownership/`
		);
	}

	toggleInProgress(in_progress) {
		if (in_progress !== this.state.in_progress) {
			this.setState({ in_progress });
		}
	}

	handleExercice(evt) {
		this.props.setWorksCurrentExercice(evt.target.value);
	}

	render() {
		return (
			<div className="grid grid-1">
				<div className="card">
					<div className="card-header card-header-with-back">
						<button className="back-btn" onClick={this.goBack}>
							Revenir en arrière
						</button>
						<h2>Travaux</h2>
					</div>
					<div className="card-body table-wrapper">
						<div className="standfirst toggle-plus-select">
							<ToggleButtons
								value={this.state.in_progress}
								toggle={this.toggleInProgress}
								label_true="En cours"
								label_false="Cloturés"
							/>
							{this.props.works.exercices.length
							&& this.state.in_progress === false ? (
								<SelectorItemObject
									id="select-works-exerice"
									label="Période"
									current={this.props.works.current_exercice}
									options={this.props.works.exercices}
									callback={this.handleExercice}
								/>
							) : null}
						</div>
						{this.state.is_loading === false ? (
							Object.keys(this.props.works.list[this.state.in_progress])
								.length ? (
								<TableWorks
									cols={[
										'LIBELLÉ',
										'CLÉ',
										'STATUT',
										'DATE DE DÉBUT',
										'DOCS/OPTIONS',
									]}
									table={this.props.works.list[this.state.in_progress]}
									picto_color={this.props.coownerships.current.syndic}
									getSteps={this.getSteps}
									exercice={this.props.works.current_exercice}
								/>
							) : (
								<p>
									Il n'y a pas de travaux{' '}
									{this.state.in_progress
										? 'en cours'
										: ' à cette période'}{' '}
									sur cette copropriété.
								</p>
							)
						) : (
							<Loading />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		coownerships: state.coownerships,
		user: state.user,
		works: state.works,
	};
};
const mapDispatchToProps = {
	setLog,
	setWorks,
	setWorkSteps,
	setWorksExercices,
	setWorksCurrentExercice,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Works));
