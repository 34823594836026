// module
import React, { Component } from 'react';
// components
import { TableBase, colorEvenRows } from './Table';
import TdAmount from './TdAmount';

class TableBank extends Component {
    render() {
        let first_transaction = this.props.table[this.props.table.length - 1];
        let start_solde =
            first_transaction.solde
            - first_transaction.debit
            + first_transaction.credit;

        return (
            <TableBase className="table-bank" cols={this.props.cols}>
                <tbody>
                    <tr className="solde">
                        <td colSpan={this.props.cols.length - 1}>
                            Solde actuel
                        </td>
                        <TdAmount value={this.props.solde} />
                    </tr>
                    {this.props.table.map((el, i) => (
                        <tr key={i} className={colorEvenRows(i)}>
                            <td>{el.date}</td>
                            <td className="td-label">{el.label}</td>
                            <TdAmount value={el.debit} />
                            <TdAmount value={el.credit} negative={true} />
                            <TdAmount value={el.solde} />
                        </tr>
                    ))}
                    <tr className="solde">
                        <td colSpan={this.props.cols.length - 1}>
                            Solde de début de période
                        </td>
                        <TdAmount value={start_solde} />
                    </tr>
                </tbody >
            </TableBase>
        );
    }
}

export default TableBank;