// module
import React, { Component } from 'react';
// components
import { TableBase } from './Table';
// functions
import {
	setAnimatedCellsHeight,
	toggleAnimatedCells,
} from '../../functions/animatedCells';

class TableProcedures extends Component {
	constructor(props) {
		super(props);

		this.handleDeploy = this.handleDeploy.bind(this);
	}

	handleDeploy(evt) {
		setAnimatedCellsHeight();
		toggleAnimatedCells(evt.currentTarget.id);
	}

	render() {
		return (
			<TableBase className="table-judicial-procedures" cols={this.props.cols}>
				{this.props.table.map((el, i) => (
					<tbody key={i} className="table-hidden-rows">
						<tr
							id={`tbody-${i}`}
							className="tr-clickable"
							onClick={this.handleDeploy}
						>
							<td className="date">{el.date}</td>
							<td>{el.libelle}</td>
							<td>{el.coproprietaire}</td>
							<td>
								<img
									src="/images/pictogrammes/lens-grey.svg"
									alt="bouton détails"
									className={`table-picto tbody-${i}`}
								/>
							</td>
						</tr>
						{el.etapes.map((etape, j) => (
							<tr key={j} className={`hidden hideable tbody-${i}`}>
								<td>
									<div className="animated-cell">{etape.date}</div>
								</td>
								<td colSpan={3} className="td-label">
									<div className="animated-cell">
										{etape.description}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				))}
			</TableBase>
		);
	}
}

export default TableProcedures;
