// module
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// components
import { TableBase, colorEvenRows } from './Table';
import TdAmount from './TdAmount';

class TableCoowners extends Component {
	render() {
		return (
			<TableBase className="table-coowners" cols={this.props.cols}>
				<tbody>
					{this.props.table.map((coowner, i) => (
						<tr key={i} className={colorEvenRows(i)}>
							<td>{coowner.name}</td>
							<TdAmount value={coowner.solde} />
							<td>
								<Link
									to={this.props.url_access + coowner.id}
									className="table-picto"
								>
									Accès au compte du copropriétaire
								</Link>
							</td>
							<td className="address">{coowner.address.full}</td>
							<td>
								{coowner.cs === true ? (
									<span className="highlight">Oui</span>
								) : (
									'Non'
								)}
							</td>
							<td className="td-label lots">
								{coowner.lots.map((lot, i) =>
									i < coowner.lots.length - 1
										? `${lot.label}, `
										: lot.label
								)}
							</td>
						</tr>
					))}
				</tbody>
			</TableBase>
		);
	}
}

export default TableCoowners;
