import {SET_KEYS, REMOVE_KEYS} from '../action_types/keysActionTypes';

const initialState = {
    list: [],
    saved: false,
};

function keysReducer(state = initialState, action) {
    let nextState;

    switch (action.type) {
        case SET_KEYS:
            nextState = {
                ...state,
                list: action.keys,
                saved: action.saved
            };
            return nextState || state;

        case REMOVE_KEYS:
            return initialState;

        default:
            return state;
    }
}

export default keysReducer;