import * as types from '../action_types/councilActionTypes';

export const removeCouncil = () => ({
	type: types.REMOVE_COUNCIL
});
export const setCouncilMembers = (members) => ({
	type: types.SET_COUNCIL_MEMBERS,
	members
});
// BALANCE
export const setBalanceTransactions = (transactions) => ({
	type: types.SET_BALANCE_TRANSACTIONS,
	transactions
});
export const setBalanceExercices = (exercices) => ({
	type: types.SET_BALANCE_EXERCICES,
	exercices
});
export const setBalanceCurrentExercice = (exercice) => ({
	type: types.SET_BALANCE_CURRENT_EXERCICE,
	exercice
});
export const removeBalance = () => ({
	type: types.REMOVE_BALANCE
});
// GREATBOOK
export const setGreatBookTransactions = (transactions) => ({
	type: types.SET_GREAT_BOOK_TRANSACTIONS,
	transactions
});
export const setGreatBookExercices = (exercices) => ({
	type: types.SET_GREAT_BOOK_EXERCICES,
	exercices
});
export const setGreatBookCurrentExercice = (exercice) => ({
	type: types.SET_GREAT_BOOK_CURRENT_EXERCICE,
	exercice
});
export const removeGreatBook = () => ({
	type: types.REMOVE_GREAT_BOOK
});
// JUDICIALS
export const setJudicialProcedures = (judicial_procedures) => ({
	type: types.SET_JUDICIAL_PROCEDURES,
	judicial_procedures
});
// COOWNERS
export const setCoowners = (coowners, current_page) => ({
	type: types.SET_COOWNERS,
	coowners,
	current_page
});
export const setNbPagesCoowners = (nb) => ({
	type: types.SET_NB_PAGES_COOWNERS,
	nb
});
export const removeCoowners = () => ({
	type: types.REMOVE_COOWNERS
});