import {
	SET_GLOBAL_VISITS,
	SET_VISITS,
	SET_VISITS_EXERCICES,
	SET_VISITS_CURRENT_EXERCICE,
	REMOVE_VISITS,
} from '../action_types/coownershipAmountsActionTypes';

const initialState = {
	globals: [],
	list: [],
	exercices: [],
	current_exercice: 0,
};

function visitsReducer(state = initialState, action) {
	let nextState;

	switch (action.type) {
		case SET_GLOBAL_VISITS:
			nextState = {
				...state,
				globals: action.visits,
			};
			return nextState || state;

		case SET_VISITS:
			nextState = {
				...state,
				list: action.visits,
			};
			return nextState || state;

		case SET_VISITS_EXERCICES:
			nextState = {
				...state,
				exercices: action.exercices,
			};
			return nextState || state;

		case SET_VISITS_CURRENT_EXERCICE:
			nextState = {
				...state,
				current_exercice: action.exercice,
			};
			return nextState || state;

		case REMOVE_VISITS:
			return initialState;

		default:
			return state;
	}
}

export default visitsReducer;
